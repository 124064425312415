import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Table,
    Pagination,
    DatePicker,
    Select,
    Row,
    Form,
    Modal,
    Divider,
    TreeSelect,
    Tooltip
} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Basic, Brand, Delivery, Product, Term, Warehouse, wmsPublic} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import LookupAll from "../../../../components/passport/LookupAll";
import ExportButton from "../../../../components/export";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryIndex(props) {
    // action part(配件)  boutique(精品)
    let {action} = props

    // 列表分页数据d
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [supplier, setSupplier] = useState({});// 供货商
    let [customer, setCustomer] = useState({}) // 客户
    let [employee, setEmployee] = useState({}) // 领料人
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(1);
    let [brand, setBrand] = useState([]);

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        deliveryCode: "",
        productNumber: "",
        name: "",
        customerId: "",
        customerType: 1,
        warehouseId: "",
        orderCode: "",
        termIdsOne: [],
        brandIds: brand.map(item => item.id),
        plate: "",
        date: [],
        ownerId: common.getUser().company.id,
        kind: action,
        isReceipt: Basic.IS_ANSWER_YES,
        deliveryEmployeeId: "",
        supplierId: "",
        types: [],

        // 接口中没有的字段
        exampleWarehouseList: [],
        exampleType: "",
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [treeData1, setTreeData1] = useState([])
    let [terms, setTerms] = useState([])
    let [subtotal, setSubtotal] = useState({});

    const columnsPart = [
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '车牌号',
            dataIndex: ['deliveryDto', 'plate'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.productDto === undefined) {
                    return ''
                }

                if (record.receiptItemDto.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.receiptItemDto.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-2') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'total',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryItemStatusAlias ? Delivery.DeliveryItemStatusAlias[text] : text
        },
        {
            title: '出库类型',
            dataIndex: ['deliveryDto', 'type'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '领料人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '业务单号',
            dataIndex: ['deliveryDto', 'orderCode'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: ['deliveryDto', 'vin'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '管理分类',
            dataIndex: ['productDto', 'termOneName'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: 'deliveryDto',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                if (text.type in Delivery.DeliveryTypeReturnAlias) {
                    return "原出库单号: " + text.spec
                } else {
                    return text.spec
                }
            }
        },
    ]

    const columnsBoutique = [
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            align: 'center',
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'total',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryItemStatusAlias ? Delivery.DeliveryItemStatusAlias[text] : text
        },
        {
            title: '出库类型',
            dataIndex: ['deliveryDto', 'type'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '领料人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '业务单号',
            dataIndex: ['deliveryDto', 'orderCode'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '销售顾问',
            dataIndex: ['deliveryDto', 'saleName'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: ['deliveryDto', 'vin'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '管理分类',
            dataIndex: ['productDto', 'termOneName'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: 'deliveryDto',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                if (text.type in Delivery.DeliveryTypeReturnAlias) {
                    return "原出库单号: " + text.spec
                } else {
                    return text.spec
                }
            }
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ...pagination,
            ...query,
            termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms),
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            data.deliveryItems.forEach((item) => {
                item.productDto.termOneName = wmsPublic.connectTermName(item.productDto.termIdOne, terms)
            })

            // 小计
            if (data.deliveryItems.length > 0) {
                let objMy = {
                    id: "-1",
                    total: 0,
                    quantity: 0,
                    deliveryDate: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    total: data.subtotal.price,
                    quantity: data.subtotal.quantity,
                    deliveryDate: '合计',
                }
                data.deliveryItems.forEach((item) => {
                    item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)

                    // 小计
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 总金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                })

                data.deliveryItems.push(objMy)
                data.deliveryItems.push(objMy2)
            }
            setList(data.deliveryItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    let rowSelection = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list.forEach((item) => {
                        if (item.deliveryId === selectedRows[length > 1 ? length - 1 : 0].deliveryId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: record.id === "-1" || record.id === "-2"
        })
    }

    // 全部打印
    let printAll2 = () => {
        if (list.length === 0) {
            common.alert("暂无数据");
            return false
        }

        if (total > 300) {
            common.confirm("最多只能打印300条", () => {
                printAll()
            })
        } else {
            printAll()
        }
    }

    let printAll = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/deliveryItem/list", {
                ...query,
                page: 1,
                limit: 300,
                termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms),
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
            }).then((data) => {
                resolve(data.deliveryItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            printData(printList)
        }).finally(common.loadingStop)
    }

    let printData = (printList) => {
        let total = 0;
        let min = new Date(printList[0].deliveryDate).getTime();
        let minStr = printList[0].deliveryDate
        let max = new Date(printList[0].deliveryDate).getTime();
        let maxStr = printList[0].deliveryDate
        printList.forEach((item) => {
            item.total = item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
            total = new BigNumber(item.total).plus(total).toString()

            let time = new Date(item.deliveryDate).getTime();
            if (min > time) {
                min = time
                minStr = item.deliveryDate
            }

            if (time > max) {
                max = time
                maxStr = item.deliveryDate
            }
            item.deliveryDate = item.deliveryDate.substring(0, 10)
            item.deliveryDto.type = item.deliveryDto.type in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[item.deliveryDto.type] : item.deliveryDto.type
        })

        let data = {
            "title": "出库单",
            "companyName": common.getUser().company.name,
            "startDate": minStr.substring(0, 10),
            "endDate": maxStr.substring(0, 10),
            "upperCase": common.moneyToChinese(common.numberCut(total, 2)),
            "total": common.numberCut(total, 2),
            "createdAt": printList[0].deliveryDate.substring(0, 10),
            "receiver": printList[0].employeeName,
            "salesman": common.getUser().nickname,
            "deliveryItems": printList
        }

        common.ajax("post", "/ext/print/template/parse", {
            code: "wms_delivery_all",
            owner_id: common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).catch(common.loadingStop)
    }

    // 打印
    let print = () => {
        if (rows.length === 0) {
            common.alert("请选择一个出库单");
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/deliveryItem/list", {
                ...query,
                page: 1,
                limit: 1000,
                deliveryId: rows[0].deliveryDto.id,
                termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms),
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
            }).then((data) => {
                resolve(data.deliveryItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = 0;
            printList.forEach((item) => {
                item.total = item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
                total = new BigNumber(item.total).plus(total).toString()
            })

            let data = {
                "title": "销售出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": printList[0].customerName,
                    "code": printList[0].deliveryDto.orderCode,
                    "plate": printList[0].deliveryDto.plate
                },
                "code": printList[0].deliveryDto.code,
                "total": total,
                "createdAt": printList[0].deliveryDate.substring(0, 10),
                "spec": printList[0].deliveryDto.spec,
                "provider": printList[0].employeeName,
                "receiver": printList[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": printList
            }
            if (printList[0].deliveryDto.type in Delivery.DeliveryTypeReturnAlias) {
                data.title = "出库退库单"
            }
            if (printList[0].deliveryDto.type === Delivery.DELIVERY_TYPE_REPAIR) {
                data.title = "工单出库单"
            }
            if (printList[0].deliveryDto.type === Delivery.DELIVERY_TYPE_SALE) {
                data.title = "销售订单出库单"
            }
            if (printList[0].deliveryDto.type === Delivery.DELIVERY_TYPE_RECEIVE) {
                data.title = "领用出库单"
            }
            if (printList[0].deliveryDto.type === Delivery.DELIVERY_TYPE_ALLOT) {
                data.title = "调拨出库单"
            }

            common.ajax("post", "/ext/print/template/parse", {
                code: "wms_delivery",
                owner_id: common.getUser().company.id,
                data: data
            }).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    useEffect(() => {
        list.forEach((item) => {
            if (item.productDto !== undefined) {
                item.productDto.termOneName = wmsPublic.connectTermName(item.productDto.termIdOne, terms)
            }
        })

        setList(list)
    }, [list, terms])

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品出库查询" : '配件出库查询'}>
                <Button
                    disabled={rows.length === 0}
                    icon={<PrinterOutlined/>} onClick={print}>打印</Button>
                <Button
                    disabled={list.length === 0}
                    icon={<PrinterOutlined/>} onClick={printAll2}>打印全部</Button>
                <ExportButton
                    filename={action === Product.KIND_PART ? '配件出库.xlsx' : '精品出库.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        deliveryCode: query.deliveryCode,
                        productNumber: query.productNumber,
                        name: query.name,
                        customerId: query.customerId,
                        orderCode: query.orderCode,
                        plate: query.plate,
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        brandIds: query.brandIds,
                        warehouseId: query.warehouseId,
                        ownerId: query.ownerId,
                        supplierId: query.supplierId,
                        types: query.types,
                        kind: query.kind,
                        deliveryEmployeeId: query.deliveryEmployeeId,
                        isReceipt: query.isReceipt,
                        termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms)
                    })}
                    type={action === Product.KIND_PART ? 'wms_part_delivery' : 'wms_boutique_delivery'}
                />
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: dateTime
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="出库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        if (Delivery.TYPE_RETURN_SEARCH === val) {
                                            setSearch({
                                                ...search,
                                                types: Object.keys(Delivery.DeliveryTypeReturnAlias),
                                                exampleType: val
                                            })
                                        } else {
                                            // 出库类型 与 退库类型 合并
                                            setSearch({
                                                ...search,
                                                types: val in Delivery.DeliveryTypeGetReturnAlias ? [val, Delivery.DeliveryTypeGetReturnAlias[val]] : [val],
                                                exampleType: val
                                            })
                                        }
                                    }}>
                                    {action === Product.KIND_ARTICLE &&
                                    <Option value={Delivery.DELIVERY_TYPE_SALE}
                                            key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Option>}
                                    <Option value={Delivery.DELIVERY_TYPE_ALLOT}
                                            key={Delivery.DELIVERY_TYPE_ALLOT}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ALLOT]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_SELL}
                                            key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_STOCK}
                                            key={Delivery.DELIVERY_TYPE_STOCK}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_STOCK]}</Option>
                                    {action === Product.KIND_PART &&
                                    <Option value={Delivery.DELIVERY_TYPE_REPAIR}
                                            key={Delivery.DELIVERY_TYPE_REPAIR}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_REPAIR]}</Option>}
                                    <Option value={Delivery.DELIVERY_TYPE_RECEIVE}
                                            key={Delivery.DELIVERY_TYPE_RECEIVE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_RECEIVE]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_ADJUST}
                                            key={Delivery.DELIVERY_TYPE_ADJUST}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ADJUST]}</Option>
                                    <Option value={Delivery.TYPE_RETURN_SEARCH}
                                            key={Delivery.TYPE_RETURN_SEARCH}>{Delivery.TYPE_RETURN_SEARCH_NAME}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.productNumber} onChange={(e) => {
                                    setSearch({...search, productNumber: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="仓库">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.exampleWarehouseList}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            exampleWarehouseList: val,
                                            warehouseId: val.toString()
                                        })
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="客户名称">
                                <Input
                                    value={customer.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        customer.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setCustomer({})
                                                    setSearch({...search, customerId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNumber(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNumber(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="业务单号">
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="车牌号">
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={'label-character-3'}
                                label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                        })
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Option key={''} value={''}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="领料人">
                                <Input
                                    value={employee.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        employee.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setEmployee({})
                                                    setSearch({...search, deliveryEmployeeId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择领料人")
                                                setModalNumber(3)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择领料人")
                                        setModalNumber(3)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setCustomer({})
                                    setSupplier({})
                                    setEmployee({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={action === Product.KIND_PART ? columnsPart : columnsBoutique}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === '-1' || record.id === '-2') {
                                return false
                            }
                            if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                                rowSelection.selectedRowKeys = []
                                rowSelection.onChange([], [], 1)
                            } else {
                                let arr = []
                                list.forEach((item) => {
                                    if (item.deliveryId === record.deliveryId) {
                                        arr.push(item)
                                    }
                                })
                                rowSelection.selectedRowKeys = arr.map((item) => item.id)
                                rowSelection.onChange(arr.map((item) => item.id), arr, 1)
                            }
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库总金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        进货总价: {common.numberFormat(subtotal.name)}
                        <Divider type="vertical"/>
                        出库成本: {common.numberFormat(subtotal.manufacturerRebate)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                title={modalTitle}
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}>
                {modalNumber === 3 &&
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(value) => {
                        setEmployee({id: value.id, name: value.name})
                        setSearch({...search, deliveryEmployeeId: value.id})
                        setVisible(false)
                    }}
                />}
                {(modalNumber === 1 || modalNumber === 2) &&
                <LookupAll
                    isCompany={true}
                    isCustomer={modalNumber === 1}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        if (modalNumber === 1) {
                            setCustomer({id: value.id, name: value.name})
                            setSearch({...search, customerId: value.id})
                            setVisible(false)
                        }
                        if (modalNumber === 2) {
                            setSupplier({id: value.id, name: value.name})
                            setSearch({...search, supplierId: value.id})
                            setVisible(false)
                        }
                    }}
                />}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIndex)