import React, {useEffect, useState} from "react"
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons'
import {Button, Col, Input, message, Pagination, Radio, Table, Form, Row, Modal, Select} from "antd"
import common from "../../../utils/common"
import SearchArea from "../../layout/SearchArea"
import PageBottom from "../../layout/PageBottom"
import CreatePartnerForm from "../CreatePartnerForm";

const { Option } = Select;

// 1.只获取集团的 2.只获取公司的 3.都获取
const DATASOURCE_TENANT = 1  //集团数据

// 0.所有 1.启用 2.禁用
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

function Partner(props) {

    // 模态框内 使用该组件 一定要传 onOK
    // isMultiple 是否多选 默认false
    // dataSource 数据来源  1.只获取集团的 2.只获取公司的 3.都获取
    // type 单位类型  保险公司
    // canChangeType 是否可以修改单位类型 默认为true
    let {onOk, isMultiple = false, dataSource, type, canChangeType = true} = props

    // 初始化搜索条件
    let initialSearch = {
        ownerId: dataSource === DATASOURCE_TENANT ? "" : common.getUser().company.id,
        dataSource: dataSource || DATASOURCE_TENANT, // 默认为集团
        status: STATUS_ENABLE, // 启用
    }

    let [terms, setTerms] = useState([])
    let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [tableData, setTableData] = useState([])
    let [partnerFormVisible, setPartnerFormVisible] = useState(false) // 新增外部单位表单的弹框
    let [rowKeys, setRowKeys] = useState([])
    let [total, setTotal] = useState(0)
    let [selectedPartners, setSelectedPartners] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    // 列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            width: 150,
        },
        {
            title: '单位名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 250,
        },
        {
            title: '单位简称',
            dataIndex: 'nameShort',
            key: 'nameShort',
            ellipsis: true,
            width: 250,
        },
        {
            title: '默认为厂家采购入库供货商',
            key: 'isFactorySupplier',
            ellipsis: true,
            width: 200,
            render: (text, record) => record.isFactorySupplier === 1 ? '是' : '否'
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            width: 120,
        },
        {
            title: '法人代表',
            dataIndex: 'legalPerson',
            key: 'legalPerson',
            ellipsis: true,
            width: 100,
        },
        {
            title: '信用代码',
            dataIndex: 'creditCode',
            key: 'creditCode',
            ellipsis: true,
            width: 100,
        },
        {
            title: '开户银行',
            dataIndex: 'bankDeposit',
            key: 'bankDeposit',
            ellipsis: true,
            width: 100,
        },
        {
            title: '银行账号',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            ellipsis: true,
            width: 100,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 100,
        },
        // {
        //     title: '辅助供货商',
        //     dataIndex: 'supplierId',
        //     key: 'supplierId',
        //     ellipsis: true,
        //     width: 100,
        // },
        // {
        //     title: '进项税率',
        //     key: 'incomeRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.incomeRate + '%'
        // },
        // {
        //     title: '返点率',
        //     key: 'returnRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.returnRate + '%'
        // },
        // {
        //     title: '是否默认为厂家采购入库供货商',
        //     key: 'isFactorySupplier',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.isFactorySupplier === 1 ? '是' : '否'
        // },
        // {
        //     title: '新保商业险手续费率',
        //     key: 'newCommercialRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.newCommercialRate + '%'
        // },
        // {
        //     title: '新保交强险手续费率',
        //     key: 'newCompulsoryRate',
        //     e    llipsis: true,
        //     width: 100,
        //     render: (text, record) => record.newCompulsoryRate + '%'
        // },
        // {
        //     title: '续保商业险手续费率',
        //     key: 'renewCommercialRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.renewCommercialRate + '%'
        // },
        // {
        //     title: '续保交强险手续费率',
        //     key: 'renewCompulsoryRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.renewCompulsoryRate + '%'
        // },
        // {
        //     title: '是否默认为索赔单位',
        //     key: 'isClaimPartner',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.isClaimPartner === 1 ? '是' : '否'
        // },
        // {
        //     title: '头像',
        //     dataIndex: 'avatar',
        //     key: 'avatar',
        //     ellipsis: true,
        //     width: 100,
        // },
        // {
        //     title: '类型',
        //     dataIndex: 'type',
        //     key: 'type',
        //     ellipsis: true,
        //     width: 100,
        // },
        {
            title: '邮件',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            width: 100,
        },
        {
            title: '公司电话',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            width: 100,
        },
    ]

    // 外部单位的类型
    useEffect(() => {
        common.loadingStart()
        common
            .ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => {
                res = res.filter(item => item.status !== 2)
                setTerms(res)
                // 设置是否默认外部单位的类型
                if (type) {
                    res.forEach(item => {
                        if (type === item.name) {
                            search = {...search, termId: item.id}
                            setSearch({...search})
                            setQuery({...search})
                        }
                    })
                }
            })
            .finally(common.loadingStop)
    }, [])

    // 获取外部单位列表
    let getPartners = () => {
        common.loadingStart()
        common.ajax("get", "/passport/partner/list", {
            ...query,
            ...pagination,
        })
        .then(res => {
            setTotal(res.pagination.total)
            tableData = res.partners || []
            setTableData([...tableData])
        })
        .finally(common.loadingStop)
    }
    useEffect(() => {
        getPartners()
    }, [query, pagination])

    const handleSelect = () => {
        if (selectedPartners.length === 0) {
            return message.info("请选择合作单位")
        }
        let partners = isMultiple ? selectedPartners : selectedPartners[0]
        onOk(partners)
        // setSelectedPartners([])
    }

    //  当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 选中列之后的操作
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys,   //selectedPartners.map(value => value.id),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setSelectedPartners(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
        type: isMultiple ? 'checkbox' : 'radio'
    }

    return (
        <div>
            <SearchArea>
                <Form className="ant-advanced-search-form">

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={'单位类型'}>
                                <Radio.Group value={search.termId} disabled={!canChangeType} onChange={e => {
                                    search = {...search, termId: e.target.value}
                                    setSearch({...search})
                                }}>
                                    <Row gutter={24}>
                                        <Col span={4} key={terms.length}><Radio value={""}>全部</Radio></Col>
                                        {terms.map(value =>
                                            <Col span={4} key={value.id}>
                                                <Radio value={value.id}>{value.name}</Radio>
                                            </Col>
                                        )}
                                    </Row>    
                                </Radio.Group>
                            </Form.Item>
                        </Col>    
                    </Row>    

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"单位名称"}>
                                <Input value={search.name} onChange={e => {
                                    search = {...search, name: e.target.value.trim()}
                                    setSearch({...search})
                                }} placeholder={"请输入查询内容"}/>
                            </Form.Item>
                        </Col>    

                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select defaultValue={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={STATUS_ALL}>全部</Option>
                                    <Option value={STATUS_ENABLE}>启用</Option>
                                    <Option value={STATUS_DISABLE}>禁用</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                    
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    search = {...initialSearch, termId: canChangeType ? "" : search.termId}
                                    setSearch(search)
                                    setQuery(search)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                const selectedRows = selectedPartners
                                if (selectedRows.indexOf(record) >= 0) {
                                    selectedRows.splice(selectedRows.indexOf(record), 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, 
                    }
                }}
                rowKey={'id'}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                scroll={{x: '100%'}}
                pagination={false}
            />

            <PageBottom 
                className={"page-bottom-pagination"}
                children={<div>
                    <Button icon={<PlusOutlined/>} onClick={() => {
                        setPartnerFormVisible(true)
                    }}>新增</Button>
                </div>}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelect}>确定</Button>
            </div>

            <Modal
                title={"新增外部单位"}
                visible={partnerFormVisible}
                maskClosable={false}
                footer={null}
                width={1000}
                onCancel={() => {
                    setPartnerFormVisible(false)
                }}>
                <CreatePartnerForm onOk={() => {
                    setPartnerFormVisible(false)
                    getPartners()  // 重新获取外部单位列表
                }} termId={search.termId} canChangeType={canChangeType}/>
            </Modal>

        </div>
    )
}

export default Partner
