import React, {useEffect, useState} from 'react'
import SearchArea from "../../../components/layout/SearchArea"
import {
    CloseOutlined, CloudUploadOutlined,
    EditOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Tooltip} from 'antd';
import './style.less'
import PageTop from "../../../components/layout/PageTop"
import PageBottom from "../../../components/layout/PageBottom"
import common from "../../../utils/common"
import {Link} from "react-router-dom"
import moment from 'moment'
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupEmployee from "../../../components/passport/LookupEmployee";
import Search from "../../../utils/search";
import UpdateLevel from "../../../components/crm/UpdateLevel";
import {Product} from "../../../components/wms/config";
import {Term} from "../../../components/crm/config";

const {RangePicker} = DatePicker
const FormItem = Form.Item

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

// 状态 1有效 2无效
const TERM_STATUS_YES = 1;
const TERM_STATUS_NO = 2;

const Option = Select.Option

function Task(props) {

    let {history} = props

    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const statusValue = {
        [STATUS_SUCCESS]: "有效",
        [STATUS_FAIL]: "战败审核中",
        [STATUS_CONDUCT]: "跟进中",
        [STATUS_FAIL_YES]: '战败'
    }

    // 日期
    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth() + 1
    let day = now.getDate()

    let initialSearch = {
        value: 2,
        value2: 0,
        name: "",
        minCreateAt: "",
        maxCreateAt: "",
        minNoteCreateAt: '',
        maxNoteCreateAt: '',
        phone: '',
        levelIds: '',
        productName: '',
        productId: '',
        noteContent: '',
        saleId: '',
        saleName: '',
        shouldNoteAtStart: '',
        shouldNoteAtEnd: '',
        // shouldNoteAtStart: '2018-01-01 00:00:00',
        // shouldNoteAtEnd: year + '-' + (String(month).length < 2 ? '0' + month : month) + '-' + (String(day).length < 2 ? '0' + day : day) + ' 23:59:59',
        channelId: "",
        sourceId: "",
        departmentId: "",
        remark: "",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [total, setTotal] = useState(0)
    let [isLevel, setIsLevel] = useState(false)
    let [isLevel2, setIsLevel2] = useState(true)
    let [loginInfo, setLoginInfo] = useState({
        saleId: '',
        saleName: '',
    })
    let [selectTask, setSelectTask] = useState({levelId: "", id: ""}) // 选择的潜客
    let [selectNewLevelId, setSelectNewLevelId] = useState() // 用户新的等级
    let [dataSource, setDataSource] = useState([])
    let [saleVisible, setSaleVisible] = useState(false)
    let [companyTypes, setCompanyTypes] = useState([])
    let [companyType, setCompanyType] = useState("")
    let [levelInfo, setLevelInfo] = useState("")
    let [levels, setLevels] = useState([])
    let [sources, setSources] = useState([]) // 信息来源
    let [channels, setChannels] = useState([]) // 客户渠道
    let [departments, setDepartments] = useState([]) // 业务部门
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [updateLevelIdVisible, setUpdateLevelIdVisible] = useState(false) // 修改等级弹框
    let [levelIds, setLevelIds] = useState([])
    let [defaultLevelIds, setDefaultLevelIds] = useState([])
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 12
    })

    const columns = [
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            fixed: 'left',
            width: 135,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
            ellipsis: true,
        },
        {
            title: '意向车型',
            dataIndex: 'productName',
            key: 'productName',
            ellipsis: true,
            width: 150,
        },
        {
            title: (
                <span>
                    意向级别&nbsp;&nbsp;
                    <Tooltip title={'战败申请潜客等级为F,转订单潜客等级为O'}><QuestionCircleOutlined/></Tooltip>
                </span>
            ),
            dataIndex: 'levelId',
            key: 'levelId',
            ellipsis: true,
            align: 'right',
            width: 100,
            render: (text, record) => {
                return common.can("crm.task.change.level") ?
                    <Input value={text} addonAfter={
                        <EditOutlined
                            onClick={() => {
                                selectTask = record
                                setSelectTask({...selectTask})
                                setUpdateLevelIdVisible(true)
                            }}/>
                    }/> : text
            }
        },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            key: 'saleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            ellipsis: true,
            width: 100,
        },
        {
            title: '档案备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 200,
            ellipsis: true,
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            ellipsis: true,
            align: 'center',
            width: 100,
            render: (text, record, index) => {
                return text !== null && text !== "" && text !== undefined ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '最后跟进内容',
            dataIndex: 'lastNoteContent',
            key: 'lastNoteContent',
            ellipsis: true,
            width: 300,
            render: (text, record) => {
                return text ? <Link to={`/crm/note/create/${record.id}`}>{text}</Link> : ''
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            ellipsis: true,
            width: 50,
            render: (text, record) => {
                return sexValue[text]
            }
        },
        {
            title: '意向品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '意向车系',
            dataIndex: 'seriesName',
            key: 'seriesName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '指导价',
            dataIndex: 'productPrice',
            key: 'productPrice',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '应跟进时间',
            dataIndex: 'shouldNoteTime',
            key: 'shouldNoteTime',
            ellipsis: true,
            align: 'center',
            width: 100,
            render: (text, record, index) => {
                return text !== null && text !== "" && text !== undefined ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '跟进次数',
            dataIndex: 'noteNum',
            key: 'noteNum',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: "潜客类型",
            dataIndex: "companyTypeName",
            key: "companyTypeName",
            width: 100,
            ellipsis: true
        },
        {
            title: '来电已到店',
            dataIndex: 'isShop',
            key: 'isShop',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? "已到店" : "未到店"
            }
        },
        {
            title: '到店次数',
            dataIndex: 'shopNum',
            key: 'shopNum',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '潜客状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return statusValue[text]
            }
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '潜客渠道',
            dataIndex: 'channelName',
            key: 'channelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            align: 'left',
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {
                            <Link to={`/crm/note/create/${record.id}`}>跟进</Link>
                        }
                        <Link to={`/crm/task/maintain/${record.id}`}>档案维护</Link>
                    </div>
                )
            }
        },
    ]

    // 获取当前登录人
    // function getLoginUser() {
    //     common.loadingStart()
    //     common.ajax('get', '/passport/employee/findEmployeeByAuthUser').then(data => {
    //         if (data !== null) {
    //             setLoginInfo({saleId: data.id, saleName: data.name})
    //             setSearch({...search, saleId: data.id, saleName: data.name})
    //             setQuery({...query, saleId: data.id, saleName: data.name})
    //         }
    //         setIsLevel2(true)
    //     }).finally(common.loadingStop)
    // }

    // 潜客列表
    function getData() {
        if (isLevel) {
            common.loadingStart()
            let params = {
                ...query,
                ownerId: common.getUser().company.id,
                limit: pageInfo.pageSize,
                page: pageInfo.page,
                status: query.value2,
                minNoteCreateAt: query.minNoteCreateAt ? moment(query.minNoteCreateAt, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                maxNoteCreateAt: query.maxNoteCreateAt ? moment(query.maxNoteCreateAt, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                isShop: query.value,
                companyType: companyType,
                levelIds: levelIds.join(','),
                isGroupNextTime: true,
            }
            common.ajax("get", "/crm/task/list", params, {useToken: true}).then(data => {
                setDataSource(data.taskDtos)
                setTotal(data.pagination.total)
            }).finally(common.loadingStop)
        }
    }

    useEffect(getData, [pageInfo.pageSize, pageInfo.page, query, isLevel])

    // 获取当前登录人
    // useEffect(getLoginUser, [])


    // 获取客户类型
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data => {
            data = data.filter(item => item.status !== TERM_STATUS_NO)
            setCompanyTypes(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])


    // 获取客户等级列表
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level"}).then(res => {
            res = res.filter(item => item.status !== TERM_STATUS_NO)
            res.sort((a, b) => {
                return a.sort - b.sort
            })
            levels = res || []
            setLevels(levels)
        }).finally(common.loadingStop)
    }, [])

    // 获取数据
    let getCrmSetting = (taxonomy) => {
        common.loadingStart()
        common.ajax('get', '/crm/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        })
            .then(res => {
                if (res !== null) {
                    if (res.spec !== '') {
                        setDefaultLevelIds(res.spec.split(","))
                        setLevelIds(res.spec.split(","))
                    }
                }
                setIsLevel(true)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getCrmSetting(Term.TASK_SEARCH_CUSTOMER_LEVEL)
    }, [])

    // 获取客户来源
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(res => {
            res = res.filter(item => item.status !== TERM_STATUS_NO)
            setSources(res)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 获取潜客渠道
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(res => {
            res = res.filter(item => item.status !== TERM_STATUS_NO)
            setChannels(res)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 获取业务部门
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department',}).then((data) => {
            data = data.filter(item => item.status !== TERM_STATUS_NO)
            setDepartments(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])


    function regTimeonChange(date, dateString) {
        setSearch({...search, minCreateAt: dateString[0], maxCreateAt: dateString[1]})
    }

    function regTimeonChange2(date, dateString) {
        setSearch({
            ...search,
            minNoteCreateAt: dateString[0] ? dateString[0] : "",
            maxNoteCreateAt: dateString[1] ? dateString[1] : ""
        })
    }

    // 分页
    function onChange(page) {
        setPageInfo({...pageInfo, page: page})
    }

    // 修改等级
    let updateLevelId = () => {
        common.loadingStart()
        common.ajax("post", '/crm/task/updateLevelId', {id: selectTask.id, levelId: selectNewLevelId})
            .then(res => {
                common.toast("修改成功")
                getData() // 重新获取数据
            })
            .finally(common.loadingStop)
    }

    return (
        <div className="task">
            <div className="myCreateForm">

                <PageTop title="潜客管理">
                    {
                        common.can("crm.task.import") ? <Button>
                                <Link to={'/crm/task/import'}>
                                    <CloudUploadOutlined/>
                                    <span> 数据导入</span>
                                </Link>
                            </Button>
                            : null
                    }
                    <Button icon={<PlusOutlined/>}
                            onClick={() => history.push('/crm/task/create')}>新增</Button>
                </PageTop>
                <SearchArea>
                    <Form className={'ant-advanced-search-form'}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label="登记日期" className='label-character-4'>
                                    <RangePicker
                                        value={[search.minCreateAt ? moment(search.minCreateAt) : null, search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                        suffixIcon={null}
                                        placeholder={["开始时间", "结束时间"]} onChange={regTimeonChange}/>
                                </FormItem>
                            </Col>

                            <Col span={6}>
                                <FormItem label="是否到店" className='label-character-4'>
                                    <Select value={search.value}
                                            onChange={e => setSearch({...search, value: e})}>
                                        <Option value={2} key={2}>全部</Option>
                                        <Option value={1} key={1}>已到店</Option>
                                        <Option value={0} key={0}>未到店</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'选择车型'} className='label-character-4'>
                                    <Input
                                        type="text"
                                        placeholder={'选择车辆'}

                                        suffix={
                                            search.productName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({
                                                            ...search,
                                                            productName: "",
                                                            productId: ""
                                                        })
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupProductVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupProductVisible(true)
                                        }}
                                        value={
                                            search.productName
                                        }
                                    />
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="最后跟进日期" className='label-character-5'>
                                    <RangePicker
                                        value={[search.minNoteCreateAt ? moment(search.minNoteCreateAt) : null, search.maxNoteCreateAt ? moment(search.minNoteCreateAt) : null]}
                                        suffixIcon={null}
                                        onChange={regTimeonChange2}/>
                                </FormItem>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label={"潜客类型"} className='label-character-4'>
                                    <Select value={companyType} onChange={e => {
                                        setCompanyType(e)
                                    }}>
                                        <Option value={""} key={""}>全部</Option>
                                        {
                                            companyTypes.map(item => {
                                                return <Option value={item.id}
                                                               key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="客户姓名" className='label-character-4'>
                                    <Input value={search.name} onChange={v => {
                                        setSearch({...search, name: v.target.value})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="联系电话" className='label-character-4'>
                                    <Input value={search.phone} onChange={v => {
                                        setSearch({...search, phone: v.target.value})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"应跟进日期"} className='label-character-5'>
                                    <RangePicker
                                        suffixIcon={null}
                                        ranges={{
                                            '今日': [moment(), moment()],
                                            '本周': [moment().startOf('week'), moment().endOf('week')],
                                            '本月': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        value={[search.shouldNoteAtStart ? moment(search.shouldNoteAtStart) : null,
                                            search.shouldNoteAtEnd ? moment(search.shouldNoteAtEnd) : null]}
                                        onChange={(val) => {
                                            if (val === null) {
                                                setSearch({
                                                    ...search,
                                                    shouldNoteAtStart: "",
                                                    shouldNoteAtEnd: "",
                                                })
                                            } else {
                                                setSearch({
                                                    ...search,
                                                    shouldNoteAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                    shouldNoteAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                })
                                            }
                                        }}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"客户级别"} className='label-character-4'>
                                    <Select mode="multiple" allowClear value={levelIds} onChange={e => {
                                        setLevelIds(e)
                                    }}>
                                        {
                                            levels.map(item => {
                                                return <Select.Option value={item.id}
                                                                      key={item.id}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"档案备注"} className='label-character-4'>
                                    <Input value={search.remark} onChange={e => {
                                        setSearch({...search, remark: e.target.value})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"销售顾问"} className='label-character-4'>
                                    <Input
                                        type="text"
                                        placeholder={'销售顾问'}
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setSaleVisible(true)
                                                }}/>
                                        }
                                        suffix={
                                            search.saleName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, saleName: "", saleId: ""})
                                                    }}/> : <span/>
                                        }
                                        onKeyPress={() => {
                                            setSaleVisible(true)
                                        }}
                                        value={
                                            search.saleName
                                        }
                                    />
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"跟进内容"} className='label-character-4'>
                                    <Input value={search.noteContent} onChange={e => {
                                        setSearch({...search, noteContent: e.target.value})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"潜客渠道"} className='label-character-4'>
                                    <Select value={search.channelId} onChange={value => {
                                        setSearch({...search, channelId: value})
                                    }}>
                                        <Option key={""} value={""}>全部</Option>
                                        {
                                            channels.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"信息来源"} className='label-character-4'>
                                    <Select value={search.sourceId} onChange={value => {
                                        setSearch({...search, sourceId: value})
                                    }}>
                                        <Option key={""} value={""}>全部</Option>
                                        {
                                            sources.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"业务部门"} className='label-character-4'>
                                    <Select value={search.departmentId} onChange={value => {
                                        setSearch({...search, departmentId: value})
                                    }}>
                                        <Option key={""} value={""}>全部</Option>
                                        {
                                            departments.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={() => {
                                        setQuery({...search})
                                        setPageInfo({...pageInfo, page: 1})
                                        Search.setParams(search)
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        setQuery({
                                            ...initialSearch,
                                            ...loginInfo
                                        })
                                        setSearch({
                                            ...initialSearch,
                                            ...loginInfo
                                        })
                                        setPageInfo({...pageInfo, page: 1})
                                        setLevelIds(defaultLevelIds)
                                        setCompanyType("")
                                        Search.clearParams()
                                    }}>清空</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </SearchArea>
                <Table
                    rowClassName={record => {
                        if (moment(record.shouldNoteTime).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD") && record.status == STATUS_CONDUCT) {
                            return 'table-color-red'
                        }
                    }}
                    rowKey={record => record.id} columns={columns} dataSource={dataSource}
                    scroll={{x: '100%'}}
                    pagination={false}/>
                <PageBottom pagination={
                    <Pagination
                        pageSizeOptions={['12', '30', '50', '100']}
                        onChange={onChange} total={total}
                        current={pageInfo.page}
                        defaultPageSize={pageInfo.pageSize} showSizeChanger
                        onShowSizeChange={(current, size) => {
                            setPageInfo({...pageInfo, pageSize: size})
                        }}
                        showTotal={total => `共${total}条`} showQuickJumper={true}/>
                }>
                    {levelInfo}
                </PageBottom>
            </div>

            <div>
                <Modal title="选车型"
                       maskClosable={false}
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>

                    <LookupProduct onOk={(val) => {
                        setLookupProductVisible(false)
                        setSearch({...search, productName: val.name, productId: val.id})
                    }}/>
                </Modal>

                <Modal title="选择员工"
                       maskClosable={false}
                       visible={saleVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setSaleVisible(false)
                       }}>
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setSaleVisible(false)
                            setSearch({...search, saleId: value.id, saleName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>

                <Modal title="修改等级"
                       maskClosable={false}
                       visible={updateLevelIdVisible}
                       width={1000}
                       onOk={updateLevelId}
                       onCancel={() => {
                           setUpdateLevelIdVisible(false)
                       }}>
                    <UpdateLevel selectTask={selectTask}
                                 levels={levels}
                                 selectNewLevelId={selectNewLevelId}
                                 setSelectNewLevelId={setSelectNewLevelId}/>
                </Modal>

            </div>
        </div>
    )
}


export default Task




