import React from 'react'
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";

function Index() {

    return (
        <React.Fragment>
            <PageTop title={"业务报表"}/>
            <div className="businessWrap">
                {/*新车销售*/}
                <Row gutter={24}>
                    {
                        (common.can('report.business.crm.lead') ||
                            common.can('report.business.crm.task') ||
                            common.can('report.business.sale.order') ||
                            common.can('report.business.sale.order.profit') ||
                            common.can('report.business.sale.order.composite')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">新车销售</div>
                                    </Col>
                                    {
                                        common.can('report.business.crm.task') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/lead/statistics"}>客流统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.crm.lead') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/task/statistics"}>潜客统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.sale.order') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/sale/statics/order"}>销售订单统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.sale.order.profit') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/sale/statics/profit"}>订单毛利统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.sale.order.composite') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/sale/statics/composite"}>销售综合毛利</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }

                    {/*增值业务*/}
                    {
                        common.can('report.business.avocation.order') ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            增值业务
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.avocation.order') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/avocation/order"}>增值业务统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.avocation.premium') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/avocation/premium"}>投保业务统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>新保业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>续保业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>上牌业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>按揭业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>玻璃卡业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={24}>*/}
                                    {/*    <div className="links">*/}
                                    {/*        <Link to={""}>延保业务统计</Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                </Row>
                            </Col>
                            : null
                    }

                    {/*维修业务*/}
                    {
                        (common.can('report.business.support.project') ||
                            common.can('report.business.support.total') ||
                            common.can('report.business.support.profit') ||
                            common.can('report.business.support.outsource')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>

                                    <Col span={24}>
                                        <div className={"title"}>
                                            维修产值
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.support.project') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/support/project"}>维修工时统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }

                                    {
                                        common.can('report.business.support.total') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/support/total"}>维修产值统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.profit') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/support/profit"}>维修毛利核算</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.discount') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/support/discount"}>维修优惠统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.outsource') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/support/outsource"}>委外施工统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {

                                        common.can('crm.filter.bxywtj') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/crm/filter/bxywtj"}>保险业务统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.achievement') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/support/achievements"}>绩效产值统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }

                    {/*客户管理*/}
                    {
                        (common.can('report.business.crm.review.sale') ||
                            common.can('report.business.crm.review.support') ||
                            common.can('report.business.support.appointment') ||
                            common.can('report.business.passport.customer')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            客户管理
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.crm.review.sale') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/crm/review/sale"}>销售回访统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.crm.review.support') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/crm/review/support"}>维修回访统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.appointment') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/support/appointment"}>维修预约统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.passport.customer') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/passport/customer"}>客户档案查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.support.vehicle') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/support/vehicle"}>车辆档案查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }
                </Row>
                <br/>
                {/*商品车管理*/}
                <Row gutter={24}>
                    {
                        (common.can('report.business.wms.car.receipt.delivery') ||
                            common.can('report.business.wms.car.receipt') ||
                            common.can('report.business.wms.car.delivery') ||
                            common.can('report.business.wms.car.sku')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            商品车管理
                                        </div>
                                    </Col>
                                    {common.can('report.business.wms.car.receipt.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link
                                                    to={"/report/business/wms/car/receiptAndDelivery"}>整车进销存统计</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.receipt') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/receipt"}>整车入库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/delivery"}>整车出库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.sku') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/sku"}>整车库存查询</Link>
                                            </div>
                                        </Col>
                                        : null}
                                </Row>
                            </Col>
                            : null
                    }

                    {/*精品管理*/}
                    {
                        (common.can('report.business.wms.boutique.receipt.delivery') ||
                            common.can('report.business.wms.boutique.receipt') ||
                            common.can('report.business.wms.boutique.delivery') ||
                            common.can('report.business.wms.boutique.sku') || common.can('report.business.wms.boutique.grant')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">精品管理</div>
                                    </Col>
                                    {common.can('report.business.wms.boutique.receipt.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link
                                                    to={"/report/business/wms/article/receiptAndDelivery"}>精品进销存统计</Link>
                                            </div>
                                        </Col>
                                        : null}
                                    {common.can('report.business.wms.boutique.receipt') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/receipt"}>精品入库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/delivery"}>精品出库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.sku') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/sku"}>精品库存查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.grant') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/grant"}>实物发放统计</Link>
                                            </div>
                                        </Col> : null}

                                    {common.can('report.business.wms.boutique.work') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/work"}>精品施工统计</Link>
                                            </div>
                                        </Col> : null}

                                    {common.can('report.business.wms.boutique.order') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/order"}>精品销售统计</Link>
                                            </div>
                                        </Col>
                                        : null}
                                </Row>
                            </Col>
                            : null
                    }
                    {/*配件管理*/}
                    {
                        (common.can('report.business.wms.part.receipt.delivery') ||
                            common.can('report.business.wms.part.receipt') ||
                            common.can('report.business.wms.part.delivery') ||
                            common.can('report.business.wms.part.sku')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            配件管理
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.wms.part.receipt.delivery') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/wms/part/receiptAndDelivery"}>配件进销存统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.receipt') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/receipt"}>配件入库查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.delivery') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/delivery"}>配件出库查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.sku') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/sku"}>配件库存查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }

                    {/*礼券业务*/}
                    {
                        (common.can('report.business.coupon.ticket') ||
                            common.can('report.business.coupon.ticket.used') ||
                            common.can('report.business.coupon.ticket.no')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            礼券业务
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.coupon.ticket') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/coupon/ticket"}>礼券发放统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.coupon.ticket.used') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/coupon/ticket/use"}>已收回礼券统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.coupon.ticket.no') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/coupon/ticket/sent"}>未收回礼券统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }
                </Row>
                {/*加装管理*/}
                <Row gutter={24}>
                    {/*加装管理*/}
                    {
                        (common.can('report.business.install.order') ||
                            common.can('report.business.install.order.item')
                        ) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            加装报表
                                        </div>
                                    </Col>
                                    {common.can('report.business.install.order') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/install/order"}>订单统计</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.install.order.item') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/install/order/item"}>材料出库统计</Link>
                                            </div>
                                        </Col>
                                        : null}
                                </Row>
                            </Col>
                            : null
                    }
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Index
