import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Form,
    Button,
    Input,
    Pagination,
    Table,
    Modal, Select, Col, Row,
} from "antd";

import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import CustomerForm from "../CustomerForm";
import LookupCompany from "../LookupCompany";

const FormItem = Form.Item
const { Option } = Select;

// 0.未知 1.男 2.女
const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;
let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

// 0.所有 1.启用 2.禁用
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

function Customer(props) {

    // 模态框内 使用该组件 一定要传 onOK
    // isMultiple 是否多选 默认false
    // canChangeCompany 是否可以切换公司 默认false
    let {onOk, isMultiple, canChangeCompany = false} = props
    
    // 表单方法
    const [form] = Form.useForm();

    let initialSearch = {
        email: '',
        phone: '',
        name: '',
        status: STATUS_ENABLE,
        exampleCompany: common.getUser().company.name,
        ownerId: common.getUser().company.id,
    }
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    let [customerFormVisible, setCustomerFormVisible] = useState(false); //新增车弹框
    const [data, setData] = useState();

    const columns = [
        {
            title: '编号',
            width: 110,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: 'phone',
            width: 160,
            ellipsis: true,
            render: (text) => {
                if (common.can("important.mobile")) {
                    return text
                }

                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => sexAlias.hasOwnProperty(record.sex) ? sexAlias[record.sex] : '未知'
        },
        {
            title: '客户类型',
            dataIndex: 'customerTypeName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '微信号',
            dataIndex: 'wechat',
            width: 160,
            ellipsis: true,
        },
        {
            title: 'QQ号',
            dataIndex: 'qq',
            width: 160,
            ellipsis: true,
        },
        {
            title: '邮箱地址',
            dataIndex: 'email',
            width: 160,
            ellipsis: true,
        },
        {
            title: '身份证',
            dataIndex: 'idcard',
            width: 160,
            ellipsis: true,
        },
        {
            title: '客户来源',
            dataIndex: 'customerSourceName',
            width: 160,
            ellipsis: true,
        },
    ];

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows, res) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    let getCustomers = () => {
        let params = {
            ownerId: query.ownerId,
            email: query.email,
            phone: query.phone,
            name: query.name,
            status: query.status,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.loadingStart();
        common.ajax('get', '/passport/customer/list', params).then(data => {
            if (data.customers.length > 0) {
                let list = []
                list = data.customers.filter((item, index) => {
                    return item.customerTypeId !== ''
                }).map(item => item.customerTypeId)
                if (list.length > 0) {
                    common.ajax("get", "/passport/term/findByIds", {ids: list}).then(data1 => {
                        let obj = {}
                        data1.forEach(item => {
                            obj = {...obj, [item.id]: item.name}
                        })

                        data.customers.forEach(item => {
                            if (item.customerTypeId !== '') {
                                item.customerTypeName = obj[item.customerTypeId]
                            }
                        })
                        setData(data.customers)
                    }).finally(() => {
                        common.loadingStop()
                    })
                } else {
                    setData(data.customers)
                }
            } else {
                setData(data.customers)
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }
    useEffect(getCustomers, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 保存客户
    let handleCustomer = (customer) => {
        common.ajax('post', '/passport/customer/create', customer).then(() => {
            common.toast("保存成功")
            getCustomers()
            setCustomerFormVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 确定
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择客户")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    return (
        <React.Fragment>
            <SearchArea>
                <Form form={form} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        {
                            canChangeCompany && 
                            <Col span={6}>
                                <Form.Item label="公司">
                                    <Input
                                        value={search.exampleCompany}
                                        readOnly={true}
                                        autoComplete={"off"}
                                        suffix={
                                            search.exampleCompany === '' ? <span/> :
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, ownerId: '', exampleCompany: ''})
                                                    }}/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setVisible(true)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        }
                        <Col span={6}>
                            <FormItem label={"客户姓名"}>
                                <Input type="text" placeholder='请输入客户姓名'
                                       value={search.name}
                                       onChange={(e) => {
                                           setSearch({...search, name: e.target.value})
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"联系电话"}>
                                <Input type="text" placeholder='请输入客户名称'
                                       value={search.phone}
                                       onChange={(e) => {
                                           setSearch({...search, phone: e.target.value})
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"邮箱"}>
                                <Input type="text" placeholder='请输入邮箱'
                                       value={search.email}
                                       onChange={(e) => {
                                           setSearch({...search, email: e.target.value})
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="状态">
                                <Select defaultValue={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={STATUS_ALL}>全部</Option>
                                    <Option value={STATUS_ENABLE}>启用</Option>
                                    <Option value={STATUS_DISABLE}>禁用</Option>
                                </Select> 
                            </FormItem>
                        </Col>
                        <Col span={canChangeCompany ? 18 : 24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, currentPage: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                            } else {
                                rowSelection.onChange([record.id], [record], 1)
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                scroll={{x: true}}
                rowKey={record => record.id}
                pagination={false}/>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                <div>
                    <Button icon={<PlusOutlined/>} onClick={() => {
                        setCustomerFormVisible(true)
                    }}>新增客户</Button>
                </div>
                <div>
                    <PageBottom pagination={
                        <Pagination
                            onChange={handlePageChange}
                            onShowSizeChange={handlePageChange}
                            showTotal={total => `共${total}条`}
                            total={total}
                            defaultCurrent={pagination.page}
                            defaultPageSize={pagination.limit}
                            current={pagination.page}
                            pageSizeOptions={['10', '20', '40', '80']}
                            showQuickJumper={true}
                            showSizeChanger/>
                    }/>
                </div>
            </div>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

            <Modal
                visible={customerFormVisible}
                maskClosable={false}
                footer={null}
                width={1000}
                onCancel={() => {
                    setCustomerFormVisible(false)
                }}>
                <CustomerForm id={""} onSave={handleCustomer}/>
            </Modal>

            <Modal
                maskClosable={false}
                visible={visible}
                title={"选择公司"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupCompany
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({
                            ...search,
                            ownerId: val.id,
                            exampleCompany: val.name
                        })
                        setVisible(false)
                    }}
                />
            </Modal>
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Customer);
