import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Table,
    Form,
    Tooltip,
    DatePicker,
    Divider,
    Select
} from "antd";
import common from "../../../../utils/common"
import {
    AccountBookOutlined,
    CheckSquareOutlined,
    CloseOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import LookupAll from "../../../../components/passport/LookupAll";
import {
    Basic, Delivery,
    Product,
    ProductCategoryRate,
    ProductGeneralRate,
    Receipt,
    Sale, Term,
    wmsPublic
} from "../../../../components/wms/config";
import GoodsReceiptItem from "../../../../components/wms/LookupGoodsReceiptItem";
import EmptyForm from "../../components/empty/form";
import moment from "moment";
import BigNumber from "bignumber.js";
import Search from "../../../../utils/search";
import {useHistory} from "react-router-dom";

const {TextArea} = Input

/**
 * 配将/精品销售出库
 */
function SaleForm(props) {
    // action 1(配件) 2(精品) 3(商品 实际上就是精品 只是不显示成本价)
    let {action, numbers} = props

    let [list, setList] = useState([]) // 表格数据
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [visible2, setVisible2] = useState(false) // 模态框可见性
    let [showPrintButton, setShowPrintButton] = useState(false) // 模态框可见性
    let [orderCode, setOrderCode] = useState("")// 出库单
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [quantity, setQuantity] = useState('0.00');
    let [total, setTotal] = useState('0.00');
    let [employee, setEmployee] = useState({});//领料人
    let [customer, setCustomer] = useState({});//客户
    let [receivableId, setReceivableId] = useState("");//应收款ID
    let [modalValue, setModalValue] = useState([]);
    let [setting, setSetting] = useState({})
    let [settingDeliveryPrice, setSettingDeliveryPrice] = useState({})

    let companyId = common.getUser().company.id

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/listForDelivery", {
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
            brandId: common.getUser().brand.id,
            kind: action === Sale.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            ownerId: companyId,
            number: params,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            isHaveGoodsInStock: Basic.IS_ANSWER_YES,//要有库存
            chargeType: action === Sale.TYPE_PART ? ProductGeneralRate.CHARGE_TYPE_SALE : ProductGeneralRate.CHARGE_TYPE_BOUTIQUE,
            orderType: ProductCategoryRate.ORDER_TYPE_SALE_WMS,
            limit: action === 3 ? 5000 : 10,
        }).then((data) => {
            if (action === 3) {
                let numberArr = numbers.split(",")
                let pickArr = []
                for (let number of numberArr) {
                    for (let receiptItem of data.receiptItems) {
                        if (number === receiptItem.productDto.number) {
                            pickArr.push(receiptItem)
                            break;
                        }
                    }
                    productGoodsOnOk(pickArr)
                }
            } else {
                if (data.receiptItems.length === 1) {
                    let arr = list.filter(item => item.id === data.receiptItems[0].id)
                    if (arr.length > 0) {
                        productGoodsOnOk(list)
                    } else {
                        productGoodsOnOk([...list, ...data.receiptItems])
                    }
                    setTimeout(() => {
                        emptyInput.current.focus()
                    }, 100)
                } else {
                    setModalNumber(1)
                    setModalTitle(action === Sale.TYPE_BOUTIQUE ? '精品选择' : (action === Sale.TYPE_PART ? '配件选择' : '商品选择'))
                    setVisible(true)
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: action === Sale.TYPE_BOUTIQUE ? '精品编号' : (action === Sale.TYPE_PART ? '配件编号' : "商品编号"),
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        style={{whiteSpace: 'pre-wrap'}}
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle(action === Sale.TYPE_BOUTIQUE ? '精品选择' : (action === Sale.TYPE_PART ? '配件选择' : "商品选择"))
                                    setVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: action === Sale.TYPE_BOUTIQUE ? '精品名称' : (action === Sale.TYPE_PART ? '配件名称' : '商品名称'),
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined || text === null) {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: action === 3 ? "库存数" : '可出库数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].quantity = val
                        }}
                        onBlur={() => {
                            // 左下角 出库数量 出库金额
                            let number = '0.00';
                            let price = '0.00';
                            list.forEach((item) => {
                                if (item.quantity !== null) {
                                    number = new BigNumber(item.quantity).plus(number).toString()
                                }

                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toString()
                                }
                            })
                            setQuantity(number)
                            setTotal(price)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: action === 3 ? 0 : 120,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '计划售价',
            width: 120,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                return <div style={{color: "blue"}}>{common.numberFormat(text)}</div>
            }
        },
        {
            title: <Tooltip title={"默认为配件档案管理中的计划售价"}>出库价 <QuestionCircleOutlined/></Tooltip>,
            dataIndex: 'price',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        style={{width: '100%'}}
                        min={0}
                        disabled={(Object.keys(settingDeliveryPrice).length > 0 && settingDeliveryPrice.flag === Term.SETTING_NO)}
                        precision={2}
                        value={record.price}
                        onChange={(val) => {
                            setList(list.map((item, index2) => {
                                if (index2 === index) {
                                    return {
                                        ...item,
                                        price: val
                                    }
                                }
                                return item
                            }))
                        }}
                        onBlur={(val) => {
                            // 出库总金额
                            let price = 0;
                            list.forEach((item) => {
                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                }
                            })
                            setTotal(price)

                            // 重新计算加价率
                            if (action !== 3) {
                                if (val.target.value === '') {
                                    list[index].taxPercent = ''
                                } else {
                                    // 加价率
                                    setList(list.map((item, index2) => {
                                        if (index2 === index) {
                                            return {
                                                ...item,
                                                taxPercent: (new BigNumber(val.target.value).minus(item.priceCost)).dividedBy(item.priceCost).multipliedBy(100).toFixed(2)
                                            }
                                        }
                                        return item
                                    }))
                                }
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '出库加价率(%)',
            width: action === 3 ? 0 : 200,
            align: 'center',
            dataIndex: 'taxPercent',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (record.taxPercent !== undefined && record.taxPercent !== null && record.taxPercent.length > 0) {
                    record.taxPercent = record.taxPercent.replace("%", "")
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <InputNumber
                            onPressEnter={wmsPublic.onPressEnter}
                            // style={{width: '100%'}}
                            // min={0}
                            disabled={(Object.keys(settingDeliveryPrice).length > 0 && settingDeliveryPrice.flag === Term.SETTING_NO)}
                            precision={2}
                            value={record.taxPercent}
                            onChange={(val) => {
                                setList(list.map((item, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...item,
                                            taxPercent: val
                                        }
                                    }
                                    return item
                                }))
                            }}
                            onBlur={(val) => {
                                // 出库总金额
                                let price = 0;

                                // 实际价格 = 加价率*原价+原价
                                setList(list.map((item, index2) => {
                                    if (index2 === index) {
                                        if (val.target.value === '') {
                                            item.price = "0.00"
                                        } else {
                                            item.price = new BigNumber(val.target.value).multipliedBy(item.priceCost).dividedBy(100).plus(item.priceCost).toFixed(2)
                                        }
                                    }
                                    if (item.quantity !== null && item.price !== null) {
                                        price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                    }
                                    return item
                                }))
                                setTotal(price)
                            }}
                        />
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.taxPercent !== null && record.taxPercent !== "") || (Object.keys(settingDeliveryPrice).length > 0 && settingDeliveryPrice.flag === Term.SETTING_NO)}
                                onClick={() => {
                                    // 出库总金额
                                    let price = 0;
                                    setList(list.map(item => {
                                        item.taxPercent = record.taxPercent
                                        // 出库价
                                        item.price = new BigNumber(item.taxPercent).multipliedBy(item.priceCost).dividedBy(100).plus(item.priceCost).toFixed(2)

                                        if (item.quantity !== null && item.price !== null) {
                                            price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                        }

                                        return item
                                    }))

                                    setTotal(price)
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }}/>)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => {
                                    let arr = list.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList(arr)

                                    // 左下角 出库数量 出库金额
                                    let number = 0;
                                    let price = 0;
                                    arr.forEach((item) => {
                                        if (item.quantity !== null) {
                                            number = new BigNumber(item.quantity).plus(number).toNumber()
                                        }

                                        if (item.quantity !== null && item.price !== null) {
                                            price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                        }
                                    })
                                    setQuantity(number)
                                    setTotal(price)
                                }}
                                okText="确定"
                                cancelText="取消">
                                <Button type={'link'}>删除</Button>
                            </Popconfirm>}
                    </div>)
            }
        }
    ]

    // 精品/配件 选择
    let productGoodsOnOk = (val) => {
        let arr = wmsPublic.productGoodsOnOk(val, list)

        let quantity = '0.00'
        let price = '0.00'
        arr.forEach((item) => {
            if (action !== 3) {
                // 加价率
                item.taxPercent = (new BigNumber(item.price).minus(item.priceCost)).dividedBy(item.priceCost).multipliedBy(100).toFixed(2) + "%"
            }

            quantity = new BigNumber(quantity).plus(item.quantity).toString()
            price = new BigNumber(item.price).multipliedBy(item.quantity).plus(price).toString()
        })

        setList(arr)
        setVisible(false)
        setNewValue('')

        // 价格 数量 左下角
        setQuantity(quantity)
        setTotal(price)
    }

    const [form] = Form.useForm()

    let onFinish = (values) => {
        let params = {
            ...values,
            ownerId: companyId,
            customerId: customer.id,
            employeeId: employee.id,
            groupId: "",
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD') + " " + moment(new Date(), "HH:mm:ss").format('HH:mm:ss'),
            grantItIs: action === Sale.TYPE_PART ? Delivery.DELIVERY_ITEM_STATUS_SHIPPED : Delivery.DELIVERY_ITEM_STATUS_UNSHIPPED,
            kind: action === Sale.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            type: Delivery.DELIVERY_TYPE_SELL,
            orderId: 'any',
            deliveryItem: list.map((item) => {
                return {
                    productId: item.productId,
                    receiptItemId: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    spec: item.spec,
                }
            }),
            mobile: "",
        }

        // 如果是客户的话 就把这个参数设成true
        if (customer.lookUpType === 'customer') {
            params.mobile = customer.phone
        }

        common.loadingStart();
        common.ajax("post", action === 3 ? '/wms/sale/commodity/create' : "/wms/sale/create", params).then((data) => {
            if (data.id === "stockOut") {
                if (data.stockOutProduct.length > 0) {
                    common.toast(data.stockOutProduct[0].name + "库存不足")
                } else {
                    common.alert("此配件/精品还有其它批次进货的库存余额，请选择另外一批出库或修改出库数量")
                }
            } else {
                if (action === 3 && data.receivableId !== null && data.receivableId.length !== 0) {
                    setReceivableId(data.receivableId)
                }
                setOrderCode(data.orderCode)
                setShowPrintButton(true)
                common.toast("创建成功")
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    let createReceiptEmpty = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/productGoods/receipt/empty/create", params).then((data) => {
            setVisible2(false)
            common.toast("虚入库创建成功")
            data.forEach((item1) => {
                list.forEach((item2) => {
                    if (item1.skuId === item2.skuId) {
                        item1.price = item2.price
                    }
                    item1.spec = ''
                    item1.quantity = item1.salableQuantity
                })
            })
            setList([
                ...list,
                ...data
            ])
            setModalValue({})
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    // 获取领料人 默认值
    let getDefaultEmployee = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findByAuthUser')
            .then(res => {
                if (res !== null) {
                    setEmployee(res)
                    form.setFieldsValue({employeeName: res.name})
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        if (action === 3) {
            getDefaultEmployee()
            getSettingData(Term.COMMODITY_SALE_DELIVERY_CUSTOMER)
        }
    }, [action])

    // 获取配置数据
    let getSettingData = (taxonomy) => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        })
            .then(res => {
                if (res !== null) {
                    if (Term.GOODS_DELIVERY_SALE_SELECT_DATE === taxonomy) {
                        setSetting(res)
                    } else if (Term.COMMODITY_SALE_DELIVERY_CUSTOMER === taxonomy) {
                        if (res.spec !== null && res.spec.length > 0) {
                            setCustomer({id: res.spec, name: res.employeeName})
                            form.setFieldsValue({
                                customerId: res.spec,
                                customerName: res.employeeName,
                                plate: "",
                                vin: "",
                                vehicleId: "",
                                productName: "",
                            })
                        }
                    } else {
                        setSettingDeliveryPrice(res)
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()
        getSettingData(Term.GOODS_DELIVERY_SALE_SELECT_DATE)
        getSettingData(action === 1 ? Term.PART_SALE_DELIVERY_UPDATE_DELIVERY_PRICE : Term.BOUTIQUE_SALE_DELIVERY_UPDATE_DELIVERY_PRICE)
    }, [])

    useEffect(() => {
        if (numbers !== undefined && numbers !== null && numbers !== "" && numbers.length > 0) {
            getData(numbers)
        }
    }, [numbers])

    // 打印 获取出库单
    let print = () => {
        if (orderCode === "") {
            common.alert("暂未创建销售单 无法打印");
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/deliveryItem/list", {
                page: 1,
                limit: 1000,
                orderCode: orderCode,
                ownerId: companyId,
                kind: Sale.TYPE_PART === action ? Product.KIND_PART : Product.KIND_ARTICLE,
                isReceipt: Basic.IS_ANSWER_YES, // 获取仓位
            }).then((data) => {
                resolve(data.deliveryItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = 0;
            printList.forEach((item) => {
                item.total = item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
                total = new BigNumber(item.total).plus(total).toString()
            })

            let data = {
                "title": "销售出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": printList[0].customerName,
                    "code": printList[0].deliveryDto.orderCode,
                    "plate": printList[0].deliveryDto.plate,
                    "vin": printList[0].deliveryDto.vin
                },
                "code": printList[0].deliveryDto.code,
                "total": total,
                "createdAt": printList[0].deliveryDate.substring(0, 10),
                "spec": printList[0].deliveryDto.spec,
                "provider": printList[0].employeeName,
                "receiver": printList[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": printList
            }

            common.ajax("post", "/ext/print/template/parse", {
                code: "wms_sell",
                owner_id: companyId,
                data: data
            }).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    let history = useHistory()

    return (
        <>
            <PageTop
                title={action === Sale.TYPE_BOUTIQUE ? "精品销售" : (action === Sale.TYPE_PART ? "配件销售" : "商品销售")}>
                {
                    action === 3 &&
                    <Button
                        disabled={receivableId.length === 0}
                        icon={<AccountBookOutlined/>}
                        onClick={() => {
                            history.push(`/finance/receipt/receivable?ids=${receivableId}&type=order&paymentType=receipt`)
                        }}>收款作业</Button>
                }
                {
                    showPrintButton &&
                    <Button
                        icon={<PrinterOutlined/>}
                        onClick={() => {
                            print()
                        }}>打印</Button>
                }
                <Button
                    disabled={showPrintButton}
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (Object.keys(customer).length === 0) {
                            common.alert("请选择客户")
                            return false
                        }

                        if (Object.keys(employee).length === 0) {
                            common.alert(action === 3 ? "请选择销售员" : "请选择领料人")
                            return false
                        }

                        if (list.length === 0) {
                            common.alert(action === Sale.TYPE_BOUTIQUE ? "请选择精品" : (action === Sale.TYPE_PART ? '请选择配件' : "请选择商品"))
                            return false
                        }
                        for (let item of list) {
                            if (item.quantity === null) {
                                common.alert("请填写出库数量")
                                return false
                            }
                            if (item.price === null) {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }

                        let num = 0;
                        list.forEach((item) => {
                            if (item.quantity > item.salableQuantity) {
                                num++;
                            }
                        })
                        if (num !== 0) {
                            common.alert("出库数量不允许大于可出库数量")
                            return false
                        }

                        form.submit()
                    }}>保存</Button>
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>
            <Form
                initialValues={{
                    spec: '',
                    vin: '',
                    plate: '',
                    vehicleId: '',
                    productName: '',
                    creatorName: common.getUser().nickname,
                }}
                form={form}
                className={'label-character-4'}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择客户'}]}
                            name={'customerName'}
                            label="客户">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    customer.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({customerName: ""})
                                                setCustomer({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(4)
                                            setModalTitle('选择客户')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(4)
                                    setModalTitle('选择客户')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'employeeName'}
                            rules={[{required: true, message: action === 3 ? "销售员不允许为空" : '领料人不允许为空'}]}
                            label={action === 3 ? "销售员" : "领料人"}>
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    employee.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployee({})
                                                form.setFieldsValue({employeeName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(3)
                                            setModalTitle(action === 3 ? "选择销售员" : "选择领料人")
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(3)
                                    setModalTitle(action === 3 ? "选择销售员" : "选择领料人")
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                style={{width: '100%'}}
                                disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)}/>
                        </Form.Item>
                    </Col>
                </Row>
                {action !== 3 &&
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={'plate'}
                                label="车牌">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        (form.getFieldValue("plate") === undefined || form.getFieldValue("plate") === "") ?
                                            <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    form.setFieldsValue({plate: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(5)
                                                setModalTitle('选择车辆')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(5)
                                        setModalTitle('选择车辆')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'vin'}
                                label="VIN">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'productName'}
                                label="车型">
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>}
                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item
                            name={'spec'}
                            label="出库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'creatorName'}
                            label="制单人">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={[...list, {
                    isEmptyRecord: true,
                    id: -1
                }]}
                scroll={{x: '100%', y: "500px"}}/>
            <div style={{marginTop: '10px'}}>
                {action === Sale.TYPE_BOUTIQUE ? '精品名称: ' + list.length : (action === Sale.TYPE_PART ? '配件名称: ' + list.length : '商品名称: ' + list.length)}
                <Divider type="vertical"/>
                出库数量: {common.numberFormat(quantity)}
                <Divider type="vertical"/>
                出库金额: {common.numberFormat(total)}
            </div>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 1 &&
                    <GoodsReceiptItem
                        isShowPriceCost={action !== 3}
                        defaultValue={list}
                        productNumber={newValue}
                        action={action === Sale.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                        chargeType={action === Sale.TYPE_PART ? ProductGeneralRate.CHARGE_TYPE_SALE : ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                        orderType={ProductCategoryRate.ORDER_TYPE_SALE_WMS}
                        onOk={productGoodsOnOk}/>}
                {modalNumber === 3 &&
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={companyId}
                        isMultiple={false}
                        onOk={(val) => {
                            setEmployee(val)
                            form.setFieldsValue({employeeName: val.name})
                            setVisible(false)
                        }}/>}
                {(modalNumber === 4 || modalNumber === 5) &&
                    <LookupAll
                        defaultValue={{
                            customerId: customer.hasOwnProperty("id") ? customer.id : ""
                        }}
                        defaultKey={modalNumber === 4 ? "1" : "5"}
                        isCompany={true}
                        isCustomer={true}
                        isPartner={true}
                        isMultiple={false}
                        isVehicle={true}
                        canChangeCompany={true}
                        onOk={(value) => {
                            // 客户
                            if (modalNumber === 4) {
                                setCustomer(value)
                                form.setFieldsValue({
                                    customerId: value.id,
                                    customerName: value.name,
                                    plate: "",
                                    vin: "",
                                    vehicleId: "",
                                    productName: "",
                                })
                            }
                            // 车牌
                            if (modalNumber === 5) {
                                setCustomer({
                                    id: value.id,
                                    customerName: value.name
                                })
                                form.setFieldsValue({
                                    customerId: value.id,
                                    customerName: value.name,
                                    plate: value.plate,
                                    vin: value.vin,
                                    vehicleId: value.vehicleId,
                                    productName: value.productName,
                                })
                            }

                            setVisible(false)
                        }}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 5 &&
                    <EmptyForm
                        defaultValue={modalValue}
                        action={action === Sale.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                        onOk={createReceiptEmpty}/>}
            </Modal>
        </>
    );
}

export default SaleForm