import React, {useEffect, useState} from "react";
import {Modal, Table, Button, Pagination} from "antd";
import moment from "moment";
import common from "../../../utils/common";
import PayList from "./paylist";
import OrderDetail from "../../../pages/sale/plus/detail";
import OutsourceDetail from "../../support/OutsourceDetail";
import AllotGoodsDetail from "../../wms/finance/AllotGoodsDetail";
import AllotCarDetail from "../../wms/finance/AllotCarDetail";
import DelegateDetail from "../../support/DelegateDetail";
import GoodsProductDetail from "../../wms/finance/ProductGoodsReceiptDetail";
import CarReceiptDetail from "../../wms/CarReceiptForm";
import AvocationDetail from "../../avocation/AvocationOrderDetail";
import GoodsReceiptBackDetail from "../../wms/finance/ProductGoodsReceiptBackDetail";
import CarReceiptBackDetail from "../../wms/CarReceiptBackForm";
import {Allot, Receipt} from "../../wms/config";
import BigNumber from "bignumber.js";
import PageBottom from "../../layout/PageBottom";

const BUSINESS_SUPPORT = 1
const BUSINESS_TRANSFER = 2
const BUSINESS_INTERNAL = 3
const BUSINESS_PART = 4
const BUSINESS_ARTICLE = 5
const BUSINESS_CAR = 6
const BUSINESS_VALUE_ADDED = 7
const BUSINESS_ORDER = 8
const BUSINESS_RETURN = 9

const BusinessName = {
    [BUSINESS_SUPPORT]: "委外维修",
    [BUSINESS_TRANSFER]: "调拨单",
    [BUSINESS_INTERNAL]: "委内业务",
    [BUSINESS_PART]: "配件入库",
    [BUSINESS_ARTICLE]: "精品入库",
    [BUSINESS_CAR]: "车辆入库",
    [BUSINESS_VALUE_ADDED]: "增值业务",
    [BUSINESS_ORDER]: "销售折扣",
    [BUSINESS_RETURN]: "入库退货"
}

function PayableTable(props) {

    const {isTenant, searchNeedles, val, num} = props

    let [ownerId, setOwnerId] = useState('')
    let [lookupVisible, setLookupVisible] = useState(false)
    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [supprtDetailVisible, setSupprtDetailVisible] = useState(false)
    let [transferDetailVisible, setTransferDetailVisible] = useState(false)
    let [internalDetailVisible, setInternalDetailVisible] = useState(false)
    let [partDetailVisible, setPartDetailVisible] = useState(false)
    let [articleDetailVisible, setArticleDetailVisible] = useState(false)
    let [carDetailVisible, setCarDetailVisible] = useState(false)
    let [valueAddedDetailVisible, setValueAddedDetailVisible] = useState(false)
    let [returnDetailVisible, setReturnDetailVisible] = useState(false)
    let [lookupOutsourceDetailVisible, setLookupOutsourceDetailVisible] = useState(false)
    let [code, setCode] = useState("")
    let [payableId, setPayableId] = useState('')
    let [modalData, setModalData] = useState({})
    let [modalData2, setModalData2] = useState([])
    let [modalNumber, setModalNumber] = useState(0)
    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0,
        isReset: false
    })

    const Columns = [
        {
            title: '业务单号',
            dataIndex: 'businessOrderCode',
            width: 150,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '业务发生时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'center',
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '单位编号',
            dataIndex: 'customerCode',
            width: 150,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '单位名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '业务类型',
            dataIndex: 'financeCategoryName',
            width: 150,
            ellipsis: true,
            center: 'center',
        },
        {
            title: '应付金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '已付金额',
            dataIndex: 'paid',
            width: 150,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record, index) => {
                return index === dataSource.length - 1 ? <Button type={'link'}>{text}</Button> :
                    <Button type={'link'} onClick={() => {
                        setLookupVisible(true)
                        setPayableId(record.payableId)
                    }}>{common.numberFormat(text)}</Button>
            }
        },
        {
            title: '未付金额',
            dataIndex: 'notPaid',
            width: 150,
            ellipsis: true,
            align: 'right',
            center: 'center',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '差异金额',
            dataIndex: 'diff',
            width: 150,
            ellipsis: true,
            align: 'right',
            center: 'center',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '实际支付',
            dataIndex: 'actual',
            width: 150,
            ellipsis: true,
            align: 'right',
            center: 'center',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '账龄/天',
            dataIndex: 'createdDay',
            width: 100,
            ellipsis: true,
            align: 'right',
            center: 'center',
            render: (text, record) => {
                return record.businessOrderCode != '小计' &&  record.businessOrderCode != '合计' ? text : null
            }
        },
        {
            title: '账龄/段',
            dataIndex: 'createdStage',
            width: 100,
            ellipsis: true,
            align: 'right',
            center: 'center'
        },
        {
            title: '单据备注',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '最后一次往来时间',
            dataIndex: 'lastTimeAt',
            width: 130,
            ellipsis: true,
            center: 'center',
            align: 'center',
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '查看',
            dataIndex: 'operation',
            width: 100,
            align: "center",
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                let body = null
                {
                    record.businessOrderCode != '小计' &&  record.businessOrderCode != '合计' ? body = <Button type={'link'} onClick={e => {
                            setCode(record.businessOrderCode)
                            setOwnerId(record.ownerId)
                            switch (record.businessType) {
                                case BUSINESS_ORDER:
                                    setOrderDetailVisible(true)
                                    break;
                                case BUSINESS_SUPPORT:
                                    setSupprtDetailVisible(true)
                                    break;
                                case BUSINESS_TRANSFER:
                                    getTransferData(record.businessOrderCode, record.ownerId)
                                    break;
                                case BUSINESS_INTERNAL:
                                    setInternalDetailVisible(true)
                                    break;
                                case BUSINESS_PART:
                                    getGoodsReceipt(record.businessOrderCode, record.ownerId)
                                    break;
                                case BUSINESS_ARTICLE:
                                    getGoodsReceipt(record.businessOrderCode, record.ownerId)
                                    break;
                                case BUSINESS_CAR:
                                    getCarReceipt(record.businessOrderCode, record.ownerId)
                                    break;
                                case BUSINESS_VALUE_ADDED:
                                    setValueAddedDetailVisible(true)
                                    break;
                                case BUSINESS_RETURN:
                                    getReceiptReturn(text, record.ownerId)
                                    break;
                                default:
                                    break;
                            }
                            e.stopPropagation()
                        }}>原始单据</Button>
                        : body = null
                }
                return body
            }
        },
    ]

    let getCarReceipt = (params, ownerId) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            receiptCode: params,
            ownerId: !isTenant ? common.getUser().company.id : ownerId,
            kind: 'car'
        }).then((data) => {
            setModalData({
                ...data.receiptItems[0].receiptDto,
                receiptItemDto: data.receiptItems[0],
                goodsDto: data.receiptItems[0].goodsDto,
                productDto: data.receiptItems[0].productDto
            })
            setCarDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getGoodsReceipt = (params, ownerId) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: !isTenant ? common.getUser().company.id : ownerId,
            receiptCode: params
        }).then((data) => {
            setModalData2(data.receiptItems)
            setPartDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getTransferData = (params, ownerId) => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/findByCode", {
            code: params
        }).then((data) => {
            common.consoleLog(data)
            setModalData(data)
            if (data.type === Allot.TYPE_CAR) {
                setModalNumber(4)
            } else {
                setModalNumber(3)
            }
            setTransferDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getReceiptReturn = (params, ownerId) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: !isTenant ? common.getUser().company.id : ownerId,
            receiptCode: params,
            // type: Receipt.RECEIPT_TYPE_RETURN_BUY
        }).then((data) => {
            setModalData2(data.receiptItems)
            if (data.receiptItems[0].goodsDto == null) {
                setModalNumber(1)
            } else {
                setModalNumber(2)
            }
            setReturnDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getData = () => {
        if (val == num) {
            let params = {
                page: pageInfo.page,
                limit: pageInfo.pageSize,
                ...searchNeedles
            }
            common.loadingStart()
            common.ajax('get', '/finance/report/payableList', params).then(data => {
                if (data.payables.length > 0) {
                    data.payables.push(data.subtotal)
                    data.payables.push(data.total)
                    data.payables.map((item, index) => {
                        item.id = index
                    })
                }
                setDataSource(data.payables)
                setPageInfo({...pageInfo, total: data.pagination.total, isReset: false})
            }).finally(common.loadingStop)
        }
    }

    useEffect(() => {
        setPageInfo({...pageInfo, page: 1, isReset: true})
    }, [searchNeedles])

    useEffect(getData, [pageInfo.pageSize, pageInfo.page, pageInfo.isReset])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={Columns}
                   pagination={false}
            />

            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={pageInfo.total}
                                        current={pageInfo.page}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${pageInfo.total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>

            <div>
                <Modal title="付款明细"
                       visible={lookupVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       maskClosable={false}
                       onCancel={() => {
                           setLookupVisible(false)
                       }}>
                    <PayList id={payableId}></PayList>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={code} ownerId={ownerId}></OrderDetail>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={supprtDetailVisible}
                    title={'委外详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setSupprtDetailVisible(false)}
                >
                    <OutsourceDetail businessCode={code}></OutsourceDetail>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={transferDetailVisible}
                    title={'调拨单详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setTransferDetailVisible(false)}
                >
                    {modalNumber === 3 &&
                    <AllotGoodsDetail defaultValue={modalData}/>}
                    {modalNumber === 4 &&
                    <AllotCarDetail defaultValue={modalData}/>}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={internalDetailVisible}
                    title={'委内详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setInternalDetailVisible(false)}
                >
                    <DelegateDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={partDetailVisible}
                    title={'配件入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setPartDetailVisible(false)}
                >
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'part'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={articleDetailVisible}
                    title={'精品入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setArticleDetailVisible(false)}
                >
                    {/*配件action = {'part'}*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'boutique'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={carDetailVisible}
                    title={'车辆入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setCarDetailVisible(false)}
                >
                    <CarReceiptDetail action={'look'} defaultValue={modalData}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={valueAddedDetailVisible}
                    title={'增值业务详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setValueAddedDetailVisible(false)}
                >
                    <AvocationDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={returnDetailVisible}
                    title={'入库退货详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setReturnDetailVisible(false)}
                >
                    {modalNumber === 1 &&
                    <GoodsReceiptBackDetail defaultValue={modalData2} action={modalData2[0].receiptDto.type}/>}
                    {modalNumber === 2 &&
                    <CarReceiptBackDetail defaultValue={modalData2}/>}
                </Modal>
            </div>
        </>
    )
}

export default PayableTable