import React, {useEffect, useState} from 'react';
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    Input,
    Table,
    Select,
    Pagination,
    Form,
    DatePicker, Modal
} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import PageTop from "../../../components/layout/PageTop";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import {Link} from "react-router-dom";
import moment from "moment";
import condition from "../../../utils/condition";
import SearchPlanButton from "../../../components/support/SearchPlanButton";
import Search from "../../../utils/search";
import {SaleOrder} from "../../../components/sale/config";
import OrderDetail from "../plus/detail";
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupEmployee from "../../../components/passport/LookupEmployee";
import BigNumber from "bignumber.js";
import LookupCustomer from "../../../components/passport/LookupCustomer";

const {RangePicker} = DatePicker

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    },
    getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
    }),
};

// 选择框
const {Option} = Select;

function Order(props) {
    // 按钮组
    function handleMenuClick() {
        props.history.push('/sale/plus')
    }

    const STATUS_UNTRIED = 1;
    const STATUS_ADOPT = 2;
    const STATUS_EQUIPPED = 3;
    const STATUS_KIND_VEHICLE = 4;
    const STATUS_KIND_PARTS = 5;
    const STATUS_WAIT = 6;
    const STATUS_RECEIVABLE = 7;
    const STATUS_PAID = 8;
    const STATUS_RETREAT = 9;
    const STATUS_AUDIT = 11;
    const STATUS_AUDIT_REJECT = 12;

    const typeName = {
        1: "区域客户订单",
        2: "诚意金订单",
        3: "试驾车订单",
        4: "展车订单",
        5: "调拨订单",
        7: "代交车订单",
        8: "精品订单",
        11: "一般客户订单"
    }
    const typeArr = [
        {
            key: 1,
            value: "区域客户订单"
        },
        {
            key: 2,
            value: "诚意金订单"
        },
        {
            key: 3,
            value: "试驾车订单"
        },
        {
            key: 4,
            value: "展车订单"
        },
        {
            key: 5,
            value: "调拨订单"
        },
        {
            key: 7,
            value: "代交车订单"
        },
        {
            key: 8,
            value: "精品订单"
        },
        {
            key: 11,
            value: "一般客户订单"
        },
    ]
    const statusName = {
        [STATUS_UNTRIED]: "订单待审",
        [STATUS_ADOPT]: "订单已审",
        [STATUS_EQUIPPED]: "订单配车",
        [STATUS_KIND_VEHICLE]: "订车出库",
        [STATUS_KIND_PARTS]: "精品出库",
        [STATUS_WAIT]: "订单预结",
        [STATUS_RECEIVABLE]: "订单结算",
        [STATUS_PAID]: "订单收款",
        [STATUS_RETREAT]: "已退单",
        [STATUS_AUDIT]: "订单审核中",
        [STATUS_AUDIT_REJECT]: "审核未通过"
    }
    const statusArr = [
        {
            key: SaleOrder.STATUS_UNTRIED,
            value: "未审"
        },
        {
            key: SaleOrder.STATUS_AUDIT,
            value: "审核中"
        },
        {
            key: SaleOrder.STATUS_AUDIT_REJECT,
            value: "驳回"
        },
        {
            key: SaleOrder.STATUS_ADOPT,
            value: "通过"
        },
        {
            key: SaleOrder.STATUS_EQUIPPED,
            value: "订单配车"
        },
        {
            key: SaleOrder.STATUS_KIND_VEHICLE,
            value: "车辆出库"
        },
        {
            key: SaleOrder.STATUS_KIND_PARTS,
            value: "精品出库"
        },
        {
            key: SaleOrder.STATUS_WAIT,
            value: "订单预结"
        },
        {
            key: SaleOrder.STATUS_RECEIVABLE,
            value: "订单结算"
        },
        {
            key: SaleOrder.STATUS_PAID,
            value: "订单收款"
        },
        {
            key: SaleOrder.STATUS_RETREAT,
            value: "订单作废"
        },
    ]
    const settlementStatusName = {
        1: "未结算",
        2: "待收款",
        3: "已收款"
    }

    const columns = [
        {
            title: '订单编号',
            width: 120,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                    setOrderCode(text)
                    setOrderDetailVisible(true)
                }}>{text}</span>
            }
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 110,
            dataIndex: ['customerDto', "phone"],
            ellipsis: true,
        },
        {
            title: '车主名称',
            width: 100,
            dataIndex: 'vehicleOwner',
            key: 'vehicleOwner',
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return typeName[text]
            }
        },
        {
            title: "品牌",
            dataIndex: ['vehicle', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: "车系",
            dataIndex: ['vehicle', 'seriesName'],
            width: 100,
            ellipsis: true
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.productName : null
            }
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: "VIN",
            dataIndex: ['vehicle', "vin"],
            width: 160,
            ellipsis: true,
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return <b>{statusName[text]}</b>
            }
        },
        {
            title: "外饰",
            dataIndex: "colorOut",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.colorOut : null
            }
        },
        {
            title: "指导价",
            dataIndex: ['vehicle', 'msprPrice'],
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '整车实际售价',
            dataIndex: 'vehicleSalePrice',
            key: 'vehicleSalePrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '用品实际售价',
            dataIndex: 'partsSalePrice',
            key: 'partsSalePrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合计售价',
            dataIndex: 'actualPayPrice',
            key: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            width: 50,
            align: 'center',
            ellipsis: true,
            render: () => {
                return '1'
            }
        },
        {
            title: "内饰",
            dataIndex: "colorInner",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.colorInner : null
            }
        },
        {
            title: '是否配车',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.vehicle != null && (record.vehicle.status === 2 || record.vehicle.status === 3)) {
                    str = '是'
                }
                return str
            }
        },
        {
            title: '车辆出库',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.vehicle != null && record.vehicle.status === 3) {
                    str = '是'
                }
                return str
            }
        },
        {
            title: '精品出库',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.parts != null && record.parts.length > 0) {
                    let arr = record.parts.filter(item => item.status === 3)
                    if (arr.length > 0) {
                        str = '是'
                    }
                }
                return str
            }
        },
        {
            title: "结算状态",
            dataIndex: "settlementStatus",
            key: "settlementStatus",
            width: 80,
            ellipsis: true,
            render: (text) => {
                return settlementStatusName[text]
            }
        },
        {
            title: '销售方式',
            dataIndex: 'payTypeName',
            key: 'payTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: "经销商",
            dataIndex: "partnerName",
            width: 100,
            ellipsis: true
        },
        {
            title: "开票类型",
            dataIndex: 'invoiceTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "交货地点",
            dataIndex: "deliveryAddress",
            width: 100,
            ellipsis: true
        },
        {
            title: "交货日期",
            dataIndex: "deliveryDate",
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '增票折扣',
            dataIndex: 'useRebate',
            key: 'useRebate',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '订单备注',
            dataIndex: 'orderRemark',
            key: 'orderRemark',
            ellipsis: true,
            width: 200,
        },
        {
            title: "创建人",
            dataIndex: "creatorId",
            ellipsis: true,
            width: 100,
        },
        {
            title: '创建日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            ellipsis: true,
            sorter: true,
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: "车主电话",
            dataIndex: "vehicleOwnerPhone",
            ellipsis: true,
            width: 110,
        },
        {
            title: "精品数量",
            dataIndex: ['parts'],
            ellipsis: true,
            width: 80,
            align: 'right',
            render: (text) => {
                if (text === null || text === undefined) {
                    return "0.00"
                }
                let sum = "0.00"
                text.forEach((partItem) => {
                    sum = new BigNumber(partItem.quantity).plus(sum).toString()
                })
                return common.numberHalfUp(sum, 2)
            }
        },
        {
            title: '性别',
            width: 50,
            dataIndex: ['customerDto', "sex"],
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }
                if (text === 1) {
                    return "男"
                }
                if (text === 2) {
                    return "女"
                }
                return ""
            }
        },
        {
            title: '住家地址',
            width: 120,
            dataIndex: ['customerDto', "location"],
            ellipsis: true,
        },
        {
            title: '身份证号',
            width: 160,
            dataIndex: ['customerDto', "idcard"],
            ellipsis: true,
        },
        {
            title: '客户类型',
            width: 100,
            dataIndex: ['customerDto', "customerTypeName"],
            ellipsis: true,
        },
        {
            title: '业务备注',
            width: 100,
            dataIndex: "partsRemark",
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 130,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className="action-btns">
                        <Link
                            to={`/sale/edit/${record.id}`}>{record.status >= SaleOrder.STATUS_ADOPT && record.status < SaleOrder.STATUS_AUDIT ? "查看订单" : '编辑'}</Link>
                        {
                            (record.isPrepay || record.status === SaleOrder.STATUS_WAIT || record.status === SaleOrder.STATUS_PAID || record.status === SaleOrder.STATUS_RECEIVABLE) && record.type !== "2" && record.type !== "4" &&
                            <Link
                                to={`/sale/settlement/${record.id}`}>{record.status >= SaleOrder.STATUS_WAIT ? '查看预结' : '预结算'}</Link>
                        }
                    </div>
                )
            }
        },
    ];

    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
    })
    let [total, setTotal] = useState(0)
    let [subtotal, setSubtotal] = useState({})

    let [dataSource, setDataSource] = useState([])
    let [orderCode, setOrderCode] = useState("")

    let initSearch = {
        minCreatedAt: '',
        maxCreatedAt: '',
        vehicleOwner: '',
        code: '',
        type: '',
        channelId: '',
        statuses: [SaleOrder.STATUS_UNTRIED, SaleOrder.STATUS_AUDIT, SaleOrder.STATUS_AUDIT_REJECT, SaleOrder.STATUS_ADOPT, SaleOrder.STATUS_EQUIPPED, SaleOrder.STATUS_KIND_VEHICLE, SaleOrder.STATUS_KIND_PARTS, SaleOrder.STATUS_WAIT],
        settlementStatus: 0,
        vin: '',
        customerId: '',// 客户ID
        customerName: '',// 客户名称
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initSearch)
    let [searchNeedles, setSearchNeedles] = useState(Search.getParams() !== null ? Search.getParams() : initSearch)

    let [channel, setChannel] = useState([])
    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [visible2, setVisible2] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(1);
    // let [typeChannels, setTypeChannels] = useState([])// 销售渠道

    let [orderBy, setOrderBy] = useState(" orders.created_at DESC ")

    useEffect(() => {
        common.loadingStart();

        let params = {
            ownerId: common.getUser().company.id,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            isDepartment: true,
            orderBy: orderBy,
            ...searchNeedles
        }

        common.ajax("get", "/sale/order/vehicleList", params).then(data => {
            data.orders.forEach(item => {
                item.isPrepay = false
                if (item.parts != null) {
                    if (item.status === STATUS_KIND_PARTS) {
                        item.isPrepay = true
                    }
                } else {
                    if (item.status === STATUS_KIND_VEHICLE) {
                        item.isPrepay = true
                    }
                }
            })

            setDataSource(data.orders)
            setTotal(data.pagination.total)
            // setSubtotal(data.subtotal)

            common.loadingStart();
            let params = {
                taxonomy: "channel",
                userId: common.getUser().company.id
            }
            common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
                data = data.filter(item => item.status !== 2)
                setChannel(data)
            }).finally(() => {
                common.loadingStop();
            })
        }).finally(common.loadingStop)
    }, [pageInfo.pageSize, pageInfo.page, searchNeedles, orderBy])

    let handleSortChange = (pagination, filters, sorter) => {
        let table = 'orders.';
        sorter.field = table + condition.getFieldStr(sorter.field);
        setOrderBy(condition.getOrderBy(sorter))
    }

    // // 销售渠道
    // let getTypeChannels = () => {
    //     common.loadingStart()
    //     common.ajax('get', '/sale/type/channel/list', {ownerId: common.getUser().company.id}).then(data => {
    //         setTypeChannels(data)
    //     }).finally(common.loadingStop)
    // }
    // useEffect(getTypeChannels, [])

    return (
        <div className="order">
            <PageTop title="订单管理">
                <Button icon={<PlusOutlined/>} type="primary" onClick={handleMenuClick}>
                    新增
                </Button>
            </PageTop>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车主名称" className='label-character-3'>
                                <Input value={search.vehicleOwner} onChange={e => {
                                    setSearch({...search, vehicleOwner: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"订单类型"}>
                                <Select value={search.type} onChange={e => {
                                    setSearch({...search, type: e})
                                }}>
                                    <Option value={""} key={""}>请选择</Option>
                                    {
                                        typeArr.map(val => {
                                            return <Option key={val.key} value={val.key}>{val.value}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"销售渠道"}>
                                <Select value={search.channelId} onChange={e => {
                                    setSearch({...search, channelId: e})
                                }}>
                                    <Option key={""} value={""}>请选择</Option>
                                    {
                                        channel.map(val => {
                                            return <Option key={val.id} value={val.id}>{val.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={"订单状态"} className='label-character-3'>
                                <Select
                                    allowClear={true}
                                    value={search.statuses}
                                    mode="multiple"
                                    onChange={val => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    {
                                        statusArr.map(val => {
                                            return <Option key={val.key} value={val.key}>{val.value}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"创建日期"}>
                                <RangePicker
                                    value={[search.minCreatedAt ? moment(search.minCreatedAt) : null,
                                        search.maxCreatedAt ? moment(search.maxCreatedAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minCreatedAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                            maxCreatedAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                        })
                                    }}
                                    suffixIcon={<></>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"结算状态"}>
                                <Select value={search.settlementStatus} onChange={e => {
                                    setSearch({...search, settlementStatus: e})
                                }}>
                                    <Option key={0} value={0}>全部</Option>
                                    <Option key={2} value={2}>已结算</Option>
                                    <Option key={1} value={1}>未结算</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"VIN"} className='label-character-3'>
                                <Input value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库车型">
                                <Input
                                    value={search.productName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.productName === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', productName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择车型")
                                                setModalNumber(1)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择车型")
                                        setModalNumber(1)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售顾问">
                                <Input
                                    type="text"
                                    placeholder={'销售顾问'}
                                    suffix={
                                        search.saleName !== undefined ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择销售顾问")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择销售顾问")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                    value={
                                        search.saleName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    type="text"
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', customerName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNumber(3)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNumber(3)
                                        setVisible2(true)
                                    }}
                                    value={
                                        search.customerName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {/*信息来源 客户渠道 好像是线索来源*/}
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="信息来源">*/}
                        {/*        <Input*/}
                        {/*            type="text"*/}
                        {/*            suffix={*/}
                        {/*                search.saleName !== undefined ?*/}
                        {/*                    <CloseOutlined*/}
                        {/*                        onClick={() => {*/}
                        {/*                            setSearch({...search, saleId: '', saleName: ''})*/}
                        {/*                        }}/> : <span/>*/}
                        {/*            }*/}
                        {/*            addonAfter={*/}
                        {/*                <SelectOutlined*/}
                        {/*                    onClick={() => {*/}
                        {/*                        setModalTitle("选择销售顾问")*/}
                        {/*                        setModalNumber(2)*/}
                        {/*                        setVisible2(true)*/}
                        {/*                    }}/>*/}
                        {/*            }*/}
                        {/*            onKeyPress={() => {*/}
                        {/*                setModalTitle("选择销售顾问")*/}
                        {/*                setModalNumber(2)*/}
                        {/*                setVisible2(true)*/}
                        {/*            }}*/}
                        {/*            value={*/}
                        {/*                search.saleName*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="客户渠道">*/}
                        {/*        <Select*/}
                        {/*            onChange={(val) => {*/}
                        {/*            setSearch({*/}
                        {/*                ...search,*/}
                        {/*                channelId: val*/}
                        {/*            })*/}
                        {/*        }}>*/}
                        {/*            {*/}
                        {/*                typeChannels.map(item => {*/}
                        {/*                    return <Select.Option value={item.id}*/}
                        {/*                                          key={item.id}>{item.name}</Select.Option>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    let query = {...search}
                                    if (query.minCreatedAt && query.maxCreatedAt) {
                                        query['minCreatedAt'] = moment(query.minCreatedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                        query['maxCreatedAt'] = moment(query.maxCreatedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                    }
                                    if (query.statuses.length === 0) {
                                        query.statuses = statusArr.map(val => val.key)
                                    }
                                    setSearchNeedles(query)
                                    setPageInfo({...pageInfo, page: 1, pageSize: 15})
                                    Search.setParams(query)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initSearch)
                                    setSearchNeedles(initSearch)
                                    setPageInfo({...pageInfo, page: 1, pageSize: 15})
                                    Search.clearParams()
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initSearch} url={props.match.url}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleSortChange}
                rowKey={record => record.id} rowSelection={rowSelection} columns={columns}
                dataSource={dataSource} pagination={false}
                scroll={{x: '100%'}}
            />
            <PageBottom
                // children={
                //     <div>
                //         台数：{total}
                //         {Object.keys(subtotal).length > 0 &&
                //         <>（未审：{SaleOrder.STATUS_UNTRIED in subtotal ? subtotal[SaleOrder.STATUS_UNTRIED] : 0} 驳回：{SaleOrder.STATUS_AUDIT_REJECT in subtotal ? subtotal[SaleOrder.STATUS_AUDIT_REJECT] : 0} 通过：{SaleOrder.STATUS_ADOPT in subtotal ? subtotal[SaleOrder.STATUS_ADOPT] : 0} 车辆出库：{SaleOrder.STATUS_KIND_VEHICLE in subtotal ? subtotal[SaleOrder.STATUS_KIND_VEHICLE] : 0} 精品出库：{SaleOrder.STATUS_KIND_PARTS in subtotal ? subtotal[SaleOrder.STATUS_KIND_PARTS] : 0}）
                //         </>
                //         }
                //     </div>
                // }
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination pageSizeOptions={['15', '30', '50', '100']} showQuickJumper={true}
                                current={pageInfo.page}
                                showTotal={total => `共${total}条`}
                                total={total} defaultPageSize={pageInfo.pageSize}
                                defaultCurrent={pageInfo.page} showSizeChanger
                                onChange={(page, pageSize) => {
                                    // setState({...state, page: page, pageSize: pageSize})
                                    setPageInfo({...pageInfo, page: page, pageSize: pageSize})
                                }}
                    />
                }
            >
                {/*<Button icon={<TagOutlined/>}>批量处理1</Button>*/}
                {/*<Button>批量处理2</Button>*/}
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                    <LookupCustomer
                        isMultiple={false}
                        canChangeCompany={false}
                        onOk={val => {
                            setSearch({...search,
                                customerId: val.id,
                                customerName: val.name,
                            })
                            setVisible2(false)
                        }}
                    />
                }
                {modalNumber === 2 &&
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setSearch({...search, saleId: value.id, saleName: value.name})
                            setVisible2(false)
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />}

                {modalNumber === 1 &&
                    <LookupProduct
                        brandId={common.getUser().brand.id}
                        isMultiple={false}
                        onOk={(val) => {
                            setSearch({...search, productId: val.id, productName: val.name})
                            setVisible2(false)
                        }}/>}
            </Modal>

            {orderDetailVisible &&
                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1000}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={orderCode}/>
                </Modal>}
        </div>
    );
}

export default Order
