import React, {useEffect, useState} from "react";
import {Form, Radio} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";

const FormItem = Form.Item

function Setting() {
    const initialTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "sameBusinessType",
        name: "一个增值业务订单,只能选一类的产品",
        flag: "1",
    }

    let [taxonomy, setTaxonomy] = useState(initialTaxonomy)  //分类

    //设置参数
    let changeTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/setting', taxonomy)
            .then(res => {
                taxonomy = res || initialTaxonomy
                common.consoleLog("res", res)
                setTaxonomy(res)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //获取参数
    let getTaxonomy = () => {
        let params = {
            taxonomy: "sameBusinessType",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                taxonomy = res || initialTaxonomy
                common.consoleLog("res", res)
                setTaxonomy(taxonomy)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        common.consoleLog(common.getUser())
        getTaxonomy()
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"增值业务参数设置"}/>
            <Form>
                <FormItem label={taxonomy.name}>
                    <Radio.Group value={taxonomy.flag} onChange={e => {
                        taxonomy = {...taxonomy, flag: e.target.value}
                        setTaxonomy({...taxonomy})
                        changeTaxonomy()
                    }}>
                        <Radio value={"0"}>否</Radio>
                        <Radio value={"1"}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting