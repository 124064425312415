import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import moment from "moment";
import {Button, Modal, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import {Link} from "react-router-dom";
import common from "../../../../utils/common";
import OrderDetail from "../../../sale/plus/detail";
import GoodsProductDetail from "../../../../components/wms/finance/ProductGoodsReceiptDetail";
import GoodsReceiptBackDetail from "../../../../components/wms/finance/ProductGoodsReceiptBackDetail";
import CarReceiptDetail from "../../../../components/wms/CarReceiptForm";
import CarReceiptBackDetail from "../../../../components/wms/CarReceiptBackForm";
import {Allot, Product} from "../../../../components/wms/config";
import OutsourceDetail from "../../../../components/support/OutsourceDetail";
import DelegateDetail from "../../../../components/support/DelegateDetail";
import AvocationDetail from "../../../../components/avocation/AvocationOrderDetail";
import AllotGoodsDetail from "../../../../components/wms/finance/AllotGoodsDetail";
import AllotCarDetail from "../../../../components/wms/finance/AllotCarDetail";
import BigNumber from "bignumber.js";
import ApplyForm from "./applyForm";
import {EditOutlined, SaveOutlined} from "@ant-design/icons";
import DiffForm from "./diffForm";

let APPLY = Symbol()
let REVIEW = Symbol()
let PAYABLE = Symbol()
let REJECT = Symbol()

const REVIEW_YES = 3//通过
const REVIEW_NO = 2//驳回

const BUSINESS_SUPPORT = 1
const BUSINESS_TRANSFER = 2
const BUSINESS_INTERNAL = 3
const BUSINESS_PART = 4
const BUSINESS_PART_BACK = -4
const BUSINESS_ARTICLE = 5
const BUSINESS_ARTICLE_BACK = -5
const BUSINESS_CAR = 6
const BUSINESS_CAR_BACK = -6
const BUSINESS_VALUE_ADDED = 7
const BUSINESS_ORDER = 8

const BusinessName = {
    [BUSINESS_SUPPORT]: "委外维修",
    [BUSINESS_TRANSFER]: "调拨单",
    [BUSINESS_INTERNAL]: "委内业务",
    [BUSINESS_PART]: "配件入库",
    [BUSINESS_PART_BACK]: "配件入库退货",
    [BUSINESS_ARTICLE]: "精品入库",
    [BUSINESS_ARTICLE_BACK]: "精品入库退货",
    [BUSINESS_CAR]: "车辆入库",
    [BUSINESS_CAR_BACK]: "车辆入库退货",
    [BUSINESS_VALUE_ADDED]: "增值业务",
    [BUSINESS_ORDER]: "销售费用",
};

// 付款单明细/申请单明细
function Item(props) {

    const {listDataSource, customerId, type, onOk, applyType, selectOrderCode} = props

    let [parentKeys, setParentKeys] = useState([])
    let [parentRows, setParentRows] = useState([])
    let [childrenKeys, setChildrenKeys] = useState([])
    let [childrenRows, setChildrenRows] = useState([])
    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [supprtDetailVisible, setSupprtDetailVisible] = useState(false)
    let [transferDetailVisible, setTransferDetailVisible] = useState(false)
    let [internalDetailVisible, setInternalDetailVisible] = useState(false)
    let [partDetailVisible, setPartDetailVisible] = useState(false)
    let [articleDetailVisible, setArticleDetailVisible] = useState(false)
    let [carDetailVisible, setCarDetailVisible] = useState(false)
    let [valueAddedDetailVisible, setValueAddedDetailVisible] = useState(false)
    let [returnDetailVisible, setReturnDetailVisible] = useState(false)
    let [lookupOutsourceDetailVisible, setLookupOutsourceDetailVisible] = useState(false)
    let [code, setCode] = useState("")
    let [modalData, setModalData] = useState({})
    let [modalData2, setModalData2] = useState([])
    let [modalNumber, setModalNumber] = useState(0)
    let [applyFormVisible, setApplyFormVisible] = useState(false)
    let [applyId, setApplyId] = useState('')
    let [applyInfoVisible, setApplyInfoVisible] = useState(false)
    let [diffFormVisible, setDiffFormVisible] = useState(false)
    let [diffFormList, setDiffFormList] = useState([])

    const ParentColumns = [
        {
            title: "申请日期",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "申请编号",
            dataIndex: "code",
            key: "code",
            align: "center",
            ellipsis: true,
            width: 150,
            render: (text, record) => {
                return (
                    <Button type={'link'} onClick={(e) => {
                        setApplyId(record.id)
                        setApplyInfoVisible(true)
                        e.stopPropagation()
                    }}>{text}</Button>
                )
            }
        },
        {
            title: "申请人",
            dataIndex: "creatorName",
            key: "creatorName",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return text ? text : ''
            }
        },
        {
            title: "待付金额",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return common.numberFormat(new BigNumber(record.total).minus(new BigNumber(record.paid)).toString())
            }
        },
        {
            title: "差额",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return new BigNumber(record.total).minus(new BigNumber(record.invoiceMoney)).toString()
            }
        },
        {
            title: "发票金额",
            dataIndex: "invoiceMoney",
            key: "invoiceMoney",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "发票编号",
            dataIndex: "invoiceNo",
            key: "invoiceNo",
            align: "center",
            ellipsis: true,
            width: 100
        },
        {
            title: "发票类型",
            dataIndex: "invoiceTypeCodeName",
            key: "invoiceTypeCodeName",
            align: "center",
            ellipsis: true,
            width: 100
        },
        {
            title: "申请说明",
            dataIndex: "spec",
            key: "spec",
            align: "center",
            ellipsis: true,
            width: 200,
        },
    ]

    const childrenColumns = [
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            width: 150,
            ellipsis: true
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Button type={'link'} onClick={(e) => {
                        setCode(text)
                        switch (record.businessType) {
                            case BUSINESS_ORDER:
                                setOrderDetailVisible(true)
                                break;
                            case BUSINESS_SUPPORT:
                                setSupprtDetailVisible(true)
                                break;
                            case BUSINESS_TRANSFER:
                                getTransferData(text)
                                break;
                            case BUSINESS_INTERNAL:
                                setInternalDetailVisible(true)
                                break;
                            case BUSINESS_PART:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_ARTICLE:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_CAR:
                                getCarReceipt(text)
                                break;
                            case BUSINESS_VALUE_ADDED:
                                setValueAddedDetailVisible(true)
                                break;
                            case BUSINESS_PART_BACK:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_ARTICLE_BACK:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_CAR_BACK:
                                getReceiptReturn(text)
                                break;
                            default:
                                break;
                        }
                        e.stopPropagation()
                    }}>{text}</Button>
                )
            }
        },
        {
            title: "业务类型",
            dataIndex: "businessType",
            render: (text, record) => {
                return (
                    BusinessName[text]
                )
            },
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true
        },
        {
            title: "应付金额",
            dataIndex: 'total',
            key: 'total',
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: 'paid',
            key: 'paid',
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(new BigNumber(record.total).minus(record.paid).toString())
            }
        },
        {
            title: "客户名称",
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
            align: "center",
            width: 200,
            render: (text, record, index) => {
                return text ? text : '-'
            }
        },
        {
            title: "VIN",
            dataIndex: 'vin',
            key: 'vin',
            ellipsis: true,
            align: "center",
            width: 150,
            render: (text, record, index) => {
                return text ? text : '-'
            }
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            key: 'plate',
            ellipsis: true,
            align: "center",
            width: 100,
            render: (text, record, index) => {
                return text ? text : '-'
            }
        },
        {
            title: "业务员",
            dataIndex: 'businessSaleName',
            key: 'businessSaleName',
            ellipsis: true,
            align: "center",
            width: 100,
            render: (text, record, index) => {
                return record.businessSaleName ? record.businessSaleName : record.creatorName
            }
        },
    ]

    let getType = () => {
        if (type == 'payable') {
            return PAYABLE
        } else if (type == 'apply') {
            return APPLY
        } else if (type == 'review') {
            return REVIEW
        } else if (type == 'reject') {
            return REJECT
        }
    }

    let getCarReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            receiptCode: params,
            ownerId: common.getUser().company.id,
            kind: Product.KIND_CAR
        }).then((data) => {
            setModalData({
                ...data.receiptItems[0].receiptDto,
                receiptItemDto: data.receiptItems[0],
                goodsDto: data.receiptItems[0].goodsDto,
                productDto: data.receiptItems[0].productDto
            })
            setCarDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getGoodsReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params
        }).then((data) => {
            setModalData2(data.receiptItems)
            setPartDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getTransferData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/findByCode", {
            code: params
        }).then((data) => {
            common.consoleLog(data)
            setModalData(data)
            if (data.type === Allot.TYPE_CAR) {
                setModalNumber(4)
            } else {
                setModalNumber(3)
            }
            setTransferDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getReceiptReturn = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params,
            // type: Receipt.RECEIPT_TYPE_RETURN_BUY,
        }).then((data) => {
            setModalData2(data.receiptItems)
            if (data.receiptItems[0].goodsDto == null) {
                setModalNumber(1)
            } else {
                setModalNumber(2)
            }
            setReturnDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    /**
     * 父表单选
     */
    let onParentSelectChange = (record, selected, selectedRows) => {
        //父表和子表所选中的keys
        let parentKeyArr = [...parentKeys]
        let childrenKeyArr = [...childrenKeys]

        //选中的父表下所有的子表id
        let childrenIds = listDataSource.find(item => item.id == record.id).payableList.map(item => item.id)

        //selected：true 选中 false 取消
        if (selected) {
            //父表添加选中的数据 子表keys添加当前父表下所有的key
            parentKeyArr.push(record.id)
            childrenKeyArr = Array.from(new Set([...childrenKeyArr, ...childrenIds]))
        } else {
            //父表keys删除当前取消的数据 子表keys删除所有属于此数据下的数据
            parentKeyArr.splice(parentKeyArr.findIndex(item => item == record.id), 1)
            childrenKeyArr = childrenKeyArr.filter(item => !childrenIds.some(e => e === item))
        }

        setParentKeys(parentKeyArr)
        setChildrenKeys(childrenKeyArr)
    }

    /**
     * 父表全选
     */
    let onParentSelectAllChange = (selected, selectedRows, changeRows) => {
        //父表keys 和子表 keys
        let parentKeyArr = [...parentKeys]
        let childrenIds = []

        //将子表所有的key添加进keys数组中
        changeRows.forEach(e => {
            childrenIds = [...childrenIds, ...e.payableList.map(item => item.id)]
        });

        if (selected) {
            //父表所有数据选中
            parentKeyArr = Array.from(new Set([...parentKeyArr, ...changeRows.map(item => item.id)]))
        } else {
            parentKeyArr = []
            childrenIds = []
        }

        setParentKeys(parentKeyArr)
        setChildrenKeys(childrenIds)
    }

    /**
     * 子表单选
     */
    let onChildrenSelectChange = (record, selected, selectedRows) => {
        //子表keys
        let keyArr = [...childrenKeys]

        if (selected) {
            //添加当前选中的数据
            keyArr.push(record.id)
        } else {
            //删除当前选中的数据
            keyArr.splice(keyArr.findIndex(item => item == record.id), 1)
        }

        //排除调undefined数据
        selectedRows = selectedRows.filter(item => item !== undefined)

        //父表响应
        onChildrenToParent('select', selected, selectedRows, record)

        setChildrenKeys(keyArr)
    }

    /**
     * 子表全选
     */
    let onChildrenSelectAllChange = (selected, selectedRows, changeRows) => {
        let keyArr = [...childrenKeys]

        if (selected) {
            keyArr = Array.from(new Set([...keyArr, ...changeRows.map(item => item.id)]))
        } else {
            keyArr = keyArr.filter(item => !changeRows.some(row => row.id == item))
        }

        selectedRows = selectedRows.filter(item => item !== undefined)

        onChildrenToParent('selectAll', selected, changeRows, undefined)

        setChildrenKeys(keyArr)
    }

    /**
     * 子表数据选中或取消父表响应
     *  type:单选或者全选
     *  selected:取消 false 选中 true
     *  changeRows: 影响的数据
     *  record:单选时进行操作的数据
     */
    let onChildrenToParent = (type, selected, changeRows, record) => {
        //父表keys
        let keyArr = [...parentKeys]

        //子表单选
        if (type == 'select') {
            //循环父表数据
            for (let item of listDataSource) {
                //查找出操作的子表数据所属的父表数据
                if (item.payableList.find(v => v.id == record.id)) {
                    //子表数据全都选中的情况
                    if (item.payableList.length == changeRows.length) {
                        keyArr.push(item.id)
                    } else {
                        //子表数据无全选中 父表keys中包含操作的子表数据所属的父表key 将其删除
                        if (keyArr.length > 0 && keyArr.find(a => a == item.id)) {
                            keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                        }
                    }
                    break
                }
            }
            //子表全选
        } else if (type == 'selectAll') {
            //循环父表数据
            for (let item of listDataSource) {
                //查找出操作的子表数据所属的父表数据
                if (item.payableList.find(v => v.id == changeRows[0].id)) {
                    if (selected) {
                        //选中的情况 父表keys加入当前操作的子表数据所属的父表key
                        keyArr.push(item.id)
                    } else {
                        //取消的情况 父表keys删除当前操作的子表数据所属的父表key
                        keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                    }
                    break;
                }
            }
        }

        setParentKeys(keyArr)
    }

    const rowSelection = {
        selectedRowKeys: parentKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setParentKeys(selectedRowKeys)
            setParentRows(selectedRows)
        },
    };

    const parentRowSelection = {
        selectedRowKeys: parentKeys,
        type: getType() == REJECT ? applyType == 'review' ? "checkbox" : "radio" : "checkbox",
        onSelect: onParentSelectChange,
        onSelectAll: onParentSelectAllChange,
        getCheckboxProps: (record) => ({
            disabled: function () {
                if (getType() == REJECT) {
                    if (applyType == 'review') {
                        return record.status != 3
                    }

                    return record.status != 2
                }
            }(),
        }),
    }

    const childrenRowSelection = {
        selectedRowKeys: childrenKeys,
        type: "checkbox",
        onSelect: onChildrenSelectChange,
        onSelectAll: onChildrenSelectAllChange,
    }

    let expandedRowRender = (record, index) => {
        let arr = record.payableList
        return <Table style={{marginTop: '10px', marginBottom: '10px'}} className={'children_table'} rowKey={'id'}
                      columns={childrenColumns}
                      dataSource={arr}
                      pagination={false}/>;
    }

    let onReview = (status) => {
        if (status == REVIEW_YES) {
            let list = listDataSource.filter(item => parentKeys.indexOf(item.id) != -1 && parseInt(item.total) != parseInt(item.invoiceMoney)).map(item => item)

            if (list.length > 0) {
                let a = list.filter(item => parseInt(item.total) < 0).map(item => item)
                let b = list.filter(item => parseInt(item.total) > 0).map(item => item)
                common.consoleLog('a', a, 'b', b, list)
                if (a.length > 0) {
                    let codes = a.map(item => item.code)
                    common.alert(`申请单号 ${codes.join(',')} 需要驳回`)
                }
                if (b.length > 0) {
                    setDiffFormList(b)
                    setDiffFormVisible(true)
                }
                return
            }
        }

        let params = {
            status: status,
            ownerId: common.getUser().company.id,
            applyIds: parentKeys
        }

        common.loadingStart()
        common.ajax('post', '/finance/apply/review', params).then(res => {
            if (status == REVIEW_YES) {
                common.toast('审核成功')
            } else {
                common.toast('驳回成功')
            }
            onOk()
        }).finally(common.loadingStop)
    }

    let cancelReview = () => {
        let params = {
            applyIds: parentKeys,
            ownerId: common.getUser().company.id
        }
        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/finance/apply/cancel', params).then(res => {
            common.toast('取消成功')
            onOk()
        }).finally(common.loadingStop)
    }

    let disableReview = () => {
        let params = {
            applyIds: parentKeys,
            ownerId: common.getUser().company.id
        }
        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/finance/apply/disable', params).then(res => {
            common.toast('作废成功')
            onOk()
        }).finally(common.loadingStop)
    }

    // 设置选中的列
    useEffect(() => {
        common.consoleLog("code", selectOrderCode)
        if (!selectOrderCode) {
            return
        }
        listDataSource.map(item => {
            if (item.businessOrderCode === selectOrderCode) {
                parentKeys = [item.id]
                setParentKeys([...parentKeys])
            }
        })
    }, [props])

    return (
        <>
            <PageTop title={(getType() === REJECT || getType() === REVIEW) ? '申请单明细' : "应付款明细"}/>

            <Table
                className={getType() === PAYABLE ? 'parent-table' : ''}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                expandable={(getType() === PAYABLE || getType() === REVIEW || getType() === REJECT) ? {expandedRowRender: expandedRowRender,} : null}
                rowSelection={(getType() === PAYABLE || getType() === REVIEW || getType() === REJECT) ? parentRowSelection : rowSelection}
                pagination={false}
                dataSource={listDataSource}
                columns={(getType() === PAYABLE || getType() === REVIEW || getType() === REJECT) ? ParentColumns : childrenColumns}
                summary={(currentData) => {
                    let paid = "0.00" // 已付金额
                    let total = "0.00" // 应付金额
                    let invoiceMoney = "0.00" // 应付金额
                    currentData.forEach(item => {
                        paid = common.numberHalfUp(new BigNumber(paid).plus(item.paid).toString(), 2);
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2);
                        invoiceMoney = common.numberHalfUp(new BigNumber(invoiceMoney).plus(item.invoiceMoney).toString(), 2);
                    })
                    return (
                        <tr>
                            {type === 'apply' ?
                                <>
                                    <th/>
                                    <th>合计</th>
                                    <td colSpan={3}/>
                                    <td align={"right"}> {total}</td>
                                    <td align={"right"}> {paid}</td>
                                    {/*应付余额*/}
                                    <td align={"right"}>{common.numberHalfUp(new BigNumber(total).minus(paid).toString(), 2)} </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </>
                                : null
                            }

                            {/* 应付款明细 */}
                            {type === 'payable' ?
                                <>
                                    <th/>
                                    <th/>
                                    <th>合计</th>
                                    <td colSpan={2}/>
                                    {/*代付金额*/}
                                    <td align={"right"}> {common.numberHalfUp(new BigNumber(total).minus(paid).toString(), 2)}</td>
                                    {/*差额*/}
                                    <td align={"right"}> {common.numberHalfUp(new BigNumber(total).minus(invoiceMoney).toString(), 2)}</td>
                                    {/*发票金额*/}
                                    <td align={"right"}>{invoiceMoney} </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </>
                                : null
                            }

                            {/*付款审核*/}
                            {type === 'review' ?
                                <>
                                    <th/>
                                    <th/>
                                    <th>合计</th>
                                    <td colSpan={2}/>
                                    {/*代付金额*/}
                                    <td align={"right"}> {common.numberHalfUp(new BigNumber(total).minus(paid).toString(), 2)}</td>
                                    {/*差额*/}
                                    <td align={"right"}> {common.numberHalfUp(new BigNumber(total).minus(invoiceMoney).toString(), 2)}</td>
                                    {/*发票金额*/}
                                    <td align={"right"}>{invoiceMoney} </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </>
                                : null
                            }
                        </tr>
                    )
                }}
            />

            <PageBottom>
                {
                    getType() == REVIEW &&
                    <div>
                        <Button type="primary" disabled={!parentKeys.length > 0} onClick={() => {
                            common.confirm('确定通过吗?', () => {
                                onReview(REVIEW_YES)
                            })
                        }}>通过</Button>
                        <Button type="danger" disabled={!parentKeys.length > 0} onClick={() => {
                            common.confirm('确定驳回吗?', () => {
                                onReview(REVIEW_NO)
                            })
                        }}>驳回</Button>
                    </div>
                }

                {
                    getType() == APPLY &&
                    <Button icon={<SaveOutlined/>} type="primary" disabled={!parentKeys.length > 0} onClick={() => {
                        setApplyFormVisible(true)
                    }}>生成付款申请单</Button>
                }

                {
                    getType() == PAYABLE &&
                    <div>
                        <Link type="primary"
                              to={`/finance/pay?cid=${customerId}&ids=${parentKeys.join("&ids=")}&payType=pay`}>
                            <Button disabled={!parentKeys.length > 0}>付款</Button>
                        </Link>
                        <Link type="primary"
                              to={`/finance/pay?cid=${customerId}&ids=${parentKeys.join("&ids=")}&payType=cancel`}>
                            <Button disabled={!parentKeys.length > 0}>取消付款</Button>
                        </Link>
                    </div>
                }

                {
                    getType() == REJECT ?
                        applyType == 'review' ?
                            <Button type="primary" disabled={!parentKeys.length > 0}
                                    onClick={cancelReview}>取消审核</Button>
                            :
                            <div>
                                <Button icon={<EditOutlined/>} type="primary" disabled={!parentKeys.length > 0}
                                    onClick={() => setApplyFormVisible(true)}>编辑</Button>
                                <Button icon={<EditOutlined/>} type="primary" disabled={!parentKeys.length > 0}
                                    onClick={disableReview}>作废申请单</Button>
                            </div> : null
                }
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    visible={diffFormVisible}
                    title={''}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setDiffFormVisible(false)}
                >
                    <DiffForm list={diffFormList} ids={listDataSource.map(item => item.id)} onOk={() => {
                        onOk()
                    }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={applyInfoVisible}
                    title={''}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setApplyInfoVisible(false)}
                >
                    <ApplyForm type={'update'} applyId={applyId} customerId={customerId} isDisabled={true}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={applyFormVisible}
                    title={''}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setApplyFormVisible(false)}
                >
                    {
                        getType() == REJECT &&
                        <ApplyForm type={'update'} applyId={parentKeys[0]} customerId={customerId} onOk={() => {
                            setApplyFormVisible(false)
                            onOk()
                        }}/>
                    }

                    {
                        getType() == APPLY &&
                        <ApplyForm type={'create'} payableIds={parentKeys} customerId={customerId} onOk={() => {
                            setApplyFormVisible(false)
                        }}/>
                    }
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1200}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={code}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={supprtDetailVisible}
                    title={'委外详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setSupprtDetailVisible(false)}
                >
                    <OutsourceDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={transferDetailVisible}
                    title={'调拨单详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setTransferDetailVisible(false)}
                >
                    {modalNumber === 3 &&
                    <AllotGoodsDetail defaultValue={modalData}/>}
                    {modalNumber === 4 &&
                    <AllotCarDetail defaultValue={modalData}/>}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={internalDetailVisible}
                    title={'委内详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setInternalDetailVisible(false)}
                >
                    <DelegateDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={partDetailVisible}
                    title={'配件入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setPartDetailVisible(false)}
                >
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'part'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={articleDetailVisible}
                    title={'精品入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setArticleDetailVisible(false)}
                >
                    {/*配件action = {'part'}*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'boutique'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={carDetailVisible}
                    title={'车辆入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setCarDetailVisible(false)}
                >
                    <CarReceiptDetail action={'look'} defaultValue={modalData}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={valueAddedDetailVisible}
                    title={'增值业务详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setValueAddedDetailVisible(false)}
                >
                    <AvocationDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={returnDetailVisible}
                    title={'入库退货详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setReturnDetailVisible(false)}
                >
                    {modalNumber === 1 &&
                    <GoodsReceiptBackDetail defaultValue={modalData2} action={modalData2[0].receiptDto.type}/>}
                    {modalNumber === 2 &&
                    <CarReceiptBackDetail defaultValue={modalData2}/>}
                </Modal>
            </div>
        </>
    )
}

export default Item
