import React, {useEffect, useRef, useState} from 'react'
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip
} from 'antd'
import {
    CheckOutlined, CheckSquareOutlined, CloseOutlined, PlusOutlined,
    MinusCircleOutlined, QuestionCircleOutlined, RollbackOutlined,
    SaveOutlined, SelectOutlined, UnorderedListOutlined, RedoOutlined, EditOutlined
} from "@ant-design/icons"
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/avocation/LookupProduct"
import LookupCustomer from "../../../../components/passport/LookupCustomer"
import LookupEmployee from "../../../../components/passport/LookupEmployee"
import LookupVehicle from "../../../../components/support/LookupVehicle"
import LookupPartner from "../../../../components/passport/LookupPartner"
import LookupCompany from "../../../../components/passport/LookupCompany"
import LookupOrder from "../../../../components/sale/LookupPrepay"
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import LookupBusinessAttr from "../../../../components/avocation/LookupBusinessAttr";
import {BigNumber} from "bignumber.js";
import moment from "moment"
import {Order, Product} from "../../config"
import "./edit.less"
import CustomerForm from "../../../../components/passport/CustomerForm";
import VehiclePremiumForm from "../../../../components/support/VehiclePremiumForm";

// 收款方式 1.合作单位自行收款 2.财务公司代收 3.财务公司收入
const COLLECTION_TYPE_PARTNER = 1;
const COLLECTION_TYPE_FINANCE_COLLECT = 2;
const COLLECTION_TYPE_FINANCE_INCOME = 3;

// 订单状态  7:订单结算（未收款可取消结算） 8:订单收款（退款）
const STATUS_RECEIVABLE = 7;
const STATUS_PAID = 8;

const {RangePicker} = DatePicker;
// 使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

// 支出方式  1.成本 2.费用
const PAY_TYPE_COST = 1
const PAY_TYPE_FEE = 2
const PAY_TYPE_INTEGRAL = 3

const payTypeAlias = {
    [PAY_TYPE_COST]: "成本",
    [PAY_TYPE_FEE]: "费用",
    [PAY_TYPE_INTEGRAL]: "积分"
}

const FormItem = Form.Item
const {Option} = Select

// 是否关联订单状态  0.否  1.是
const BELONG_SALE_STATUS_NO = 0
const BELONG_SALE_STATUS_YES = 1

// 保险手续费返佣可以手动输入 0.否  1.是
// const INPUT_REBATE_RATE_YES = "1";
const INPUT_REBATE_RATE_NO = "0";

function Edit(props) {

    //  销售订单id
    let {id = '', saleOrderId, vehicleId, premiumId} = props.match.params

    const emptyInput = useRef();// 空标签
    const [form] = Form.useForm();

    // 初始化订单信息
    let initialOrder = {
        id: id, // 订单id
        ownerId: common.getUser().company.id,
        saleOrderId: '',
        saleUserId: '',
        saleUserName: '',
        code: '',
        premiumId: premiumId || "",
        performanceDepartmentId: '',
        performanceUserId: '',
        saleOrderCode: '',
        saleDepartmentId: '',
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        creatorName: common.getUser().nickname,
        items: [],
        actualAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        actualRemark: ''
    }

    // 初始化统计信息
    const initialStatistics = {
        price: "0.00",
        cost: "0.00",
        allCost: "0.00",
        total: "0.00",
    }

    // 产品明细初始化
    let initialOrderItem = {
        id: "",
        businessTypeId: "",
    }

    // 保险手续费返佣可以手动输入
    const initialInputRebateRateTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "inputRebateRate",
        name: "保险手续费返佣可以手动输入",
        flag: INPUT_REBATE_RATE_NO, // 默认为否
    }

    // 保险手续费比例扣税
    const initialRebateRateTaxTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "rebateRateTax",
        name: "保险手续费比例扣税",
        flag: "0", // 默认为0
    }

    // 保险手续费返佣可以手动输入
    let [inputRebateRateTaxonomy, setInputRebateRateTaxonomy] = useState(initialInputRebateRateTaxonomy)

    // 保险手续费比例扣税
    let [rebateRateTaxTaxonomy, setRebateRateTaxTaxonomy] = useState(initialRebateRateTaxTaxonomy)
    let [order, setOrder] = useState(initialOrder) // 订单表单
    let [vin, setVin] = useState("") // 车架号码
    let [departments, setDepartments] = useState([]) // 部门列表
    let [orderItems, setOrderItems] = useState([]) // 订单明细
    let [originProductMap, setOriginProductMap] = useState(new Map()) // 产品明细
    let [rebateRateMap, setRebateRateMap] = useState() // 手续费比例map
    let [partnerCompanyIds, setPartnerCompanyIds] = useState([]) // 合作单位的公司ids
    let [expandedRowKeys, setExpandedRowKeys] = useState([]) // 展开的子表格的keys
    let [isBelongSaleStatus, setIsBelongSaleStatus] = useState(BELONG_SALE_STATUS_NO) // 是否关联订单  默认 0.否
    let [editingProductIndex, setEditingProductIndex] = useState(undefined) // 编辑中数据记录
    let [statistics, setStatistics] = useState(initialStatistics) // 统计信息
    let [selectOrderItem, setSelectOrderItem] = useState(initialOrderItem) // 选择的业务类别id
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) // 选择客户
    let [lookupBusinessAttrVisible, setLookupBusinessAttrVisible] = useState(false) // 选择业务类别属性
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false) // 选择车辆
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择绩效人员
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 选择合作单位
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) // 选择财务公司
    let [lookupProductVisible, setLookupProductVisible] = useState(false) // 选择增值产品
    let [lookupOrderVisible, setLookupOrderVisible] = useState(false) // 选择订单
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) // 客户往来详情弹框
    let [vehiclePremiumFormVisible, setVehiclePremiumFormVisible] = useState(false) // 修改车辆档案弹框
    let [customerFormVisible, setCustomerFormVisible] = useState(false) // 修改客户档案弹框

    // 单据摘要
    let [categoryItems, setCategoryItems] = useState([])
    let [categoryName, setCategoryName] = useState("")

    // 通过Taxonomy获取单据摘要信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/term/list', {
            ownerId: common.getUser().company.id,
            taxonomy: 'actualRemark',
            limit: 1000
        }).then(data => {
            setCategoryItems(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getCategory, [])

    let onNameChange = event => {
        setCategoryName(event.target.value)
    };

    let addItem = () => {
        if (categoryName === null || categoryName === '') {
            common.toast("类别不能为空")
            return false
        }
        let arr = categoryItems.filter(item => item.name === categoryName)
        if (arr.length > 0) {
            common.toast("类别已存在")
            return false
        }
        let term = {
            ownerId: common.getUser().company.id,
            taxonomy: 'actualRemark',
            name: categoryName,
            parentId: '',
            flag: '',
            spec: '',
            sort: 0,
            status: 1,
            companyIds: [],
        }
        common.loadingStart();
        common.ajax('post', '/avocation/term/create', term).then((data) => {
            common.toast("添加成功", () => {
            }, 1);
            getCategory();
        }).finally(() => {
            common.loadingStop();
        })
        setCategoryName('')
    };

    // 返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (!id) {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    // 获取更改保险手续费返佣是否手动输入
    let getInputRebateRateTaxonomy = () => {
        let params = {
            taxonomy: "inputRebateRate",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                common.consoleLog("Taxonomy", res)
                setInputRebateRateTaxonomy(res || initialInputRebateRateTaxonomy)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getInputRebateRateTaxonomy()
    }, [])

    // 如果有车辆id则获取
    useEffect(() => {
        if (vehicleId) {
            common.loadingStart()
            common.ajax('get', '/support/vehicle/findById?id=' + vehicleId)
                .then(res => {
                    setOrder({ // 设置订单信息
                        ...order,
                        customerId: res.customerId,
                        customerName: res.customerName,
                        plate: res.plate,
                        vin: res.vin,
                        productName: res.productName,
                        seriesName: res.seriesName,
                        vehicleName: res.name,
                        vehiclePhone: res.phone,
                        vehicleId: res.id
                    })
                    // 设置表单信息
                    form.setFieldsValue({
                        vin: res.vin,
                        customerName: res.customerName
                    })
                })
                .finally(common.loadingStop)
        }
    }, [vehicleId])


    // 获取订单
    let getOrder = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/order/findById?id=' + id)
            .then(res => {
                originProductMap = new Map()
                common.consoleLog('order', res)
                order = res || initialOrder
                setOrder(res) // 设置订单
                form.setFieldsValue({...res})// 设置表单
                orderItems = res.items || []
                orderItems.forEach(item => {
                    item.name = item.product.name || ""
                    item.businessTypeId = item.product.businessTypeId || ""
                    item.businessTypeName = item.product.businessTypeName || ""
                    item.profitType = item.product.profitType || 0
                    item.rebateValue = item.product.rebateValue || "0.00"
                    originProductMap.set(item.productId, item.product)
                })
                setOrderItems([...orderItems])
                setOriginProductMap(originProductMap)
                common.consoleLog("originProductMap", originProductMap)
                // 处理订单明细
                handleOrderItems()
                // 获取收入计算方式为手续费比例的的合作公司id
                for (let i = 0; i < orderItems.length; i++) {
                    if (orderItems[i].profitType === Product.PROFIT_TYPE_RATE) {
                        if (orderItems[i].partnerCompanyId) {
                            partnerCompanyIds.push(orderItems[i].partnerCompanyId)
                        }
                    }
                }
                setPartnerCompanyIds([...partnerCompanyIds])
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (id) {
            getOrder()
        }
    }, [id])

    // 不存在id的时候获取用户信息
    useEffect(() => {
        if (!id) {
            common.loadingStart()
            common.ajax("get", "/passport/employee/findEmployeeByAuthUser", {}).then(data => {
                form.setFieldsValue({
                    performanceUserId: data.id,
                    performanceUserName: data.name,
                    performanceDepartmentId: data.departmentId
                })
                setOrder({
                    ...order,
                    performanceUserId: data.id,
                    performanceUserName: data.name,
                    performanceDepartmentId: data.departmentId
                })
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [])


    // 获取绩效部门列表
    const getDepartments = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy?taxonomy=department')
            .then(res => {
                res = res.filter(item => item.status !== 2)
                departments = res || []
                setDepartments(res)
            })
            .finally(common.loadingStop)
    }
    useEffect(getDepartments, [])


    // 查看是否含有销售订单id,有的话就获取并生成基本数据
    useEffect(() => {
        if (saleOrderId) {
            common.loadingStart()
            common.ajax('get', '/sale/order/findById?id=' + saleOrderId)
                .then(res => {
                    order = {
                        ...order,
                        saleOrderCode: res.code,
                        saleOrderId: res.id,
                        customerId: res.customerId,
                        customerName: res.customerName,
                        saleUserId: res.saleId,
                        saleUserName: res.saleName,
                        vehicleId: res.vehicle.id,
                        seriesName: res.vehicle.seriesName,
                        productName: res.vehicle.productName,
                        vehicleName: res.vehicleOwner,
                        vehiclePhone: res.vehicleOwnerPhone,
                        plate: res.vehicle.plate,
                        vin: res.vehicle.vin,
                        spec: res.partsRemark, // 业务备注
                    }
                    // 设置表单数据
                    setOrder({...order})
                    form.setFieldsValue({...order})

                    // 设置车架号码
                    setVin(res.vehicle.vin)
                })
                .finally(common.loadingStop)
        }
    }, [saleOrderId])


    // 获取车辆信息
    useEffect(() => {
        if (vin) {
            let params = {
                vin: vin,
                ownerId: common.getUser().company.id,
            }
            common.loadingStart()
            common.ajax('get', '/support/vehicle/findByVin', params)
                .then((res) => {
                    order = {
                        ...order,
                        vehicleId: res.id,
                        plate: res.plate,
                    }
                    // 设置表单数据
                    setOrder({...order})
                    form.setFieldsValue({...order})
                })
                .finally(() => {
                    common.loadingStop();
                })
        }
    }, [vin])


    /**
     * 根据数组对象的某个字段去重
     * item.name  是[{name:1}] 根据每条数据的name值来去重
     * */
    let unique = (arr, val) => {
        const res = new Map();
        return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
    }


    // 处理产品
    let handleProduct = (values) => {
        values.forEach(item => {
            originProductMap.set(item.id, item)
        })
        setOriginProductMap(originProductMap) // 设置原本的产品明细
        common.consoleLog("originProductMap", originProductMap)

        // 深拷产品
        let cloneProducts = JSON.parse(JSON.stringify(values));
        common.consoleLog("cloneProducts", cloneProducts)

        // 将产品信息转换为增值订单明细
        let newOrderItems = []
        cloneProducts.forEach(product => {
            newOrderItems.push({
                ...product,
                productId: product.id,
                id: ""
            })
        })
        // 设置订单明细 + 去重
        orderItems = [...orderItems, ...newOrderItems]
        orderItems = unique(orderItems, 'productId')
        setOrderItems([...orderItems, ...cloneProducts])

        // 处理订单明细
        handleOrderItems()

        // 关闭模态框
        setLookupProductVisible(false)
    }


    // 处理订单明细
    let handleOrderItems = () => {
        let hiddenPartnerCompanyButton = true  // 选择合作单位批量按钮
        let hiddenCollectionTypeButton = true  // 选择收入方式批量按钮
        let hiddenFinanceCompanyButton = true  // 选择财务公司批量按钮

        orderItems.forEach((orderItem) => {
            common.consoleLog("orderItem", orderItem)
            if (orderItem.product !== undefined) {
                // 编辑时
                orderItem.financeCompanyChange = orderItem.product.financeCompanyId === ""    // 不存在财务公司则设置为可以选择财务公司
                orderItem.partnerCompanyChange = orderItem.product.partnerCompanyId === "" // 不存在合作单位则设置为可以选择合作单位
                orderItem.collectionTypeChange = orderItem.product.collectionType === 0 // 不存在合作单位则设置为可以选择合作单位
                orderItem.costPriceChange = orderItem.product.costPrice === "0.00"  // 成本为0则设置可以修改成本价
                orderItem.rebateRateChange = orderItem.product.profitType === Product.PROFIT_TYPE_RATE // 收入方式为手续费比例则设置可以修改手续费比例
            } else {
                orderItem.quantity = 1
                // 新增时
                orderItem.financeCompanyChange = orderItem.financeCompany === undefined    // 不存在财务公司则设置为可以选择财务公司
                orderItem.partnerCompanyChange = orderItem.partnerCompany === undefined // 不存在合作单位则设置为可以选择合作单位
                orderItem.collectionTypeChange = orderItem.collectionType === 0 // 收入方式不存在则设置可以选择收入方式
                orderItem.costPriceChange = orderItem.costPrice === "0.00"  // 成本为0则设置可以修改成本价
                orderItem.rebateRateChange = orderItem.profitType === Product.PROFIT_TYPE_RATE // 收入方式为手续费比例则设置可以修改手续费比例
                orderItem.rebateRateTax = rebateRateTaxTaxonomy.flag // 返佣扣税比例
                orderItem.costValue = orderItem.costPrice
                orderItem.startAt = null // 有效期开始日期
                orderItem.endAt = null // 有效期截止日期
                orderItem.financeCompanyName = orderItem.financeCompany === undefined ? (orderItem.financeCompanyName ? orderItem.financeCompanyName : "") : orderItem.financeCompany.name // 设置财务公司
                orderItem.partnerCompanyName = orderItem.partnerCompany === undefined ? (orderItem.partnerCompanyName ? orderItem.partnerCompanyName : "") : orderItem.partnerCompany.name // 设置合作单位
                // orderItem.productId = orderItem.id
            }

            // 设置隐藏的财务公司批量操作按钮
            if (hiddenFinanceCompanyButton) {
                if (orderItem.product !== undefined) {
                    if (orderItem.product.financeCompanyId === "") {
                        orderItem.hiddenFinanceCompanyButton = false
                        hiddenFinanceCompanyButton = false
                    } else {
                        orderItem.hiddenFinanceCompanyButton = true
                    }
                } else {
                    if (orderItem.financeCompanyId === "") {
                        orderItem.hiddenFinanceCompanyButton = false
                        hiddenFinanceCompanyButton = false
                    }
                }
            } else {
                orderItem.hiddenFinanceCompanyButton = true
            }

            // 设置隐藏的合作单位批量操作按钮
            if (hiddenPartnerCompanyButton) {
                if (orderItem.product !== undefined) {
                    if (orderItem.product.partnerCompanyId === "") {
                        orderItem.hiddenPartnerCompanyButton = false
                        hiddenPartnerCompanyButton = false
                    } else {
                        orderItem.hiddenPartnerCompanyButton = true
                    }
                } else {
                    if (orderItem.partnerCompanyId === "") {
                        orderItem.hiddenPartnerCompanyButton = false
                        hiddenPartnerCompanyButton = false
                    }
                }
            } else {
                orderItem.hiddenPartnerCompanyButton = true
            }

            // 设置隐藏的收入方式批量操作按钮
            if (hiddenCollectionTypeButton) {
                if (orderItem.product !== undefined) {
                    if (orderItem.product.collectionType === 0) {
                        orderItem.hiddenCollectionTypeButton = false
                        hiddenCollectionTypeButton = false
                    } else {
                        orderItem.hiddenCollectionTypeButton = true
                    }
                } else {
                    if (orderItem.collectionType === 0) {
                        orderItem.hiddenCollectionTypeButton = false
                        hiddenCollectionTypeButton = false
                    }
                }
            } else {
                orderItem.hiddenCollectionTypeButton = true
            }
        })
        setOrderItems([...orderItems])
    }


    // 保存订单
    let handleOrder = () => {
        // 验证是否选择产品
        if (orderItems.length === 0) {
            return common.alert('请选择产品')
        }

        // 验证产品是否选择财务公司和合作单位
        for (let i = 0; i < orderItems.length; i++) {
            if (orderItems[i].financeCompanyId === "" || orderItems[i].partnerCompanyId === "") {
                common.alert("订单明细请选择财务公司和合作单位！")
                return false;  // 跳出方法
            }
        }

        orderItems.forEach(item => {  // 设置订单明细的车牌号
            item.plate = order.plate
        })
        if (!id) { // 新增
            createOrder()
        } else {  // 编辑
            editOrder()
        }
    }


    // 创建订单
    let createOrder = () => {
        orderItems.forEach(item => {
            if (item.attrs) {
                item.attrs.forEach(attr => {
                    attr.startAt = item.startAt
                    attr.endAt = item.endAt
                })
            }
        })
        setOrderItems([...orderItems])
        order.items = orderItems
        common.consoleLog("orderItems", orderItems)
        common.loadingStart()
        common.ajax('post', '/avocation/order/create', order)
            .then(res => {
                common.toast("新增成功")
                // props.history.push('/avocation/order')
                id = res.id
                getOrder();
                props.history.push('/avocation/order/edit/' + res.id)
            })
            .finally(common.loadingStop)
    }


    // 编辑订单
    let editOrder = () => {
        orderItems.forEach(item => {
            if (item.attrs) {
                item.attrs.forEach(attr => {
                    attr.startAt = item.startAt
                    attr.endAt = item.endAt
                })
            }
        })
        setOrderItems([...orderItems])
        order.items = orderItems
        common.consoleLog("orderItems", orderItems)
        common.loadingStart()
        common.ajax('post', '/avocation/order/updateById', order)
            .then(res => {
                common.toast("更新成功")
                getOrder();
            })
            .finally(common.loadingStop)
    }


    // 获取所有的合作单位比例
    let getRebateRateMap = () => {
        common.consoleLog("获取合作公司的手续费比例")
        let params = {
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/rebateRate/findAllRebateRateMapByOwnerId', params)
            .then((res) => {
                setRebateRateMap(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRebateRateMap()
    }, [])

    // 获取保险手续费比例扣税
    let getRebateRateTaxTaxonomy = () => {
        let params = {
            taxonomy: "rebateRateTax",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                setRebateRateTaxTaxonomy(res || initialRebateRateTaxTaxonomy)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getRebateRateTaxTaxonomy()
    }, [])

    // 检查订单明细是否存在关联订单的产品
    useEffect(() => {
        isBelongSaleStatus = false;
        orderItems.map(item => {
            if (item.belongSaleStatus === BELONG_SALE_STATUS_YES) {
                isBelongSaleStatus = true;
            }
        })
        setIsBelongSaleStatus(isBelongSaleStatus)
    }, [orderItems])


    // 退单
    let back = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/back?id=' + order.id)
            .then((res) => {
                if (res) {
                    common.toast("退单成功")
                    getOrder()
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }


    //取消预结算
    let prepayCancel = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/prepayCancel?id=' + order.id)
            .then(res => {
                if (res) {
                    common.toast("取消预结算成功")
                    getOrder()
                }
            })
            .finally(common.loadingStop())
    }


    //预结算
    let handlePrepay = () => {
        if (!order.id) {
            return
        }
        let data = {
            orderId: order.id,
            orderItems: orderItems
        }
        common.consoleLog("prepay", data)
        common.loadingStart()
        common.ajax('post', '/avocation/order/prepay', data)
            .then(res => {
                message.success('预结成功')
                getOrder()
            })
            .finally(common.loadingStop)
    }


    // 设置统计数据
    useEffect(() => {
        let price = "0.00"
        let allCost = "0.00"
        let total = "0.00"
        orderItems.forEach(item => {
            price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
            allCost = common.numberHalfUp(new BigNumber(allCost).plus(new BigNumber(item.costValue).multipliedBy(item.quantity)).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(new BigNumber(item.price).multipliedBy(item.quantity)).toString(), 2)
        })
        statistics = {
            price: price,
            allCost: allCost,
            total: total,
        }
        setStatistics({...statistics})
    }, [orderItems])


    // 手续费比例、单件返佣互算
    let handleRebate = (column, record, value) => {

        // 手续费比例更改
        if (column == 'rebateRate') {
            for (let i = 0; i < orderItems.length; i++) {
                if (orderItems[i].productId === record.productId) {
                    orderItems[i].rebateRate = value
                    let incomeValue = orderItems[i].incomeValue     // 单件佣金
                    // 按成交金额
                    if (record.profitType === Product.PROFIT_TYPE_TRANSACTION) {
                        incomeValue = "0.00"
                    }
                    // 如果收款方式按固定金额，则佣金等于 = 固定金额 / (1+返佣扣税比例)
                    else if (record.profitType === Product.PROFIT_TYPE_VALUE) {
                        incomeValue = common.numberHalfUp(new BigNumber(record.rebateValue)
                            .multipliedBy(100)
                            .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                            .toString(), 2)
                    }
                    // 如果收款方式是按手续费比例 * 成交金额，则佣金等于 = 手续费比例 * 成交金额 / (1+返佣扣税比例)
                    else if (record.profitType === Product.PROFIT_TYPE_RATE) {
                        incomeValue = common.numberHalfUp(new BigNumber(record.price)
                            .multipliedBy((record.rebateRate === undefined ? 0 : record.rebateRate))
                            .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                            .toString(), 2)
                    }
                    orderItems[i].incomeValue = incomeValue;
                }
            }
            setOrderItems([...orderItems])
        }

        // 佣金更改
        else if (column == 'incomeValue') {
            for (let i = 0; i < orderItems.length; i++) {
                if (orderItems[i].productId === record.productId) {

                    orderItems[i].incomeValue = value   // 单件佣金

                    let rebateRate = orderItems[i].rebateRate
                    // 如果收款方式按固定金额，则佣金等于 = 固定金额 / (1+返佣扣税比例)
                    if (record.profitType === Product.PROFIT_TYPE_VALUE) {
                        // rebateRate = common.numberHalfUp(new BigNumber(value)
                        //             .multipliedBy(new BigNumber(100).plus(record.rebateRateTax))
                        //             .dividedBy(new BigNumber(record.price))
                        //             .toString(), 2)
                        rebateRate = 0;
                    }
                        // 如果收款方式是按手续费比例 * 成交金额，则佣金等于 = 手续费比例 * 成交金额 / (1+返佣扣税比例)
                    // 手续费比例 = 佣金 * (1+返佣扣税比例) / 成交金额
                    else if (record.profitType === Product.PROFIT_TYPE_RATE) {
                        rebateRate = common.numberHalfUp(new BigNumber(value)
                            .multipliedBy(new BigNumber(100).plus(record.rebateRateTax))
                            .dividedBy(new BigNumber(record.price))
                            .toString(), 2)
                    }
                    orderItems[i].rebateRate = rebateRate;
                }
            }
            setOrderItems([...orderItems])
        }

        common.consoleLog("orderItemssss", orderItems)

    }


    // 增值订单明细列
    let columns = [
        {
            title: '产品名称',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input
                            value={text}
                            addonAfter={
                                <UnorderedListOutlined
                                    onClick={() => {
                                        selectOrderItem = record
                                        setSelectOrderItem(selectOrderItem)
                                        setLookupBusinessAttrVisible(true)
                                    }}/>}
                            onKeyDown={() => {
                                selectOrderItem = record
                                setSelectOrderItem(selectOrderItem)
                                setLookupBusinessAttrVisible(true)
                            }}/>
                    )
                }
                return (
                    <Input
                        ref={emptyInput}
                        value={record.name}
                        autoComplete="off"
                        placeholder="请选择产品"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupProductVisible(true)
                                }}/>
                        }
                        onKeyDown={() => {
                            setLookupProductVisible(true)
                        }}
                    />
                )
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '实际售价',
            dataIndex: 'price',
            key: 'price',
            align: "right",
            width: 90,
            render: (text, record) => {
                return record.isEmptyRecord ? '' : <InputNumber
                    value={text}
                    precision={2} min={record.minPrice}
                    onChange={value => {
                        for (let i = 0; i < orderItems.length; i++) {
                            if (record.productId === orderItems[i].productId) {
                                orderItems[i].price = value || 0
                                break
                            }
                        }
                        setOrderItems([...orderItems])
                    }}
                />
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 60,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    return (
                        <InputNumber
                            value={record.quantity} min={1}
                            onChange={value => {
                                orderItems.forEach(item => {
                                    if (record.productId === item.productId) {
                                        item.quantity = value
                                    }
                                })
                                setOrderItems([...orderItems])
                            }}/>
                    )
                }
            }
        },
        {
            title: '成本单价',
            dataIndex: 'costValue',
            key: 'costValue',
            width: 100,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    if (record.costPriceChange) {
                        return (
                            <InputNumber
                                value={text} precision={2} min={0}
                                onChange={value => {
                                    orderItems.forEach(item => {
                                        if (record.productId === item.productId) {
                                            item.costValue = value
                                        }
                                    })
                                    setOrderItems([...orderItems])
                                }}/>
                        )
                    } else {
                        return text
                    }
                }
            },
        },
        {
            title: '成交金额',
            width: 90,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                let total = common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(total)
            }
        },
        {
            title: "",
            width: 20,
        },
        {
            title: '支出计入',
            width: 100,
            dataIndex: "payType",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Select value={record.payType} style={{width: "100%"}} onChange={value => {
                        orderItems.forEach(item => {
                            if (record.productId === item.productId) {
                                item.payType = value
                            }
                        })
                        setOrderItems([...orderItems])
                    }}>
                        <Option value={0}>
                            请选择
                        </Option>
                        <Option key={PAY_TYPE_COST} value={PAY_TYPE_COST}>
                            {payTypeAlias[PAY_TYPE_COST]}
                        </Option>
                        <Option key={PAY_TYPE_FEE} value={PAY_TYPE_FEE}>
                            {payTypeAlias[PAY_TYPE_FEE]}
                        </Option>
                        <Option key={PAY_TYPE_INTEGRAL} value={PAY_TYPE_INTEGRAL}>
                            {payTypeAlias[PAY_TYPE_INTEGRAL]}
                        </Option>
                    </Select>
                )
            }
        },
        {
            title: '支出单价',
            width: 100,
            dataIndex: "payPrice",
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber precision={2} value={record.payPrice} onChange={value => {
                        orderItems.forEach(item => {
                            if (record.productId === item.productId) {
                                item.payPrice = value
                            }
                        })
                        setOrderItems([...orderItems])
                    }}/>
                )
            }
        },
        {
            title: '支出总价',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                let paytotal = common.numberHalfUp(new BigNumber(record.payPrice)
                    .multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(paytotal)
            }
        },
        {
            title: '',
            key: '',
            dataIndex: '',
            width: 20,
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            key: 'partnerCompanyName',
            width: 250,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    if (record.partnerCompanyChange) {
                        return (
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择合作单位'}
                                    value={record.partnerCompanyName}
                                    suffix={text ? <CloseOutlined onClick={() => {
                                        for (let i = 0; i < orderItems.length; i++) {
                                            if (record.productId === orderItems[i].productId) {
                                                orderItems[i].partnerCompanyName = ""
                                                orderItems[i].partnerCompanyId = ""
                                                if (record.rebateRateChange === true) {
                                                    // record.rebateRate = undefined
                                                    // 设置手续费比例
                                                    orderItems[i].rebateRate = originProductMap.get(record.productId).rebateRate
                                                }
                                            }
                                        }
                                        setOrderItems([...orderItems])
                                    }}/> : <span/>}
                                    addonAfter={<SelectOutlined
                                        onClick={() => {
                                            setEditingProductIndex(index)
                                            setLookupPartnerVisible(true)
                                        }}/>}
                                    onKeyPress={() => {
                                        setEditingProductIndex(index)
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                                <Button
                                    style={{marginLeft: "10px"}}
                                    icon={<CheckSquareOutlined/>}
                                    hidden={record.hiddenPartnerCompanyButton}
                                    onClick={() => {
                                        orderItems.forEach(item => {
                                            if (originProductMap.get(item.productId).partnerCompanyId === "") {
                                                common.consoleLog("partnerCompanyId", record.partnerCompanyId)
                                                item.partnerCompanyId = record.partnerCompanyId
                                                item.partnerCompanyName = text
                                                let key = item.partnerCompanyId + item.productId
                                                common.consoleLog("key", key)
                                                common.consoleLog("rebateRateMap[key]", rebateRateMap[key])
                                                item.rebateRate = rebateRateMap[key] || originProductMap.get(item.productId).rebateRate
                                            }
                                            setOrderItems([...orderItems])
                                        })
                                        setOrderItems([...orderItems])
                                    }}>批量
                                </Button>
                            </div>
                        )
                    } else {
                        return text
                    }
                }
            },
        },
        // {
        //     title: '收入计算方式',
        //     dataIndex: 'profitType',
        //     key: 'profitType',
        //     width: 150,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         return Product.profitTypes[text]
        //     }
        // },
        {
            title: common.can('avocation.rebate.rate') ? '手续费比例' : '',
            key: 'rebateRate',
            dataIndex: 'rebateRate',
            width: common.can('avocation.rebate.rate') ? 100 : 0,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                // 如果收入计算方式为比例,否则为"-"不显示
                if (record.profitType === Product.PROFIT_TYPE_RATE) {
                    return <InputNumber
                        parser={value => value.replace('%', '')}
                        formatter={value => `${value}%`}
                        value={text}
                        precision={2}
                        max={100}
                        min={0}
                        disabled={inputRebateRateTaxonomy.flag === INPUT_REBATE_RATE_NO} // todo 参数设置
                        /* onChange={value => {
                            for (let i = 0; i < orderItems.length; i++) {
                                if (orderItems[i].productId === record.productId) {
                                    orderItems[i].rebateRate = value
                                }
                            }
                            setOrderItems([...orderItems])
                        }}*/
                        onChange={value => {
                            handleRebate("rebateRate", record, value)
                        }}
                    />
                }
                return "-"
            }
        },
        {
            title: common.can('avocation.rebate.rate') ?
                <div>返佣扣税比例 <Tooltip title="重置为最新的返佣扣税比例">
                    <RedoOutlined style={{color: "#1890ff"}} hidden={order.status !== Order.STATUS_AGREED}
                                  onClick={() => {
                                      orderItems.forEach(item => {
                                          item.rebateRateTax = common
                                              .numberHalfUp(new BigNumber(rebateRateTaxTaxonomy.flag)
                                                  .toString(), 2)
                                      })
                                      setOrderItems([...orderItems])
                                  }}/>
                </Tooltip>
                </div> : "",
            width: common.can('avocation.rebate.rate') ? 150 : 0,
            ellipsis: true,
            align: 'right',
            dataIndex: "rebateRateTax",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                // 如果收款方式是按手续费比例 * 成交金额 或者 按固定金额
                if (record.profitType === Product.PROFIT_TYPE_RATE || record.profitType === Product.PROFIT_TYPE_VALUE) {
                    return text + "%"
                } else {
                    return '-'
                }
            }
        },
        {
            title: common.can('avocation.rebate.rate') ?
                <div>单件佣金<Tooltip title="实际售价*手续费比例/(1+返佣扣税比例)"> <QuestionCircleOutlined
                    style={{color: "#1890ff"}}/> </Tooltip></div> : "",
            width: common.can('avocation.rebate.rate') ? 130 : 0,
            ellipsis: true,
            align: 'right',
            // dataIndex: ['product', 'rebateValue'],
            dataIndex: 'incomeValue',
            render: (text, record) => {

                if (record.isEmptyRecord) {
                    return ''
                }

                // 初始化赋值
                if (record.id == "" || !record.incomeValue) {
                    // 如果收款方式按固定金额，则佣金等于 = 固定金额 / (1+返佣扣税比例)
                    if (record.profitType === Product.PROFIT_TYPE_VALUE) {
                        record.incomeValue = common.numberHalfUp(new BigNumber(record.rebateValue)
                            .multipliedBy(100)
                            .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                            .toString(), 2)
                    }
                    // 如果收款方式是按手续费比例 * 成交金额，则佣金等于 = 手续费比例 * 成交金额 / (1+返佣扣税比例)
                    else if (record.profitType === Product.PROFIT_TYPE_RATE) {
                        record.incomeValue = common.numberHalfUp(new BigNumber(record.price)
                            .multipliedBy((record.rebateRate === undefined ? 0 : record.rebateRate))
                            .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                            .toString(), 2)
                    }
                }

                /*
                // 如果收款方式按固定金额，则佣金等于 = 固定金额 / (1+返佣扣税比例)
                if (record.profitType === Product.PROFIT_TYPE_VALUE) {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.rebateValue)
                        .multipliedBy(100)
                        .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                        .toString(), 2))
                }
                // 如果收款方式是按手续费比例 * 成交金额，则佣金等于 = 手续费比例 * 成交金额 / (1+返佣扣税比例)
                else if (record.profitType === Product.PROFIT_TYPE_RATE) {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.price)
                        .multipliedBy(record.quantity)
                        .multipliedBy((record.rebateRate === undefined ? 0 : record.rebateRate))
                        .dividedBy(100)
                        .multipliedBy(100)
                        .dividedBy(new BigNumber(100).plus(record.rebateRateTax))
                        .toString(), 2))
                } else {
                    return '-'
                }
                */
                if (record.profitType === Product.PROFIT_TYPE_VALUE || record.profitType === Product.PROFIT_TYPE_RATE) {
                    return <InputNumber
                        value={record.incomeValue}
                        precision={2}
                        min={0}
                        disabled={inputRebateRateTaxonomy.flag === INPUT_REBATE_RATE_NO} // todo 参数设置
                        onChange={value => {
                            handleRebate("incomeValue", record, value)
                        }}
                    />
                }

                return "-";
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '收款方式',
            dataIndex: 'collectionType',
            key: 'collectionType',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (record.collectionTypeChange) {
                    return (
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <Select
                                style={{width: "100%"}} value={record.collectionType}
                                onChange={val => {
                                    for (let i = 0; i < orderItems.length; i++) {
                                        if (orderItems[i].productId === record.productId) {
                                            orderItems[i].collectionType = val
                                        }
                                    }
                                    setOrderItems([...orderItems])
                                }}>
                                <Option key={0} value={0}>请选择</Option>
                                <Option key={COLLECTION_TYPE_PARTNER}
                                        value={COLLECTION_TYPE_PARTNER}>{Product.collectionTypes[COLLECTION_TYPE_PARTNER]}</Option>
                                <Option key={COLLECTION_TYPE_FINANCE_COLLECT}
                                        value={COLLECTION_TYPE_FINANCE_COLLECT}>{Product.collectionTypes[COLLECTION_TYPE_FINANCE_COLLECT]}</Option>
                                <Option key={COLLECTION_TYPE_FINANCE_INCOME}
                                        value={COLLECTION_TYPE_FINANCE_INCOME}>{Product.collectionTypes[COLLECTION_TYPE_FINANCE_INCOME]}</Option>
                            </Select>
                            <Button icon={<CheckSquareOutlined/>}
                                    style={{marginLeft: "10px"}}
                                    hidden={record.hiddenCollectionTypeButton}
                                    onClick={() => {
                                        orderItems.forEach(item => {
                                            if (originProductMap.get(item.productId).collectionType === 0) {
                                                item.collectionType = record.collectionType
                                            }
                                        })
                                        setOrderItems([...orderItems])
                                    }}>批量
                            </Button>
                        </div>
                    )
                } else {
                    return Product.collectionTypes[text]
                }
            },
        },
        {
            title: '有效期',
            width: 300,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <RangePicker
                            style={{width: 200, marginRight: 10}}
                            format={"YYYY-MM-DD"}
                            value={[record.startAt === null ? null : moment(record.startAt), record.endAt === null ? null : moment(record.endAt)]}
                            onChange={(date, dateString) => {
                                common.consoleLog("dateString", dateString)
                                if (date === null) {
                                    for (let i = 0; i < orderItems.length; i++) {
                                        if (record.productId === orderItems[i].productId) {
                                            orderItems[i].startAt = null
                                            orderItems[i].endAt = null
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < orderItems.length; i++) {
                                        if (record.productId === orderItems[i].productId) {
                                            orderItems[i].startAt = dateString[0] + " 00:00:00"
                                            orderItems[i].endAt = dateString[1] + " 23:59:59"
                                        }
                                    }
                                }
                                setOrderItems([...orderItems])
                            }}/>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0)}
                                onClick={() => {
                                    orderItems.forEach(item => {
                                        item.startAt = record.startAt
                                        item.endAt = record.endAt
                                    })
                                    setOrderItems([...orderItems])
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '产品备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text ? text : '-'
            }
        },
        {
            title: '财务公司',
            dataIndex: 'financeCompanyName',
            key: 'financeCompany',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (record.financeCompanyChange) {
                    return (
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <Input
                                style={{width: 200, marginRight: 10}}
                                value={record.financeCompanyName}
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择财务公司'}
                                suffix={text ? <CloseOutlined onClick={() => {
                                    for (let i = 0; i < orderItems.length; i++) {
                                        if (orderItems[i].productId === record.productId) {
                                            orderItems[i].financeCompanyName = ""
                                            orderItems[i].financeCompanyId = ""
                                        }
                                    }
                                    setOrderItems([...orderItems])
                                }}/> : <span/>}
                                addonAfter={<SelectOutlined
                                    onClick={() => {
                                        setEditingProductIndex(index)
                                        setLookupCompanyVisible(true)
                                    }}/>}
                                onKeyPress={() => {
                                    setEditingProductIndex(index)
                                    setLookupCompanyVisible(true)
                                }}
                            />
                            <Button icon={<CheckSquareOutlined/>}
                                    hidden={record.hiddenFinanceCompanyButton}
                                    onClick={() => {
                                        orderItems.forEach(item => {
                                            if (originProductMap.get(item.productId).financeCompanyId === "") {
                                                item.financeCompanyId = record.financeCompanyId
                                                item.financeCompanyName = record.financeCompanyName
                                            }
                                        })
                                        setOrderItems([...orderItems])
                                    }}>批量
                            </Button>
                        </div>
                    )
                } else {
                    return text
                }
            },
        },
        {
            title: '操作',
            key: 'action',
            align: "center",
            width: 100,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Button
                        type='link'
                        onClick={() => {
                            setOrderItems(orderItems.filter(orderItem =>
                                orderItem.productId !== record.productId
                            ))
                        }}>删除</Button>
                )
            }
        },
    ]

    // 子表格
    let expandedRowRender = (orderItem) => {
        if (orderItem.isEmptyRecord) {
            return
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: "center",
                fixed: "left",
                width: 40,
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: '险种名称',
                width: 120,
                dataIndex: 'name',
                key: 'name',
                fixed: "left",
                render: (text, record) => {
                    return (
                        <Input value={text} onChange={e => {
                            // 修改险种
                            for (let i = 0; i < orderItem.attrs.length; i++) {
                                if (record.id === orderItem.attrs[i].id) {
                                    orderItem.attrs[i].name = e.target.value
                                }
                            }
                            // 设置订单明细
                            for (let i = 0; i < orderItems.length; i++) {
                                if (orderItem.id === orderItems[i].id) {
                                    orderItems[i] = orderItem
                                }
                            }
                            setOrderItems([...orderItems])
                        }}/>)
                }
            },
            {
                title: '险种分类',
                width: 100,
                render: () => {
                    return (orderItem.businessTypeName)
                }
            },
            {
                title: '保费金额',
                width: 110,
                dataIndex: 'premium',
                align: "right",
                render: (text, record) => {
                    return (
                        <InputNumber
                            precision={2}
                            min={0}
                            value={text}
                            onChange={value => {
                                for (let i = 0; i < orderItem.attrs.length; i++) {
                                    if (record.id === orderItem.attrs[i].id) {
                                        orderItem.attrs[i].premium = value
                                    }
                                }
                                // 设置订单明细
                                for (let i = 0; i < orderItems.length; i++) {
                                    if (orderItem.id === orderItems[i].id) {
                                        orderItems[i] = orderItem
                                    }
                                }
                                setOrderItems([...orderItems])
                            }}/>)
                }
            },
            {
                title: '投保额',
                width: 110,
                dataIndex: 'insured',
                key: 'insured',
                align: "right",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}万`}
                            parser={value => value.replace('万', '')}
                            value={text}
                            min={0}
                            onChange={value => {
                                for (let i = 0; i < orderItem.attrs.length; i++) {
                                    if (record.id === orderItem.attrs[i].id) {
                                        orderItem.attrs[i].insured = value
                                    }
                                }
                                // 设置订单明细
                                for (let i = 0; i < orderItems.length; i++) {
                                    if (orderItem.id === orderItems[i].id) {
                                        orderItems[i] = orderItem
                                    }
                                }
                                setOrderItems([...orderItems])
                            }}/>)
                }
            },
            {
                title: '保险公司',
                width: 150,
                align: "center",
                render: () => {
                    return <Input disabled value={orderItem.partnerCompanyName}/>
                }
            },
            {
                title: '有效期',
                width: 200,
                align: "center",
                ellipsis: true,
                render: () => {
                    return (
                        <RangePicker
                            disabled
                            format={"YYYY-MM-DD"}
                            readOnly
                            value={[
                                orderItem.startAt === null ? null : moment(orderItem.startAt),
                                orderItem.endAt === null ? null : moment(orderItem.endAt)
                            ]}
                        />
                    )
                }
            },
            {
                title: '保险单号',
                width: 150,
                dataIndex: 'no',
                key: 'no',
                render: (text, record) => {
                    return (
                        <Input value={text} onChange={e => {
                            // 修改险种
                            for (let i = 0; i < orderItem.attrs.length; i++) {
                                if (record.id === orderItem.attrs[i].id) {
                                    orderItem.attrs[i].no = e.target.value
                                }
                            }
                            // 设置订单明细
                            for (let i = 0; i < orderItems.length; i++) {
                                if (orderItem.id === orderItems[i].id) {
                                    orderItems[i] = orderItem
                                }
                            }
                            setOrderItems([...orderItems])
                        }}/>)
                }
            },
            {
                title: '备注',
                width: 140,
                dataIndex: 'remark',
                key: 'remark',
                render: (text, record) => {
                    return (
                        <Input.TextArea
                            rows={1} value={text}
                            onChange={e => {
                                // 修改险种
                                for (let i = 0; i < orderItem.attrs.length; i++) {
                                    if (record.id === orderItem.attrs[i].id) {
                                        orderItem.attrs[i].remark = e.target.value
                                    }
                                }
                                // 设置订单明细
                                for (let i = 0; i < orderItems.length; i++) {
                                    if (orderItem.id === orderItems[i].id) {
                                        orderItems[i] = orderItem
                                    }
                                }
                                setOrderItems([...orderItems])
                            }}/>)
                }
            },
            {
                title: '操作',
                align: "center",
                dataIndex: 'operation',
                key: 'operation',
                width: 80,
                fixed: "right",
                render: (text, record) => (
                    <Space size="middle">
                        <Button type={"link"} onClick={() => {
                            orderItem.attrs = orderItem.attrs.filter(item => item.id !== record.id)
                            // 设置订单明细
                            for (let i = 0; i < orderItems.length; i++) {
                                if (orderItem.productId === orderItems[i].productId) {
                                    orderItems[i] = orderItem
                                }
                            }
                            setOrderItems([...orderItems])
                        }}>删除</Button>
                    </Space>
                ),
            },
        ];

        return <Table scroll={{x: '100%'}}
                      rowKey={(record) => record.id}
                      columns={columns}
                      dataSource={orderItem.attrs}
                      pagination={false}/>;
    };

    return (
        <div id="avocation-order-edit-wrap">
            <PageTop title={getScene() === SCENE_NEW ? "新增订单" : "编辑订单"}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.push('/avocation/order')
                }}>返回</Button>

                <Button hidden={order.status !== Order.STATUS_AGREED} icon={<CheckOutlined/>}
                        onClick={handlePrepay}>预结算</Button>

                <Button icon={<MinusCircleOutlined/>}
                        disabled={order.status !== Order.STATUS_AGREED}
                        onClick={() => {
                            common.confirm("确认退单吗?", () => {
                                back()
                            })
                        }} type="primary" danger>退单</Button>
                <Button type='primary' icon={<SaveOutlined/>}
                        disabled={order.status > Order.STATUS_AGREED}
                        onClick={form.submit}>保存</Button>
                <Button type='primary' hidden={!(order.status === Order.STATUS_PRE_PAY)} icon={<CloseOutlined/>}
                        onClick={prepayCancel}>取消预结</Button>
            </PageTop>

            <Form form={form} className={'ant-advanced-inline-form'} onFinish={handleOrder}>
                {/*客户信息*/}
                <Row gutter={24}>
                    <Col span={6}>
                        <Row>
                            {/*关联单号*/}
                            <Col span={24}>
                                <FormItem name={"saleOrderCode"} label={'关联单号'}
                                          rules={[{
                                              required: isBelongSaleStatus,
                                              message: "关联单号不能为空"
                                          }]}
                                          className={'label-character-4'}>
                                    <Input
                                        readOnly
                                        value={order.saleOrderCode}
                                        autoComplete="off"
                                        placeholder={'请选择新车销售订单'}
                                        suffix={order.saleOrderCode ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    form.setFieldsValue({
                                                        ...order,
                                                        saleOrderId: "",
                                                        saleOrderCode: "",
                                                        customerId: "",
                                                        customerName: "",
                                                        customerPhone: "",
                                                        vin: "",
                                                    })
                                                    setOrder({
                                                        ...order,
                                                        customerId: "",
                                                        customerName: "",
                                                        customerPhone: "",
                                                        saleOrderId: "",
                                                        saleOrderCode: "",
                                                        vehicleId: "",
                                                        plate: "",
                                                        productName: "",
                                                        seriesName: "",
                                                        vehicleName: "",
                                                        vehiclePhone: "",
                                                        vin: ""
                                                    })
                                                }}/> : <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            setLookupOrderVisible(true)
                                        }
                                        }/>}
                                        onKeyPress={() => {
                                            setLookupOrderVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            {/*客户名称*/}
                            <Col span={24}>
                                <FormItem name={"customerName"} label={'客户名称'} className={'label-character-4'}
                                          rules={[{required: true, message: '请选择客户'}]}>
                                    <Input
                                        readOnly
                                        autoComplete="off"
                                        placeholder={'请选择客户'}
                                        suffix={order.customerId ?
                                            <Row gutter={8}>
                                                <Col span={id === "" ? 8 : 12}>
                                                    <Tooltip title="客户往来详情">
                                                        <QuestionCircleOutlined
                                                            style={{color: "#1890ff"}}
                                                            onClick={() => {
                                                                setLookupCustomerRecordVisible(true)
                                                            }}/>
                                                    </Tooltip>
                                                </Col>
                                                <Col span={id === "" ? 8 : 0}>
                                                    {
                                                        id === "" && common.can("important.customer.edit") ?
                                                            <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                                // 存在客户id才可以打开弹框
                                                                if (order.customerId) {
                                                                    setCustomerFormVisible(true)
                                                                } else {
                                                                    common.toast("客户不存在,无法修改客户档案")
                                                                }
                                                            }}/> : null
                                                    }
                                                </Col>
                                                <Col span={id === "" ? 8 : 12}>
                                                    <CloseOutlined onClick={() => {
                                                        form.setFieldsValue({
                                                            customerId: "",
                                                            customerName: "",
                                                            customerPhone: "",
                                                            vin: ""
                                                        })
                                                        setOrder({
                                                            ...order,
                                                            customerId: "",
                                                            customerName: "",
                                                            customerPhone: "",
                                                            vehicleId: "",
                                                            plate: "",
                                                            productName: "",
                                                            seriesName: "",
                                                            vehicleName: "",
                                                            vehiclePhone: "",
                                                            vin: ""
                                                        })
                                                    }}/>
                                                </Col>
                                            </Row>
                                            : <span/>}
                                        addonAfter={
                                            <SelectOutlined onClick={() => {
                                                if (order.saleOrderCode === "") {
                                                    setLookupCustomerVisible(true)
                                                }
                                            }}/>
                                        }
                                        onKeyPress={() => setLookupCustomerVisible(true)}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={'车牌号码'} className={'label-character-4'}>
                                    <Input value={order.plate} disabled/>
                                </FormItem>
                            </Col>
                            {/*联系电话*/}
                            <Col span={24}>
                                <FormItem label={'联系电话'} className={'label-character-4'}>
                                    <Input value={order.vehiclePhone} disabled/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"原单日期"} className={"label-character-4"}>
                                    <DatePicker
                                        disabledDate={false}
                                        showTime suffixIcon={null}
                                        value={order.actualAt ? moment(order.actualAt) : ''}
                                        format={"YYYY-MM-DD HH:mm"}
                                        onOk={(date) => {
                                            form.setFieldsValue({
                                                actualAt: date
                                            })
                                            order = {
                                                ...order,
                                                actualAt: date.format("YYYY-MM-DD HH:mm:ss"),
                                            }
                                            setOrder({...order})
                                        }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            {/*VIN号*/}
                            <Col span={24}>
                                <FormItem label={'VIN号码'} name={"vin"} className={'label-character-4'}
                                          rules={[{required: true, message: '请选择VIN号'}]}>
                                    <Input
                                        readOnly
                                        autoComplete="off"
                                        placeholder={'请选择车辆'}
                                        suffix={order.vehicleId ?
                                            <div>
                                                {
                                                    id === "" && common.can("important.vehicle.edit") ?
                                                        <>
                                                            <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                                // 存在车辆档案id和客户id才可以打开弹框
                                                                if (order.vehicleId && order.customerId) {
                                                                    setVehiclePremiumFormVisible(true)
                                                                } else {
                                                                    common.toast("客户或车辆不存在,无法修改车辆档案")
                                                                }
                                                            }}/>
                                                            &nbsp;&nbsp;
                                                        </> : null
                                                }
                                                <CloseOutlined onClick={() => {
                                                    setOrder({
                                                        ...order,
                                                        vehicleId: "",
                                                        plate: "",
                                                        productName: "",
                                                        seriesName: "",
                                                        vehicleName: "",
                                                        vehiclePhone: "",
                                                        vin: ""
                                                    })
                                                    form.setFieldsValue({
                                                        vin: ""
                                                    })
                                                }}/>
                                            </div>
                                            : <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            if (order.saleOrderCode === "") {
                                                setLookupVehicleVisible(true)
                                            }
                                        }}/>}
                                        onKeyPress={() => setLookupVehicleVisible(true)}
                                    />
                                </FormItem>
                            </Col>
                            {/*车型*/}
                            <Col span={24}>
                                <FormItem label={'销售车型'} className={'label-character-4'}>
                                    <Input value={order.productName} disabled/>
                                </FormItem>
                            </Col>
                            {/*车系*/}
                            <Col span={24}>
                                <FormItem label={'销售车系'} className={'label-character-4'}>
                                    <Input value={order.seriesName} disabled/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={'车主名称'} className={'label-character-4'}>
                                    <Input value={order.vehicleName} disabled/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"单据摘要"} className={'label-character-4'}>
                                    <Select
                                        value={order.actualRemark}
                                        onChange={value => {
                                            form.setFieldsValue({
                                                actualRemark: value.trim()
                                            })
                                            setOrder({...order, actualRemark: value.trim()})
                                        }}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{margin: '4px 0'}}/>
                                                <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                                    <Input style={{flex: 'auto'}} value={categoryName}
                                                           onChange={onNameChange}/>
                                                    <a
                                                        style={{
                                                            flex: 'none',
                                                            padding: '8px',
                                                            display: 'block',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={addItem}
                                                    >
                                                        <PlusOutlined/> 添加摘要
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {categoryItems.map(item => (
                                            <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}>
                                {/*销售顾问*/}
                                <Col span={24}>
                                    <FormItem label={'销售顾问'} className={'label-character-4'}>
                                        <Input disabled value={order.saleUserName}/>
                                    </FormItem>
                                </Col>
                                {/*绩效部门*/}
                                <Col span={24}>
                                    <FormItem
                                        label={'绩效部门'}
                                        className={'label-character-4'}
                                        name={"performanceDepartmentId"}
                                        rules={[{required: true, whitespace: true, message: '请选择绩效部门'}]}>
                                        <Select onChange={(value) => {
                                            form.setFieldsValue({
                                                performanceDepartmentId: value,
                                                performanceUserId: "",
                                                performanceUserName: ""
                                            })
                                            order = {
                                                ...order,
                                                performanceDepartmentId: value,
                                                performanceUserId: "",
                                                performanceUserName: ""
                                            }
                                            setOrder({...order})
                                        }}
                                                placeholder={'请选择绩效部门'}>
                                            {departments.map(department =>
                                                <Option key={department.id} value={department.id}>
                                                    {department.name}
                                                </Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                                {/*绩效人员*/}
                                <Col span={24}>
                                    <FormItem
                                        label={'绩效人员'} className={'label-character-4'}
                                        name={"performanceUserName"}
                                        rules={[{required: true, message: '请选择绩效人员'}]}>
                                        <Input
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择绩效人员'}
                                            suffix={order.performanceUserName ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        form.setFieldsValue({
                                                            performanceUserId: "",
                                                            performanceUserName: "",
                                                        })
                                                        setOrder({
                                                            ...order,
                                                            performanceUserId: "",
                                                            performanceUserName: "",
                                                        })
                                                    }}/> :
                                                <span/>}
                                            addonAfter={<SelectOutlined
                                                onClick={() => order.performanceDepartmentId ?
                                                    setLookupEmployeeVisible(true) :
                                                    message.info('请先选择绩效部门')
                                                }/>}
                                            onKeyPress={() => order.performanceDepartmentId ?
                                                setLookupEmployeeVisible(true) :
                                                message.info('请先选择绩效部门')
                                            }
                                        />
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={12}>
                                {/*业务单号*/}
                                <Col span={24}>
                                    <FormItem label={'业务单号'} className={'label-character-4'}>
                                        <Input value={order.code} disabled/>
                                    </FormItem>
                                </Col>
                                {/*制单时间*/}
                                <Col span={24}>
                                    <FormItem label={'制单时间'} className={'label-character-4'}>
                                        <Input value={order.createdAt} disabled/>
                                    </FormItem>
                                </Col>
                                {/*制单人*/}
                                <Col span={24}>
                                    <FormItem label={'制单人员'} className={'label-character-4'}>
                                        <Input value={order.creatorName} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={24}>
                                {/*单据备注*/}
                                <FormItem label={'单据备注'} className={'label-character-4'}>
                                    <Input.TextArea onChange={(e) => {
                                        setOrder({...order, spec: e.target.value})
                                    }} value={order.spec} placeholder="请输入单据备注"
                                                    style={{resize: 'none', height: '70px'}}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Table
                    rowKey={(record) => record.productId}
                    expandedRowKeys={expandedRowKeys}
                    expandable={{expandedRowRender}}
                    pagination={false} columns={columns} scroll={{x: '100%'}}
                    dataSource={[...orderItems, {id: '', productId: '', isEmptyRecord: true}]}
                    onExpand={(expanded, record) => {
                        if (expanded) {
                            expandedRowKeys = [...expandedRowKeys, record.productId]
                            setExpandedRowKeys([...expandedRowKeys])
                        } else {
                            expandedRowKeys = expandedRowKeys.filter(key => key !== record.productId)
                            setExpandedRowKeys([...expandedRowKeys])
                        }
                    }}
                />
                {/*统计信息*/}
                <div style={{marginTop: "5px"}}>
                    <span>实际售价：{statistics.price}</span>
                    <Divider type="vertical"/>
                    <span>总成本：{statistics.allCost}</span>
                    <Divider type="vertical"/>
                    <span>总成交金额：{statistics.total}</span>
                </div>
            </Form>

            <Modal
                maskClosable={false}
                title={'选择客户'}
                visible={lookupCustomerVisible}
                width={1000} footer={null}
                onCancel={() => setLookupCustomerVisible(false)}>
                <LookupCustomer isMultiple={false} onOk={value => {
                    setOrder({
                        ...order,
                        customerId: value.id,
                        customerName: value.name,
                        customerPhone: value.phone,
                    })
                    form.setFieldsValue({customerName: value.name,})
                    setLookupCustomerVisible(false)
                }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title={'选择员工'}
                visible={lookupEmployeeVisible}
                width={1000} footer={null}
                onCancel={() => setLookupEmployeeVisible(false)}>
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={common.getUser().company.id}
                    // departmentId={order.performanceDepartmentId}
                    isMultiple={false}
                    onOk={value => {
                        // 设置绩效人
                        order.performanceUserId = value.id
                        order.performanceUserName = value.name
                        setOrder({...order})
                        form.setFieldsValue({performanceUserId: value.id, performanceUserName: value.name})
                        setLookupEmployeeVisible(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title={'选择车辆'}
                visible={lookupVehicleVisible}
                width={1000} footer={null}
                onCancel={() => setLookupVehicleVisible(false)}>
                <LookupVehicle
                    canChangeCompany={true}
                    isMultiple={false} customerId={order.customerId}
                    onOk={value => {
                        common.consoleLog("vehicle", value)
                        setOrder({ // 设置订单信息
                            ...order,
                            customerId: value.customer.id,
                            customerName: value.customer.name,
                            customerPhone: value.customer.phone,
                            plate: value.plate,
                            vin: value.vin,
                            productName: value.productName,
                            seriesName: value.seriesName,
                            vehicleName: value.name,
                            vehiclePhone: value.phone,
                            vehicleId: value.id
                        })
                        // 设置表单信息
                        form.setFieldsValue({
                            vin: value.vin,
                            customerName: value.customer.name,
                        })
                        setLookupVehicleVisible(false)
                    }}/>
            </Modal>

            {/*选择产品*/}
            <Modal
                destroyOnClose={true}
                maskClosable={false}
                visible={lookupProductVisible}
                title={'选择增值产品'}
                width={1000}
                footer={null}
                onCancel={() => setLookupProductVisible(false)}>
                <LookupProduct
                    isMultiple={true}
                    tableProduct={orderItems}
                    tableProductIds={orderItems.map(orderItem => orderItem.productId)}
                    onOk={handleProduct}
                    hiddenAction={true}
                />
            </Modal>

            <Modal
                maskClosable={false}
                title={'选择合作单位'}
                visible={lookupPartnerVisible}
                width={1000} footer={null}
                onCancel={() => setLookupPartnerVisible(false)}>
                <LookupPartner
                    type={"保险公司"}
                    dataSource={3} // 都获取
                    isMultiple={false}
                    onOk={value => {
                        orderItems[editingProductIndex].partnerCompanyId = value.id
                        orderItems[editingProductIndex].partnerCompanyName = value.name
                        if (orderItems[editingProductIndex].rebateRateChange) {
                            let key = orderItems[editingProductIndex].partnerCompanyId + orderItems[editingProductIndex].productId
                            common.consoleLog("key", key)
                            common.consoleLog("rebateRateMap[key]", rebateRateMap[key])
                            orderItems[editingProductIndex].rebateRate = rebateRateMap[key] || originProductMap.get(orderItems[editingProductIndex].productId).rebateRate
                        }
                        setOrderItems([...orderItems])
                        setLookupPartnerVisible(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选择财务公司"
                visible={lookupCompanyVisible}
                width={1000} footer={null}
                onCancel={() => setLookupCompanyVisible(false)}>
                <LookupCompany type={'group'} isMultiple={false} onOk={value => {
                    orderItems[editingProductIndex].financeCompanyId = value.id
                    orderItems[editingProductIndex].financeCompanyName = value.name
                    setOrderItems([...orderItems])
                    setLookupCompanyVisible(false)
                }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选择销售订单"
                visible={lookupOrderVisible}
                width={1000} footer={null}
                onCancel={() => setLookupOrderVisible(false)}>
                <LookupOrder
                    buttons={['checkout']}
                    statuses={[STATUS_PAID, STATUS_RECEIVABLE]}
                    isMultiple={false}
                    onOk={sale => {
                        common.consoleLog("sale", sale)
                        // 通过vin获取车辆信息
                        new Promise(resolve => {
                            let params = {
                                vin: sale.vehicle.vin,
                                ownerId: common.getUser().company.id,
                            }
                            common.loadingStart()
                            common.ajax('get', '/support/vehicle/findByVin', params)
                                .then((data) => {
                                    resolve(data)
                                })
                                .finally(() => {
                                    common.loadingStop();
                                })
                        }).then(vehicle => {
                            order = {
                                ...order,
                                saleOrderCode: sale.code,
                                saleOrderId: sale.id,
                                customerId: sale.customerId,
                                customerName: sale.customerName,
                                customerPhone: sale.customerPhone, // todo前端sale订单传一个customerPhone
                                saleUserId: sale.saleId,
                                saleUserName: sale.saleName,
                                saleDepartmentId: sale.departmentId,
                                vehicleId: vehicle.id,
                                seriesName: vehicle.seriesName,
                                productName: vehicle.productName,
                                vehicleName: vehicle.name,
                                vehiclePhone: vehicle.phone,
                                plate: vehicle.plate,
                                vin: vehicle.vin,
                                spec: sale.partsRemark, // 业务备注
                            }
                            // 设置表单数据
                            setOrder(order)
                            form.setFieldsValue({...order})
                        })
                        setLookupOrderVisible(false)
                    }}/>
            </Modal>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={order.customerId}/>
            </Modal>

            {/* 客户档案弹框 */}
            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={customerFormVisible}
                onCancel={() => {
                    setCustomerFormVisible(false)
                }}
            >
                <CustomerForm id={order.customerId} onSave={(value) => {
                    setOrder({
                        ...order,
                        customerName: value.name,
                        customerPhone: value.phone,
                    })
                    form.setFieldsValue({
                        customerName: value.name,
                        customerPhone: value.phone,
                    })
                    setCustomerFormVisible(false)
                }}/>
            </Modal>

            {/*车辆档案弹框*/}
            <Modal
                footer={null}
                width={1000}
                maskClosable={false}
                visible={vehiclePremiumFormVisible}
                onCancel={() => {
                    setVehiclePremiumFormVisible(false)
                }}>
                <br/>
                <VehiclePremiumForm
                    id={order.vehicleId}
                    customerId={order.customerId}
                    onSave={(value) => {
                        // form.setFieldsValue({
                        //     vin: value.vin,//车架号
                        //     plate: value.plate,//车牌号码
                        //     vehicleName: value.name,
                        //     vehiclePhone: value.phone,
                        //     seriesName: value.seriesName,
                        // })
                        setOrder({
                            ...order,
                            seriesName: value.seriesName,
                            productName: value.productName,
                            vehicleName: value.name,
                            vehiclePhone: value.phone,
                            plate: value.plate,
                            vin: value.vin,
                        })
                        form.setFieldsValue({
                            vin: value.vin,
                        })
                        setVehiclePremiumFormVisible(false)
                    }}/>
            </Modal>

            {/*业务类别属性*/}
            <Modal title={'选择业务类别属性'}
                   destroyOnClose={true}
                   maskClosable={false}
                   visible={lookupBusinessAttrVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setLookupBusinessAttrVisible(false)
                   }}>
                <LookupBusinessAttr
                    isMultiple={true}
                    businessTypeId={selectOrderItem.businessTypeId}
                    onOk={(values) => {
                        // 设置业务属性id
                        values.forEach(item => {
                            item.businessAttrId = item.id
                            item.orderItemId = selectOrderItem.id
                            item.orderId = selectOrderItem.orderId
                        })
                        orderItems.forEach(item => {
                            if (item.id === selectOrderItem.id) {
                                item.attrs = item.attrs || []
                                item.attrs = [...item.attrs, ...values]
                            }
                        })
                        setOrderItems([...orderItems])
                        // 设置展开的行
                        setExpandedRowKeys([selectOrderItem.id])
                        // 关闭选择框
                        setLookupBusinessAttrVisible(false)
                    }}/>
            </Modal>

        </div>
    )
}

export default Edit
