import common from "../../utils/common";

/**
 * 定制化配置
 */
class CustomizationConstant {
    // 加装订单 特殊处理的集团ID
    static ACCESSORY_ORDER_TENANT_PRODUCT_ALLOW_PRODUCT_CATEGORY = ["1037"];

}

export {
    CustomizationConstant,
}