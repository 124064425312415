import React, {useEffect, useRef, useState} from 'react';
import {
    CloseOutlined,
    EditOutlined,
    ClockCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    DatePicker,
    Divider,
    Popconfirm,
    Space,
    Radio,
    Timeline
} from "antd";
import {connect} from "react-redux";
import ProductGoods from "../../../../components/wms/LookupProductGoods"
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Basic, Product, wmsPublic, Inquiry, Audit} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupEmployee from "../../../../components/passport/LookupEmployee";

const {Option} = Select
const {TextArea} = Input

function ReceiptIndex(props) {
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [allQuantity, setAllQuantity] = useState(0) // 数量(总计)
    let [action, setAction] = useState("part")

    let [list, setList] = useState([]) // 表格数据
    let [data, setData] = useState({
        type: Product.KIND_PART,
        spec: "",
        exteriorColor: "",
        plate: "",
        vin: "",
        receiptDate: "",
        auditDtoList: [],
        meAudit: false,
        status: Inquiry.STATUS_TO_BE_REVIEWED
    })// 表单默认值
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')

    // 获取当前时间
    let getDate = () => {
        if (props.match.params.id === undefined) {
            // 制单人
            form.setFieldsValue({creatorName: common.getUser().nickname})

            common.loadingStart()
            common.ajax('get', '/wms/receipt/now')
                .then(res => {
                    // 制单人
                    form.setFieldsValue({receiptDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
                })
                .finally(common.loadingStop)
        }
    }

    useEffect(getDate, [])

    useEffect(() => {
        if (props.match.params.id !== undefined) {
            common.loadingStart()
            common.ajax('get', '/wms/inquiry/auditDetail', {id: props.match.params.id})
                .then(res => {
                    setData({
                        ...data,
                        ...res
                    })
                    // 是否是本人审核 审核通过可以审核拒绝 审核不通过也可以审核通过
                    let auditStatus = Audit.STATUS_YES
                    let auditSpec = ''
                    if (res.meAudit) {
                        if (res.auditDto !== null && res.auditDto.status !== Audit.STATUS_DEFAULT) {
                            auditStatus = res.auditDto.status
                            auditSpec = res.auditDto.spec
                        }
                    }
                    form.setFieldsValue({
                        ...data,
                        ...res,
                        auditStatus: auditStatus,
                        auditSpec: auditSpec,
                        receiptDate: moment(moment(res.createdAt).format("YYYY-MM-DD"), "YYYY/MM/DD")
                    })
                    let arr = []
                    let quantityAll = '0.00'
                    res.products.forEach((item) => {
                        quantityAll = new BigNumber(item.quantity).plus(new BigNumber(quantityAll)).toString();
                        arr.push({
                            ...item,
                            priceOne: item.deliveryPrice,
                            price: item.costPrice,
                            name: item.productName,
                            receiptQuantity: item.quantity,
                            id: item.productId,
                        })
                    })
                    // 总数量
                    setAllQuantity(quantityAll)
                    // 配件
                    setList(arr)
                })
                .finally(common.loadingStop)
        }
    }, [props.match.params.id])

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/product/list", {
            isHaltProduction: Basic.IS_ANSWER_NO,
            kind: Product.KIND_GOODS,
            ownerId: common.getUser().company.id,
            brandId: common.getUser().brand.id,
            number: params,
            isInput: true,
        }).then((data) => {
            if (data.products.length === 1) {
                let arr = list.filter(item => item.id === data.products[0].id)
                // 添加一行
                if (arr.length > 0) {
                    productGoodsOnOk(list)
                } else {
                    productGoodsOnOk([...list, ...data.products])
                }

                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            } else {
                selectModal(3)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 表单提交
    let onFinish = (values) => {
        common.loadingStart()
        common.ajax('post', '/wms/audit/updateById', {
            id: data.auditDto.id,
            status: values.auditStatus,
            spec: values.auditSpec,
        }).then(() => {
            common.toast("操作成功")
            props.history.push("/wms/group/buy/inquiry/auditPage")
        }).finally(() => {
            common.loadingStop()
        })
    };

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={action === Product.KIND_ARTICLE ? "输入精品编号 回车" : "输入配件编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    selectModal(3)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter

                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : "精品名称",
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 150,
            ellipsis: true,
            // align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return (
                    <Input
                        defaultValue={text}
                        onPressEnter={wmsPublic.onPressEnter}
                        onChange={(e) => {
                            list[index].applicableModels = e.target.value
                        }} />)
            }
        },
        {
            title: '零售价/厂家',
            dataIndex: "priceOne",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined) {
                    return ''
                }
                return text
            }
        },
        {
            title: '成本价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
        },
        {
            title: '需求数量',
            dataIndex: 'receiptQuantity',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].receiptQuantity = val
                            let arr = []
                            let quantityAll = 0

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    // 数量
                                    if (val !== '' && val !== null) {
                                        quantityAll = new BigNumber(val).plus(new BigNumber(quantityAll)).toString();
                                    }
                                    arr.push({
                                        ...item,
                                        receiptQuantity: val,
                                    })
                                } else {
                                    // 数量
                                    if (item.receiptQuantity !== '' && item.receiptQuantity !== null && item.receiptQuantity !== undefined) {
                                        quantityAll = new BigNumber(item.receiptQuantity).plus(new BigNumber(quantityAll)).toString();
                                    }
                                    arr.push(item)
                                }
                            })
                            setAllQuantity(quantityAll)
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        defaultValue={text}
                        onPressEnter={wmsPublic.onPressEnter}
                        onChange={(e) => {
                            list[index].spec = e.target.value
                        }} />)
            }
        },
        {
            title: '操作',
            align: "center",
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                // let price = 0
                                // let priceTax = 0
                                // let priceGo = 0
                                let quantity = 0

                                let arr = list.filter((item) => {
                                    if (item.id !== record.id) {
                                        // 总 数量
                                        if (item.receiptQuantity !== '' && item.receiptQuantity !== null) {
                                            quantity = new BigNumber(quantity).plus(item.receiptQuantity).toString()
                                        }
                                    }
                                    return item.id !== record.id
                                })
                                setAllQuantity(quantity)
                                setList(arr)
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>
                    </div>)
            }
        }
    ]

    // 配件(精品选择)
    let productGoodsOnOk = (val) => {
        let arr = []
        let quantity = 0

        val.forEach((item1) => {
            let number = 0
            list.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)

                    quantity = new BigNumber(item2.receiptQuantity).plus(quantity).toString()
                }
            })
            if (number === 0) {
                let obj = {
                    price: null,
                    spec: '',
                    applicableModels: '',
                    receiptQuantity: 1,
                }
                if (item1.receiptItemDto !== null) {
                    obj = {
                        price: item1.receiptItemDto.price,
                        receiptItemSpec: '',
                        applicableModels: '',
                        receiptQuantity: 1,
                    }
                }
                arr.push({
                    ...item1,
                    ...obj
                })

                quantity = new BigNumber(obj.receiptQuantity).plus(quantity).toString()
            }
        })

        setAllQuantity(quantity)
        setList(arr)
        setNewValue("")
        setVisible(false)
    }

    // 选择模态框
    let selectModal = (num) => {
        setModalNum(num)
        if (num === 10) {
            setModalTitle("选择客户")
        }
        if (num === 11) {
            setModalTitle("选择申请人")
        }
        if (num === 3) {
            if (form.getFieldValue("type") === undefined || form.getFieldValue("type") === null || form.getFieldValue("type") === "") {
                common.toast("请选择类型")
                return false;
            }
            setModalTitle("选择配件")
        }
        setVisible(true)
    }

    const [form] = Form.useForm()

    return <>
        <PageTop title={'询价单审核'}>
            {data.meAudit &&
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    // disabled={!data.meAudit}
                    onClick={() => {
                        common.confirm("您确认要提交审核吗？", () => {
                            form.submit()
                        })
                    }}>审核确认</Button>}
            <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                    Search.back()
                }}>返回</Button>
        </PageTop>
        <Form
            initialValues={data}
            form={form}
            onFinish={onFinish}
            name={'validate_other'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                {data.meAudit &&
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'auditStatus'}
                            label="审核意见">
                            <Radio.Group>
                                <Radio value={Audit.STATUS_YES}>{Audit.StatusAlias[Audit.STATUS_YES]}</Radio>
                                <Radio value={Audit.STATUS_NO}>{Audit.StatusAlias[Audit.STATUS_NO]}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>}
                {data.meAudit &&
                    <Col span={18}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'auditSpec'}
                            label="审核备注">
                            <Input />
                        </Form.Item>
                    </Col>}
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择客户'}]}
                        className={'label-character-4'}
                        label={"客户名称"}
                        name="customerName">
                        <Input disabled={true} readOnly={true} autoComplete="off"
                            addonAfter={
                                <SelectOutlined onClick={() => {
                                    selectModal(10)
                                }} />
                            }
                            suffix={
                                (data.customerName !== undefined && data.customerName !== "") ?
                                    <Space>
                                        <CloseOutlined onClick={() => {
                                            form.setFieldsValue({
                                                customerId: "",
                                                customerName: "",
                                                vehicleId: "",
                                            })
                                            setData({
                                                ...data,
                                                customerId: "",
                                                customerName: "",
                                                vehicleId: "",
                                            })
                                        }} />
                                    </Space> : <span />
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'plate'}
                        label="车牌号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'vin'}
                        label="VIN">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        rules={[{required: true, message: '请选择申请人'}]}
                        name={'proposerName'}
                        label="申请人">
                        <Input
                            disabled={true}
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={(data.proposerName !== undefined && data.proposerName !== "") ?
                                <CloseOutlined onClick={() => {
                                    setData({
                                        ...data,
                                        proposerName: "",
                                        proposer: "",
                                    })
                                    form.setFieldsValue({proposerName: "", proposer: ""})
                                }} /> : <span />}
                            onKeyPress={() => {
                                selectModal(11)
                            }}
                            addonAfter={<SelectOutlined
                                onClick={() => {
                                    selectModal(11)
                                }} />}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'exteriorColor'}
                        label="车身颜色">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择是否告知客户'}]}
                        className={'label-character-4'}
                        name={'notifyCustomer'}
                        label="告知客户">
                        <Select disabled={true}>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_YES}
                                key={Inquiry.NOTIFY_CUSTOMER_YES}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_YES]}</Option>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_NO}
                                key={Inquiry.NOTIFY_CUSTOMER_NO}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择类型'}]}
                        className={'label-character-3'}
                        name={'type'}
                        label="类型">
                        <Select
                            disabled={true}
                            onChange={(val) => {
                                setAction(val)
                                setList([])
                            }}>
                            <Option value={Product.KIND_PART}
                                key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                            <Option value={Product.KIND_ARTICLE}
                                key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={"ownerName"}
                        className={'label-character-3'}
                        label="所属公司">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={"code"}
                        className={'label-character-4'}
                        label="询价单号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'spec'}
                        label="备注">
                        <TextArea autoSize={{minRows: 1, maxRows: 2}} />
                    </Form.Item>
                </Col>
            </Row>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={[...list, {
                    isEmptyRecord: true,
                    id: -1
                }]}
                scroll={{x: '100%', y: "400px"}} />
            <div style={{width: '100%', height: "50px", marginTop: "5px"}}>
                <div style={{float: 'left'}}>
                    SKU: {list.length}
                    <Divider type="vertical" />
                    数量: {common.numberFormat(allQuantity)}
                </div>
            </div>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        name={'receiptDate'}
                        // className={'label-character-4'}
                        label="制单日期">
                        <DatePicker disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'creatorName'}
                        label="制单人">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {data.auditDtoList.length > 0 &&
                    data.auditDtoList.map(item => {
                        return (
                            <>
                                <Col span={6}>
                                    <Form.Item
                                        label="审核时间">
                                        <Input disabled={true} value={item.time} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"审核人"}>
                                        <Input disabled={true}
                                            value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="审核备注">
                                        <Input disabled={true} value={item.spec} />
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    })
                }
            </Row>
        </Form>
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            {/*申请人*/}
            {modalNum === 11 &&
                <LookupEmployee
                    onOk={value => {
                        form.setFieldsValue({
                            proposerName: value.name || "",
                        })
                        setData({
                            ...data,
                            proposerName: value.name || "",
                            proposer: value.id,
                        })
                        setVisible(false)
                    }}
                />}
            {/*选择客户弹框*/}
            {modalNum === 10 &&
                <LookupAll
                    isCustomer={true}
                    isVehicle={true}
                    isMultiple={false}
                    onOk={value => {
                        form.setFieldsValue({
                            customerName: value.name || "",// 客户名称
                            vin: value.vin || "",// 车架号
                            plate: value.plate || "",// 车牌号
                            vehicleId: value.vehicleId || "",// 车辆ID
                        })
                        setData({
                            ...data,
                            customerName: value.name || "",// 客户名称
                            customerId: value.id,// 设置客户id
                            plate: value.plate || "",// 车牌号
                            vin: value.vin || "",// 车架号
                            vehicleId: value.vehicleId || "",// 车辆ID
                        })
                        setVisible(false)
                    }} />}
            {modalNum === 3 &&
                <ProductGoods
                    brandId={common.getUser().brand.id}
                    defaultList={list}// 编辑的时候默认值未选中的问题
                    showButton={true}
                    action={action === Product.KIND_ARTICLE ? '' : 'repair'}
                    isMultiple={true}
                    skuKind={action}
                    defaultValue={Product.KIND_GOODS}
                    onOk={productGoodsOnOk} />}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)