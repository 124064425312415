import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Radio, Select, Table, Divider} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {CloseOutlined, SelectOutlined} from "@ant-design/icons";
import Partner from "../../../components/passport/LookupPartner";
import {SupportSetting} from "../../../components/support/config";

const FormItem = Form.Item

// 0.否  1.是
const NO = "0";
const YES = "1";

function SettingIndex() {
    // 维修工单 业务需求
    const columns = [
        {
            title: '工段名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '工时单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.price}
                        precision={2}
                        min={0}
                        onBlur={e => {
                            setSectionTerms(sectionTerms.map((item, index) => {
                                if (record.id === item.id) {
                                    item.price = e.target.value
                                    changeSupportVehicleClaimantManufacturer({
                                        ownerId: common.getUser().company.id,
                                        taxonomy: item.id,
                                        name: "维修工单，工单单价",
                                        flag: e.target.value ? e.target.value : "", // 默认为无
                                        spec: "", // 默认为无
                                        status: SupportSetting.STATUS_YES
                                    })
                                }
                                return item;
                            }))
                        }}
                    />
                )
            }
        }
    ]

    // 维修报表 报表需求
    const columnsReport = [
        {
            title: '工段名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '提成比例(%)',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.price}
                        precision={0}
                        min={0}
                        onBlur={e => {
                            setSectionTermsReport(sectionTermsReport.map((item, index) => {
                                if (record.id === item.id) {
                                    item.price = e.target.value
                                    changeSupportVehicleClaimantManufacturer({
                                        ownerId: common.getUser().company.id,
                                        taxonomy: item.id,
                                        name: "维修工单报表，工时提成比例",
                                        flag: item.price, // 默认为无
                                        spec: "", // 默认为无
                                        status: SupportSetting.STATUS_NO
                                    })
                                }
                                return item;
                            }))
                        }}
                    />
                )
            }
        }
    ]

    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('') // 模态框名称
    let [modalNumber, setModalNumber] = useState(0) // 模态框选择

    // 预结算时采用（库房出库价）做为客户自费的销售单价
    const initialDeliveryPriceIsSalePriceByCustomerTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "deliveryPriceIsSalePriceByCustomer",
        name: "预结算时采用（库房出库价）做为客户自费的销售单价",
        flag: NO, // 默认必填
    }

    // 预结算时采用（库房出库价）做为厂家索赔的销售单价
    const initialDeliveryPriceIsSalePriceByFactoryTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "deliveryPriceIsSalePriceByFactory",
        name: "预结算时采用（库房出库价）做为厂家索赔的销售单价",
        flag: NO, // 默认必填
    }

    // 预结算时采用（库房出库价）做为保险维修的销售单价    
    const initialDeliveryPriceIsSalePriceByInsuranceTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "deliveryPriceIsSalePriceByInsurance",
        name: "预结算时采用（库房出库价）做为保险维修的销售单价",
        flag: NO, // 默认必填
    }

    // 新增车辆档案 索赔厂商
    const initialInputForClaimantManufacturer = {
        ownerId: common.getUser().company.id,
        taxonomy: "supportVehicleClaimantManufacturer",
        name: "新增车辆档案时，默认索赔厂商",
        flag: "", // 默认为无
        spec: "", // 默认为无
    }

    // 新增工单时，不允许手工录入工时项目
    const initialInputForRepairInputProject = {
        ownerId: common.getUser().company.id,
        taxonomy: "repairInputProject",
        name: "新增工单时，不允许手工录入工时项目",
        flag: NO, // 默认为否
        spec: "", // 默认为无
    }

    // 新增工单时，不允许修改工时项目的工时费
    const initialInputForRepairUpdateProjectPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: "repairUpdateProjectPrice",
        name: "新增工单时，不允许修改工时项目的工时费",
        flag: NO, // 默认为否
        spec: "", // 默认为无
    }

    const initialRepairAutomaticReminder = {
        ownerId: common.getUser().company.id,
        taxonomy: SupportSetting.TAXONOMY_REPAIR_AUTOMATIC_REMINDER,
        name: "维修工单自动弹出维修提醒",
        flag: NO, // 默认否
    }

    // 新增工单时，不允许修改工时项目的派工工时
    const initialInputForRepairUpdateProjectDispatchTime = {
        ownerId: common.getUser().company.id,
        taxonomy: "repairUpdateProjectDispatchTime",
        name: "新增工单时，不允许修改工时项目的派工工时",
        flag: NO, // 默认为否
        spec: "", // 默认为无
    }

    // 新增工单时，不允许修改预估材料
    const initialInputForRepairUpdateProduct = {
        ownerId: common.getUser().company.id,
        taxonomy: "repairUpdateProduct",
        name: "新增工单时，不允许修改预估材料",
        flag: NO, // 默认为否
        spec: "", // 默认为无
    }

    // 新增加装时，明细显示可选分类
    const initialInputForAccessoryItemSelect = {
        ownerId: common.getUser().company.id,
        taxonomy: "accessoryItemSelect",
        name: "新增加装时，明细显示可选分类",
        flag: YES, // 默认为是
        spec: "",
        itemList: [],
    }

    // 加装订单，是否需要派工
    const initialInputForAccessoryDispatch = {
        ownerId: common.getUser().company.id,
        taxonomy: "accessoryDispatch",
        name: "加装订单，是否需要派工",
        flag: YES, // 默认为是
        spec: "",
    }

    // 维修工单 默认工单类型
    const initialInputForRepairCategoryId = {
        ownerId: common.getUser().company.id,
        taxonomy: SupportSetting.TAXONOMY_REPAIR_CATEGORY_ID,
        name: "维修工单，默认工单类型",
        flag: YES, // 默认为是
        spec: "",
    }

    let [repairCategoryId, setRepairCategoryId] = useState(initialInputForRepairCategoryId)
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [repairAutomaticReminder, setRepairAutomaticReminder] = useState(initialRepairAutomaticReminder)
    let [accessoryDispatch, setAccessoryDispatch] = useState(initialInputForAccessoryDispatch)
    let [accessoryItemSelect, setAccessoryItemSelect] = useState(initialInputForAccessoryItemSelect)
    let [repairUpdateProduct, setRepairUpdateProduct] = useState(initialInputForRepairUpdateProduct)
    let [repairUpdateProjectDispatchTime, setRepairUpdateProjectDispatchTime] = useState(initialInputForRepairUpdateProjectDispatchTime)
    let [repairInputProject, setRepairInputProject] = useState(initialInputForRepairInputProject)
    let [repairUpdateProjectPrice, setRepairUpdateProjectPrice] = useState(initialInputForRepairUpdateProjectPrice)
    let [supportVehicleClaimantManufacturer, setSupportVehicleClaimantManufacturer] = useState(initialInputForClaimantManufacturer)
    let [deliveryPriceIsSalePriceByCustomerTaxonomy, setDeliveryPriceIsSalePriceByCustomerTaxonomy] = useState(initialDeliveryPriceIsSalePriceByCustomerTaxonomy)  // 预结算时采用（库房出库价）做为客户自费的销售单价
    let [deliveryPriceIsSalePriceByFactoryTaxonomy, setDeliveryPriceIsSalePriceByFactoryTaxonomy] = useState(initialDeliveryPriceIsSalePriceByFactoryTaxonomy)  // 预结算时采用（库房出库价）做为厂家索赔的销售单价
    let [deliveryPriceIsSalePriceByInsuranceTaxonomy, setDeliveryPriceIsSalePriceByInsuranceTaxonomy] = useState(initialDeliveryPriceIsSalePriceByInsuranceTaxonomy)  // 预结算时采用（库房出库价）做为保险维修的销售单价
    let [sectionTerms, setSectionTerms] = useState([]);// 维修工段列表
    let [sectionTermsReport, setSectionTermsReport] = useState([]);// 维修工段列表 报表专用

    // 获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory', status: 1})
            .then((res) => {
                setRepairCategories(res || [])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(getRepairCategories, [])

    function getSetting(params) {
        common.loadingStart()
        common.ajax('get', '/support/setting', {
            taxonomy: params.taxonomy,
            ownerId: params.ownerId,
        }).then((data) => {
            if (data !== null) {
                if (params.taxonomy === repairUpdateProjectDispatchTime.taxonomy) {
                    setRepairUpdateProjectDispatchTime({
                        ...repairUpdateProjectDispatchTime,
                        flag: data.flag
                    })
                } else if (params.taxonomy === accessoryDispatch.taxonomy) {
                    setAccessoryDispatch({
                        ...accessoryDispatch,
                        flag: data.flag,
                        spec: data.spec,
                    })
                } else if (params.taxonomy === repairAutomaticReminder.taxonomy) {
                    setRepairAutomaticReminder({
                        ...repairAutomaticReminder,
                        flag: data.flag,
                    })
                } else if (params.taxonomy === accessoryItemSelect.taxonomy) {
                    setAccessoryItemSelect({
                        ...accessoryItemSelect,
                        flag: data.flag,
                        spec: data.spec,
                        itemList: (data.spec != null && data.spec !== "") ? data.spec.split(",") : [],
                    })
                } else if (params.taxonomy === repairInputProject.taxonomy) {
                    setRepairInputProject({
                        ...repairInputProject,
                        flag: data.flag
                    })
                } else if (params.taxonomy === repairUpdateProjectPrice.taxonomy) {
                    setRepairUpdateProjectPrice({
                        ...repairUpdateProjectPrice,
                        flag: data.flag
                    })
                } else if (params.taxonomy === repairUpdateProduct.taxonomy) {
                    setRepairUpdateProduct({
                        ...repairUpdateProduct,
                        flag: data.flag
                    })
                } else if (params.taxonomy === repairCategoryId.taxonomy) {
                    setRepairCategoryId({
                        ...repairCategoryId,
                        spec: data.spec
                    })
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增车辆档案 索赔厂商
    let changeSupportVehicleClaimantManufacturer = (params) => {
        common.loadingStart()
        common.ajax('post', '/support/setting', params)
            .then(res => {
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取指定分类列表
    function getSectionTerms() {
        let params = {
            taxonomy: 'section',
            status: 1,
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            setSectionTermsReport(data)
            setSectionTerms(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取配置
    useEffect(() => {
        // 维修工单 业务设置 单价
        getSettingList(sectionTerms, SupportSetting.STATUS_YES)
    }, [sectionTerms])

    useEffect(() => {
        // 维修报表 比例设置
        getSettingList(sectionTermsReport, SupportSetting.STATUS_NO)
    }, [sectionTermsReport])

    let getSettingList = (taxonomyList, status) => {
        if (status === SupportSetting.STATUS_YES) {
            if (sectionTerms.length === 0) {
                return
            }
            if (sectionTerms[0].price !== undefined) {
                return;
            }
        }
        if (status === SupportSetting.STATUS_NO) {
            if (sectionTermsReport.length === 0) {
                return
            }
            if (sectionTermsReport[0].price !== undefined) {
                return;
            }
        }
        common.loadingStart()
        common.ajax('get', '/support/setting/getList', {
            ownerId: common.getUser().company.id,
            taxonomyList: taxonomyList.map(item => item.id),
            status: status
        })
            .then(res => {
                // 维修工单 业务
                if (status === SupportSetting.STATUS_YES) {
                    setSectionTerms(sectionTerms.map(item => {
                        let price = ""
                        for (let taxonomyListElement of res) {
                            if (taxonomyListElement.taxonomy === item.id) {
                                price = taxonomyListElement.flag
                                item.status = SupportSetting.STATUS_YES
                            }
                        }
                        return {
                            ...item,
                            price: price
                        }
                    }))
                }
                if (status === SupportSetting.STATUS_NO) {
                    // 报表专用
                    setSectionTermsReport(sectionTermsReport.map(item => {
                        let price = ""
                        for (let taxonomyListElement of res) {
                            if (taxonomyListElement.taxonomy === item.id) {
                                price = taxonomyListElement.flag
                                item.status = SupportSetting.STATUS_NO
                            }
                        }
                        return {
                            ...item,
                            price: price
                        }
                    }))
                }
            })
            .finally(common.loadingStop)
    }

    // 新增车辆档案 索赔厂商
    let getSupportVehicleClaimantManufacturer = () => {
        common.loadingStart()
        common.ajax('get', '/support/setting', supportVehicleClaimantManufacturer)
            .then(res => {
                setSupportVehicleClaimantManufacturer(res || initialInputForClaimantManufacturer)
            })
            .finally(common.loadingStop)
    }

    // 预结算时采用（库房出库价）做为客户自费的销售单价
    let changeDeliveryPriceIsSalePriceByCustomerTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', deliveryPriceIsSalePriceByCustomerTaxonomy)
            .then(res => {
                deliveryPriceIsSalePriceByCustomerTaxonomy = res || initialDeliveryPriceIsSalePriceByCustomerTaxonomy
                setDeliveryPriceIsSalePriceByCustomerTaxonomy(deliveryPriceIsSalePriceByCustomerTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 预结算时采用（库房出库价）做为厂家索赔的销售单价
    let changeDeliveryPriceIsSalePriceByFactoryTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', deliveryPriceIsSalePriceByFactoryTaxonomy)
            .then(res => {
                deliveryPriceIsSalePriceByFactoryTaxonomy = res || initialDeliveryPriceIsSalePriceByFactoryTaxonomy
                setDeliveryPriceIsSalePriceByFactoryTaxonomy(deliveryPriceIsSalePriceByFactoryTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 预结算时采用（库房出库价）做为保险维修的销售单价
    let changeDeliveryPriceIsSalePriceByInsuranceTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', deliveryPriceIsSalePriceByInsuranceTaxonomy)
            .then(res => {
                deliveryPriceIsSalePriceByInsuranceTaxonomy = res || initialDeliveryPriceIsSalePriceByInsuranceTaxonomy
                setDeliveryPriceIsSalePriceByInsuranceTaxonomy(deliveryPriceIsSalePriceByInsuranceTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 预结算时采用（库房出库价）做为客户自费的销售单价
    let getDeliveryPriceIsSalePriceByCustomerTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByCustomer",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                setDeliveryPriceIsSalePriceByCustomerTaxonomy(res || initialDeliveryPriceIsSalePriceByCustomerTaxonomy)
            })
            .finally(common.loadingStop)
    }

    let supportUpdateSetting = (params) => {
        common.loadingStart()
        common.ajax('post', '/support/setting', params)
            .then(res => {
                common.toast("操作成功")
            })
            .finally(common.loadingStop)
    }

    // 预结算时采用（库房出库价）做为厂家索赔的销售单价
    let getDeliveryPriceIsSalePriceByFactoryTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByFactory",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                setDeliveryPriceIsSalePriceByFactoryTaxonomy(res || initialDeliveryPriceIsSalePriceByFactoryTaxonomy)
            })
            .finally(common.loadingStop)
    }


    // 预结算时采用（库房出库价）做为保险维修的销售单价
    let getDeliveryPriceIsSalePriceByInsuranceTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByInsurance",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                setDeliveryPriceIsSalePriceByInsuranceTaxonomy(res || initialDeliveryPriceIsSalePriceByInsuranceTaxonomy)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDeliveryPriceIsSalePriceByCustomerTaxonomy()
        getDeliveryPriceIsSalePriceByFactoryTaxonomy()
        getDeliveryPriceIsSalePriceByInsuranceTaxonomy()
        getSupportVehicleClaimantManufacturer()
        getSectionTerms()
        getSetting(initialInputForRepairUpdateProjectDispatchTime)
        getSetting(initialInputForRepairInputProject)
        getSetting(initialInputForAccessoryItemSelect)
        getSetting(initialInputForRepairUpdateProjectPrice)
        getSetting(initialInputForAccessoryDispatch)
        getSetting(initialInputForRepairUpdateProduct)
        getSetting(initialRepairAutomaticReminder)
        getSetting(initialInputForRepairCategoryId)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"维修业务参数设置"}/>
            <Form>
                {/*预结算时采用（库房出库价）做为客户自费的销售单价*/}
                <FormItem label={deliveryPriceIsSalePriceByCustomerTaxonomy.name}>
                    <Radio.Group value={deliveryPriceIsSalePriceByCustomerTaxonomy.flag} onChange={e => {
                        deliveryPriceIsSalePriceByCustomerTaxonomy = {
                            ...deliveryPriceIsSalePriceByCustomerTaxonomy,
                            flag: e.target.value
                        }
                        setDeliveryPriceIsSalePriceByCustomerTaxonomy({...deliveryPriceIsSalePriceByCustomerTaxonomy})
                        changeDeliveryPriceIsSalePriceByCustomerTaxonomy()
                    }}>
                        <Radio key={NO} value={NO}>否</Radio>
                        <Radio key={YES} value={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*预结算时采用（库房出库价）做为客户自费的销售单价*/}
                <FormItem label={repairCategoryId.name}>
                    <Select
                        allowClear={true}
                        style={{width: '200px'}}
                        value={repairCategoryId.spec}
                        onChange={val => {
                            let params = {
                                ...repairCategoryId,
                                spec: val
                            }
                            setRepairCategoryId(params)
                            changeSupportVehicleClaimantManufacturer(params)
                        }}>
                        {repairCategories.map((item) => {
                            return (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            )
                        })}
                    </Select>
                </FormItem>

                {/*预结算时采用（库房出库价）做为厂家索赔的销售单价*/}
                <FormItem label={deliveryPriceIsSalePriceByFactoryTaxonomy.name}>
                    <Radio.Group value={deliveryPriceIsSalePriceByFactoryTaxonomy.flag} onChange={e => {
                        deliveryPriceIsSalePriceByFactoryTaxonomy = {
                            ...deliveryPriceIsSalePriceByFactoryTaxonomy,
                            flag: e.target.value
                        }
                        setDeliveryPriceIsSalePriceByFactoryTaxonomy({...deliveryPriceIsSalePriceByFactoryTaxonomy})
                        changeDeliveryPriceIsSalePriceByFactoryTaxonomy()
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*预结算时采用（库房出库价）做为保险维修的销售单价*/}
                <FormItem label={deliveryPriceIsSalePriceByInsuranceTaxonomy.name}>
                    <Radio.Group value={deliveryPriceIsSalePriceByInsuranceTaxonomy.flag} onChange={e => {
                        deliveryPriceIsSalePriceByInsuranceTaxonomy = {
                            ...deliveryPriceIsSalePriceByInsuranceTaxonomy,
                            flag: e.target.value
                        }
                        setDeliveryPriceIsSalePriceByInsuranceTaxonomy({...deliveryPriceIsSalePriceByInsuranceTaxonomy})
                        changeDeliveryPriceIsSalePriceByInsuranceTaxonomy()
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
                <Form.Item label={initialInputForClaimantManufacturer.name}>
                    <Input
                        style={{width: '300px'}}
                        readOnly={true}
                        value={supportVehicleClaimantManufacturer.spec}
                        autoComplete={"off"}
                        onKeyPress={() => {
                            setModalTitle("选择索赔厂商")
                            setModalNumber(1)
                            setVisible(true)
                        }}
                        suffix={supportVehicleClaimantManufacturer.spec !== "" ?
                            <CloseOutlined type={'close'} onClick={() => {
                                setSupportVehicleClaimantManufacturer({
                                    ...supportVehicleClaimantManufacturer,
                                    flag: "",
                                    spec: ""
                                })
                                changeSupportVehicleClaimantManufacturer({
                                    ...supportVehicleClaimantManufacturer,
                                    flag: "",
                                    spec: ""
                                })
                            }}/> : <span/>}
                        addonAfter={<SelectOutlined onClick={() => {
                            setModalTitle("选择索赔厂商")
                            setModalNumber(1)
                            setVisible(true)
                        }} type={'select'}/>}
                    />
                </Form.Item>

                {/*新增工单时，不允许手工录入工时项目*/}
                <FormItem label={repairInputProject.name}>
                    <Radio.Group value={repairInputProject.flag} onChange={e => {
                        let params = {
                            ...repairInputProject,
                            flag: e.target.value
                        }
                        setRepairInputProject(params)
                        changeSupportVehicleClaimantManufacturer(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*新增工单时，不允许修改工时项目的工时费*/}
                <FormItem label={repairUpdateProjectPrice.name}>
                    <Radio.Group value={repairUpdateProjectPrice.flag} onChange={e => {
                        let params = {
                            ...repairUpdateProjectPrice,
                            flag: e.target.value
                        }
                        setRepairUpdateProjectPrice(params)
                        changeSupportVehicleClaimantManufacturer(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*新增工单时，不允许修改工时项目的派工工时*/}
                <FormItem label={repairUpdateProjectDispatchTime.name}>
                    <Radio.Group value={repairUpdateProjectDispatchTime.flag} onChange={e => {
                        let params = {
                            ...repairUpdateProjectDispatchTime,
                            flag: e.target.value
                        }
                        setRepairUpdateProjectDispatchTime(params)
                        changeSupportVehicleClaimantManufacturer(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*加装订单，是否需要派工*/}
                <FormItem label={accessoryDispatch.name}>
                    <Radio.Group value={accessoryDispatch.flag} onChange={e => {
                        let params = {
                            ...accessoryDispatch,
                            flag: e.target.value
                        }
                        setAccessoryDispatch(params)
                        supportUpdateSetting(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*新增加装时，明细显示可选分类*/}
                <FormItem label={accessoryItemSelect.name}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{width: 200}}
                        value={accessoryItemSelect.itemList}
                        onChange={(val) => {
                            let params = {
                                ...accessoryItemSelect,
                                spec: val.join(","),
                                itemList: val,
                            }
                            supportUpdateSetting(params)
                            setAccessoryItemSelect(params)
                        }}>
                        <Select.Option key={"project"} value={"project"}>工时</Select.Option>
                        <Select.Option key={"product"} value={"product"}>材料</Select.Option>
                        <Select.Option key={"other"} value={"other"}>其他</Select.Option>
                    </Select>
                </FormItem>

                {/*新增工单时，不允许修改预估材料*/}
                <FormItem label={repairUpdateProduct.name}>
                    <Radio.Group value={repairUpdateProduct.flag} onChange={e => {
                        let params = {
                            ...repairUpdateProduct,
                            flag: e.target.value
                        }
                        setRepairUpdateProduct(params)
                        changeSupportVehicleClaimantManufacturer(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*维修工单自动弹出维修提醒*/}
                <FormItem label={repairAutomaticReminder.name}>
                    <Radio.Group value={repairAutomaticReminder.flag} onChange={e => {
                        let params = {
                            ...repairAutomaticReminder,
                            flag: e.target.value
                        }
                        setRepairAutomaticReminder(params)
                        supportUpdateSetting(params)
                    }}>
                        <Radio value={NO} key={NO}>否</Radio>
                        <Radio value={YES} key={YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
            <Divider orientation="left" plain>
                维修工单，工单单价
            </Divider>
            <Table
                rowKey={record => record.id}
                scroll={{x: '300px', y: "350px"}}
                dataSource={sectionTerms}
                columns={columns}
                pagination={false}
            />
            <Divider orientation="left" plain>
                维修工单报表，工时提成比例
            </Divider>
            <Table
                rowKey={record => record.id}
                scroll={{x: '300px', y: "350px"}}
                dataSource={sectionTermsReport}
                columns={columnsReport}
                pagination={false}
            />

            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                {modalNumber === 1 &&
                    <Partner
                        dataSource={3}
                        onOk={val => {
                            setSupportVehicleClaimantManufacturer({
                                ...supportVehicleClaimantManufacturer,
                                flag: val.id,
                                spec: val.name
                            })
                            changeSupportVehicleClaimantManufacturer({
                                ...supportVehicleClaimantManufacturer,
                                flag: val.id,
                                spec: val.name
                            })
                            setVisible(false)
                        }}/>}
            </Modal>
        </React.Fragment>
    )

}

export default SettingIndex