import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs, TreeSelect} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Basic, Brand, Delivery, Product, Term, Warehouse, wmsPublic} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import WarehouseIndex from "./component/warehouse";
import SupplierIndex from "./component/supplier";
import TypeIndex from "./component/type";
import TermForm from "./component/term";
import ReceiptItemIndex from "./component";
import moment from "moment";
import LookupProduct from "../../../../../../components/wms/LookupProductGoods";
import LookupAll from "../../../../../../components/passport/LookupAll";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../../components/export";
import LookupEmployee from "../../../../../../components/passport/LookupEmployee";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index(props) {
    // action 字符串 必填 part(配件) article(精品)
    let {action} = props

    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [number, setNumber] = useState("6")
    let [brand, setBrand] = useState([])
    let [visible2, setVisible2] = useState(false);
    let [treeData1, setTreeData1] = useState([])
    let [terms, setTerms] = useState([])
    let [warehouse, setWarehouse] = useState([]); // 仓库

    let companyId = common.getUser().company.id

    // 搜索条件
    let initialSearch = {
        exampleType: "",
        code: "",
        vin: "",
        supplierId: "",
        exampleSupplier: "",
        warehouseId: "",
        productName: "",
        deliveryEmployeeId: "",
        exampleDeliveryEmployeeName: "",
        exampleName: "",
        ownerId: companyId,
        brandIds: brand.map(item => item.id),
        settlementAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        settlementAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        orderCode: '',
        exampleCustomerName: '',
        customerId: '',
        termIdsOne: [],
        plate: '',
        number: '',
        createdAtStart: "",
        createdAtEnd: "",
        kind: action,
        page: 1,
        limit: 15,
        orderBy: 'deliveryItem.`delivery_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: companyId,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: search.ownerId === "" ? companyId : search.ownerId,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    return (
        <>
            <PageTop title={action === Product.KIND_PART ? '配件出库查询' : '精品出库查询'}>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? '精品出库查询.xlsx' : '配件出库查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={companyId}
                    params={JSON.stringify(query)}
                    type={action === Product.KIND_PART ? 'wms_report_part_delivery' : 'wms_report_boutique_delivery'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"出库单号"}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            settlementAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input value={search.productName} onChange={(e) => {
                                    setSearch({...search, productName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        {action === Product.KIND_ARTICLE &&
                        <Col span={6}>
                            <Form.Item label={"VIN"} className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>}
                        {action === Product.KIND_PART &&
                        <Col span={6}>
                            <Form.Item label={"车牌号"} className={'label-character-3'}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>}
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"业务单号"}>
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={search.exampleSupplier}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSupplier === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, supplierId: '', exampleSupplier: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    disabled={number === '4'}
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="库别名称" className={'label-character-3'}>
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="客户名称">
                                <Input
                                    value={search.exampleCustomerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCustomerName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', exampleCustomerName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNumber(4)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNumber(4)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="领料人">
                                <Input
                                    value={search.exampleDeliveryEmployeeName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleDeliveryEmployeeName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        deliveryEmployeeId: '',
                                                        exampleDeliveryEmployeeName: ''
                                                    })
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择领料人")
                                                setModalNumber(3)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择领料人")
                                        setModalNumber(3)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="出库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        if (Delivery.TYPE_RETURN_SEARCH === val) {
                                            setSearch({
                                                ...search,
                                                types: Object.keys(Delivery.DeliveryTypeReturnAlias),
                                                exampleType: val
                                            })
                                        } else {
                                            // 出库类型 与 退库类型 合并
                                            setSearch({
                                                ...search,
                                                types: val in Delivery.DeliveryTypeGetReturnAlias ? [val, Delivery.DeliveryTypeGetReturnAlias[val]] : [val],
                                                exampleType: val
                                            })
                                        }
                                    }}>
                                    {action === Product.KIND_ARTICLE &&
                                    <Option value={Delivery.DELIVERY_TYPE_SALE}
                                            key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Option>}
                                    <Option value={Delivery.DELIVERY_TYPE_SELL}
                                            key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_ALLOT}
                                            key={Delivery.DELIVERY_TYPE_ALLOT}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ALLOT]}</Option>
                                    {action === Product.KIND_PART &&
                                    <Option value={Delivery.DELIVERY_TYPE_REPAIR}
                                            key={Delivery.DELIVERY_TYPE_REPAIR}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_REPAIR]}</Option>}
                                    <Option value={Delivery.DELIVERY_TYPE_RECEIVE}
                                            key={Delivery.DELIVERY_TYPE_RECEIVE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_RECEIVE]}</Option>
                                    <Option value={Delivery.TYPE_RETURN_SEARCH}
                                            key={Delivery.TYPE_RETURN_SEARCH}>{Delivery.TYPE_RETURN_SEARCH_NAME}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_STOCK}
                                            key={Delivery.DELIVERY_TYPE_STOCK}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery({
                                        ...search,
                                        termIdsOne: wmsPublic.getTermData(search.termIdsOne.length === 0 ? [] : search.termIdsOne, terms),
                                        brandIds: search.brandIds.length === 0 ? brand.map(item => item.id) : search.brandIds,
                                        page: 1
                                    })
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/delivery'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={number}
                onChange={(key) => {
                    if (search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            termIdsOne: wmsPublic.getTermData(search.termIdsOne.length === 0 || key === '4' ? [] : search.termIdsOne, terms),
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery({
                            ...search,
                            termIdsOne: wmsPublic.getTermData(search.termIdsOne.length === 0 || key === '4' ? [] : search.termIdsOne, terms),
                        })
                    }
                    setNumber(key)
                }}>
                {"/report/business/wms/part/delivery/subtotal" === window.location.pathname &&
                <>
                    <TabPane tab={"按类型统计"} key={"1"}>
                        {number === '1' &&
                        <TypeIndex query={query}/>}
                    </TabPane>
                    <TabPane tab={"按库别统计"} key={"2"}>
                        {number === '2' &&
                        <WarehouseIndex query={query}/>}
                    </TabPane>
                    <TabPane tab={"按供应商统计"} key={"5"}>
                        {number === '5' &&
                        <SupplierIndex query={query}/>}
                    </TabPane>
                    <TabPane tab={"按集团管理分类统计"} key={"3"}>
                        {number === '3' &&
                        <TermForm query={query} way={'termOne'} taxonomy={Term.TAXONOMY_ONE}/>}
                    </TabPane>
                    <TabPane tab={"按集团统计分类统计"} key={"4"}>
                        {number === '4' &&
                        <TermForm query={query} way={'termTwo'} taxonomy={Term.TAXONOMY_TWO}/>}
                    </TabPane>
                    <TabPane tab={"配件管理分类统计"} key={"7"}>
                        {number === '7' &&
                        <TermForm query={query} way={'productCategory'} taxonomy={Term.PRODUCT_CATEGORY}/>}
                    </TabPane>
                </>
                }
                <TabPane tab={"出库单明细"} key={"6"}>
                    {number === '6' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={companyId}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({
                            ...search,
                            deliveryEmployeeId: value.id,
                            exampleDeliveryEmployeeName: value.name
                        })

                        setVisible2(false)
                    }}
                />}
                {modalNumber === 2 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, supplierId: value.id, exampleSupplier: value.name})
                        setVisible2(false)
                    }}
                />}
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 4 &&
                <LookupAll
                    isCompany={true}
                    isCustomer={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, customerId: value.id, exampleCustomerName: value.name})
                        setVisible2(false)
                    }}
                />}
            </Modal>
        </>
    )
}

export default Index