import React, {useEffect, useState} from 'react';
import {Table, Button} from "antd";
import {Sale} from "../../../../components/wms/config";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import {PrinterOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";

function LookModal(props) {
    // isShowCostPrice 是否展示成本价 true:展示 false:不展示
    let {defaultValue, printType = "wms_delivery", isShowCostPrice = true} = props

    let [subtotal, setSubtotal] = useState({
        id: '-1',
        quantity: '0.00',
        total: '0.00',
        costTotal: '0.00',
        deliveryDto: {"code": '小计'},
        sku: 0
    })

    useEffect(() => {
        let obj = subtotal
        let skuSet = new Set();

        defaultValue.tableList.forEach((item) => {
            item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
            if (isShowCostPrice && item.costPrice !== null) {// 成本价可能为null
                item.costTotal = new BigNumber(item.quantity).multipliedBy(item.costPrice).toFixed(2)
            }

            skuSet.add(item.skuId)

            obj.total = new BigNumber(item.total).plus(obj.total).toFixed(2)
            if (item.costTotal !== undefined && isShowCostPrice) {
                obj.costTotal = new BigNumber(item.costTotal).plus(obj.costTotal).toFixed(2)
            }
            obj.quantity = new BigNumber(item.quantity).plus(obj.quantity).toFixed(2)
        })

        setSubtotal({
            ...obj,
            sku: skuSet.size
        })
    }, [defaultValue.tableList])

    const columns2 = [
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: defaultValue.type === Sale.TYPE_PART ? '配件名称' : (isShowCostPrice ? '精品名称' : "商品名称"),
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: defaultValue.type === Sale.TYPE_PART ? '配件编号' : (isShowCostPrice ? '精品编号' : "商品编号"),
            dataIndex: ['productDto', 'code'],
            width: 200,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? "" : common.numberFormat(text)
        },
        {
            title: '销售金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: isShowCostPrice ? 150 : 0,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? "" : common.numberFormat(text)
        },
        {
            title: '成本金额',
            dataIndex: 'costTotal',
            width: isShowCostPrice ? 150 : 0,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    // 打印
    let print = () => {
        let total = 0;
        defaultValue.tableList.forEach((item) => {
            item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
            total = new BigNumber(item.total).plus(total).toString()
        })

        let data = {
            "code": printType,
            "owner_id": common.getUser().company.id,
            "data": {
                "title": "销售出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": defaultValue.customerName,
                    "code": defaultValue.code,
                    "plate": defaultValue.plate,
                    "vin": defaultValue.vin,
                },
                "code": defaultValue.tableList[0].deliveryDto.code,
                "total": common.numberCut(total, 2),
                "createdAt": defaultValue.tableList[0].deliveryDate.substring(0, 10),
                "spec": defaultValue.tableList[0].deliveryDto.spec,
                "provider": defaultValue.tableList[0].employeeName,
                "receiver": defaultValue.tableList[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": defaultValue.tableList
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            {defaultValue.action === undefined &&
                <br/>}
            {defaultValue.action === undefined &&
                <PageTop
                    title={defaultValue.type === Sale.TYPE_PART ? "配件销售单详情" : (isShowCostPrice ? "精品销售单详情" : "商品销售单详情")}>
                    <Button type={'primary'} onClick={print} icon={<PrinterOutlined/>}>打印</Button>
                </PageTop>}
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={[...defaultValue.tableList, ...[subtotal]]}
                scroll={{x: '100%', y: '435px'}}/>
            <div style={{"marginTop": "10px"}}>
                SKU数: {subtotal.sku}
            </div>
        </>
    )

}

export default LookModal