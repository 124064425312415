import React, {useEffect, useState} from "react";
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Row} from "antd";
import PageTop from "../../../../components/layout/PageTop"
import LookupVipLevel from "../../../../components/passport/LookupVipLevel";
import moment from "moment";
import common from "../../../../utils/common";
import LookupCustomer from "../../../../components/passport/LookupCustomer";

const FormItem = Form.Item
const initialVipLevel = {
    vipLevelId: "",
    vipLevelName: "",
}

function VipLevelForm(props) {

    let {customer, onOk} = props
    const [form] = Form.useForm();
    let [lookupVipLevelVisible, setLookupVipLevelVisible] = useState(false) //选择登记类型弹框
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) //选择客户弹框
    let [vipLevel, setVipLevel] = useState(initialVipLevel)
    let [customerName, setCustomerName] = useState("")//客户名称

    useEffect(() => {
        common.consoleLog("customer", customer)
        vipLevel.vipLevelName = customer.vipLevelName
        vipLevel.vipLevelId = customer.vipLevelId
        setVipLevel({...vipLevel})
        setCustomerName(customer.customerName)
        customer.expiredAt = customer.expiredAt ? moment(customer.expiredAt) : null
        form.setFieldsValue(customer)
    }, [customer])

    //设置客户的会员类型
    let handleVipLevel = (val) => {
        vipLevel.vipLevelName = customer.vipLevelName
        vipLevel.vipLevelId = customer.vipLevelId
        setVipLevel({...vipLevel})
        form.setFieldsValue({vipLevelId: val.id, vipLevelName: val.name})
        setLookupVipLevelVisible(false)
    }

    //确认按钮
    let onFinish = (values) => {
        values.expiredAt = values.expiredAt ? moment(values.expiredAt).format("YYYY-MM-DD HH:mm:ss") : null
        onOk(values)
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={'设置会员类型'}>
                <div title={""} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                    <div/>
                    <Button
                        type={"primary"}
                        icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>确定
                    </Button>
                </div>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish}>
                <FormItem label={"会员类型"} className="label-character-4" name="vipLevelId" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label={"备注"} className="label-character-4" name="spec" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label={"会员ID"} className="label-character-4" name="customerId" hidden>
                    <Input hidden/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"客户名称"}
                                  rules={[{
                                      required: customer.customerId === "",
                                      message: '客户名称不能为空',
                                  }]}
                                  className="label-character-4"
                                  name="customerName">
                            <Input
                                readOnly
                                autoComplete="off"
                                value={customerName}
                                placeholder="请选择客户"
                                suffix={
                                    customerName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCustomerName("")
                                                form.setFieldsValue({customerName: "", customerId: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCustomerVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCustomerVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"有效期限"} name="expiredAt" className="label-character-4"
                                  rules={[{
                                      required: true,
                                      message: '有效期限不能为空',
                                  }]}>
                            <DatePicker/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"会员类型"}
                                  rules={[{
                                      required: true,
                                      message: '会员类型不能为空',
                                  }]}
                                  className="label-character-4"
                                  name="vipLevelName">
                            <Input
                                readOnly
                                autoComplete="off"
                                value={vipLevel.vipLevelName}
                                placeholder="请选择会员类型"
                                suffix={
                                    vipLevel.vipLevelName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                vipLevel.vipLevelName = ""
                                                vipLevel.vipLevelId = ""
                                                setVipLevel({...vipLevel})
                                                form.setFieldsValue({vipLevelName: "", vipLevelId: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupVipLevelVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupVipLevelVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Modal title="选择会员类型"
                   visible={lookupVipLevelVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setLookupVipLevelVisible(false)
                   }}>
                <LookupVipLevel
                    companyId={common.getUser().company.id}
                    customer={customer} isMultiple={false}
                    onOk={handleVipLevel}/>
            </Modal>

            <Modal title="选择客户"
                   visible={lookupCustomerVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupCustomerVisible(false)
                   }}>
                <LookupCustomer
                    onOk={(value) => {
                        common.consoleLog(value)
                        setLookupCustomerVisible(false)
                        customerName = value.name
                        setCustomerName(customerName)
                        form.setFieldsValue({customerId: value.id, customerName: value.name})
                    }}
                />
            </Modal>

        </React.Fragment>

    );
}


export default VipLevelForm
