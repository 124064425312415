import React, {useEffect, useState} from 'react';
import {LogoutOutlined, PrinterOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Table, Pagination, DatePicker, Form, Divider, Modal, Input, Row, Col, Select} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import ReturnForm from "./returnForm";
import {Delivery, Product, RepairOrder, Sale} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import {SaleOrder} from "../../../../components/sale/config";
import moment from "moment";

const {RangePicker} = DatePicker

function DeliveryReturn(props) {
    // action part(配件)  boutique(精品)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [list2, setList2] = useState([])

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().subtract(60, 'days').format("YYYY-MM-DD") + " 00:00:00",
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        orderCode: "",
        plate: "",
        vin: "",
        types: action === Product.KIND_ARTICLE ? [Delivery.DELIVERY_TYPE_SALE, Delivery.DELIVERY_TYPE_RECEIVE, Delivery.DELIVERY_TYPE_SELL] : [Delivery.DELIVERY_TYPE_REPAIR, Delivery.DELIVERY_TYPE_RECEIVE, Delivery.DELIVERY_TYPE_SELL],
        kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
        code: "",
        date: [],
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rowKeys2, setRowKeys2] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [rows2, setRows2] = useState([]);// 打印的数据
    let [visible, setVisible] = useState(false);
    let [printDisabled, setPrintDisabled] = useState(true);
    let [canDeliveryAtDispatch, setCanDeliveryAtDispatch] = useState(false) //不派工允许出料

    // 不派工允许出料
    let findCanDeliveryAtDispatch = () => {
        if (action === Product.KIND_PART) {
            common.loadingStart()
            common.ajax('get', '/support/setting/canDeliveryAtDispatch')
                .then(res => {
                    setCanDeliveryAtDispatch(res)
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(() => {
        findCanDeliveryAtDispatch();
    }, [])

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            setRows2([])
            setRowKeys2([])

            if (selectedRows.length > 0) {
                let number = 0

                let obj = {
                    id: '-1',
                    quantity: 0,
                    quantityGrant: 0,
                    quantityBack: 0,
                    costPrice: 0,
                }
                selectedRows[0].deliveryItems.forEach((item) => {
                    if (item.quantityBack > 0) {
                        number++
                    }

                    obj.quantity = new BigNumber(item.quantity).plus(obj.quantity).toString()
                    obj.quantityGrant = new BigNumber(item.quantityGrant).plus(obj.quantityGrant).toString()
                    obj.quantityBack = new BigNumber(item.quantityBack).plus(obj.quantityBack).toString()
                    obj.costPrice = new BigNumber(item.costPrice).plus(obj.costPrice).toString()
                })

                setList2([
                    ...selectedRows[0].deliveryItems,
                    ...[obj]
                ])
                setPrintDisabled(number === 0)
            }
        },
        getCheckboxProps: record => {
            // wms领用出库
            if (record.type === Delivery.DELIVERY_TYPE_RECEIVE) {
                return {disabled: false}
            }

            // wms模块
            if (record.type === Delivery.DELIVERY_TYPE_SELL) {
                return {disabled: !(record.orderStatus === Sale.STATUS_PRE_SETTLEMENT)}
            }

            // sale模块
            if (record.type === Delivery.DELIVERY_TYPE_SALE) {
                return {disabled: !(record.orderStatus === SaleOrder.STATUS_KIND_PARTS)}
            }

            // support模块
            if (record.type === Delivery.DELIVERY_TYPE_REPAIR) {
                return {disabled: !(canDeliveryAtDispatch ? record.orderStatus <= RepairOrder.STATUS_SUPPORTING : record.orderStatus === RepairOrder.STATUS_SUPPORTING)}
            }
        }
    }

    let rowSelection2 = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys2,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys2(selectedRowKeys)
            setRows2(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.quantity === record.quantityBack || record.id === '-1'
        })
    }

    const columnsPart = [
        {
            title: '出库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '客户',
            width: 200,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '订单状态',
            width: 100,
            dataIndex: 'orderStatus',
            ellipsis: true,
            render: (text, record) => {
                // wms领用出库
                if (record.type === Delivery.DELIVERY_TYPE_RECEIVE) {
                    return ''
                }

                // wms模块
                if (record.type === Delivery.DELIVERY_TYPE_SELL) {
                    return text in Sale.StatusAlias ? Sale.StatusAlias[text] : text
                }

                // sale模块
                if (record.type === Delivery.DELIVERY_TYPE_SALE) {
                    // return text in SaleOrder.OrderStatusAlias ? SaleOrder.OrderStatusAlias[text] : text
                    return text in SaleOrder.StatusName ? SaleOrder.StatusName[text] : text
                }

                // 维修
                if (record.type === Delivery.DELIVERY_TYPE_REPAIR) {
                    return text in RepairOrder.StatusAlias ? RepairOrder.StatusAlias[text] : text
                }
            }
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'repairName',
            ellipsis: true
        },
        {
            title: '出库类型',
            width: 100,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text,
            align: 'center'
        },
        {
            title: '出库总价',
            width: 150,
            dataIndex: 'totalFee',
            ellipsis: true,
            align: 'right'
        },
        {
            title: '领料人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'deliveryItems',
            ellipsis: true,
            render: (text) => text[0].deliveryDate === undefined ? '' : text[0].deliveryDate.substring(0, 10)
        }
    ]
    const columnsBoutique = [
        {
            title: '出库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '客户',
            width: 200,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '订单状态',
            width: 100,
            dataIndex: 'orderStatus',
            ellipsis: true,
            render: (text, record) => {
                // wms领用出库
                if (record.type === Delivery.DELIVERY_TYPE_RECEIVE) {
                    return ''
                }

                // wms模块
                if (record.type === Delivery.DELIVERY_TYPE_SELL) {
                    return text in Sale.StatusAlias ? Sale.StatusAlias[text] : text
                }

                // sale模块
                if (record.type === Delivery.DELIVERY_TYPE_SALE) {
                    return text in SaleOrder.StatusName ? SaleOrder.StatusName[text] : text
                }

                if (record.type === Delivery.DELIVERY_TYPE_REPAIR) {
                    return text in RepairOrder.StatusAlias ? RepairOrder.StatusAlias[text] : text
                }
            }
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '出库类型',
            width: 100,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text,
            align: 'center'
        },
        {
            title: '出库总价',
            width: 150,
            dataIndex: 'totalFee',
            ellipsis: true,
            align: 'right'
        },
        {
            title: '领料人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'deliveryItems',
            ellipsis: true,
            render: (text) => text[0].deliveryDate === undefined ? '' : text[0].deliveryDate.substring(0, 10)
        }
    ]
    const columns2 = [
        {
            title: '出库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return '小计'
                }
                return rows[0].code
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '已发放数量',
            dataIndex: 'quantityGrant',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库退库数量',
            dataIndex: 'quantityBack',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.id === '-1' ? '' : common.numberFormat(text)
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true,
            align: 'center'
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/delivery/return/page", {
            ...pagination,
            ownerId: common.getUser().company.id,
            brandId: common.getUser().brand.id,
            ...query,
        }).then((data) => {
            setList(data.deliverys)
            setTotal(data.pagination.total)
            setRows([])
            setRows2([])
            setRowKeys([])
            setRowKeys2([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let returnBack = (params) => {
        setVisible(false)

        params.kind = action
        params.orderId = rows[0].orderId
        params.type = rows[0].type
        common.loadingStart()
        common.ajax("post", "/wms/productGoods/delivery/return", params).then((data) => {
            common.toast("出库退库成功")
            getData()
            setRows([])
            setRows2([])
            setRowKeys2([])
            setRowKeys([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 打印
    let print = () => {
        rows[0].deliveryBackItems = []
        let moneny = 0
        rows[0].deliveryItems.forEach((item) => {
            if (item.quantityBack > 0) {
                moneny = new BigNumber(item.quantityBack).multipliedBy(item.price).plus(moneny).toString()
                rows[0].deliveryBackItems.push({
                    ...item,
                    quantity: item.quantityBack,
                    total: new BigNumber(item.quantityBack).multipliedBy(item.price).toFixed(2)
                })
            }
        })

        // 组装数据
        let data = {
            "code": "wms_delivery",
            "owner_id": common.getUser().company.id,
            "data": {
                "title": "出库退库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": rows[0].customerName,
                    "code": rows[0].orderCode,
                    "plate": rows[0].plate
                },
                "code": rows[0].code,
                "total": common.numberFormat(moneny),
                "createdAt": rows[0].deliveryBackItems[0].deliveryDate.substring(0, 10),
                "spec": rows[0].spec,
                "provider": rows[0].employeeName,
                "receiver": rows[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": rows[0].deliveryBackItems
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品退库" : "配件退库"}>
                <Button
                    type="primary"
                    disabled={!rows2.length > 0}
                    icon={<LogoutOutlined/>}
                    onClick={() => {
                        rows2.forEach((item) => {
                            item.salableQuantity = null
                        })
                        setVisible(true)
                    }}>退库</Button>
                <Button icon={<PrinterOutlined/>} onClick={print}
                        disabled={printDisabled}>打印</Button>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: dateTime
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务单号">
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        {action === Product.KIND_PART &&
                        <Col span={6}>
                            <Form.Item label="车牌号">
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>}
                        {action === Product.KIND_ARTICLE &&
                        <Col span={6}>
                            <Form.Item label="车架号">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>}
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="配件编号">
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="配件名称">
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        setSearch({...search, exampleType: val, types: [val]})
                                    }}>
                                    {action === Product.KIND_ARTICLE &&
                                    <Select.Option value={Delivery.DELIVERY_TYPE_SALE}
                                                   key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Select.Option>}
                                    {action === Product.KIND_PART &&
                                    <Select.Option value={Delivery.DELIVERY_TYPE_REPAIR}
                                                   key={Delivery.DELIVERY_TYPE_REPAIR}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_REPAIR]}</Select.Option>}
                                    <Select.Option value={Delivery.DELIVERY_TYPE_RECEIVE}
                                                   key={Delivery.DELIVERY_TYPE_RECEIVE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_RECEIVE]}</Select.Option>
                                    <Select.Option value={Delivery.DELIVERY_TYPE_SELL}
                                                   key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={action === Product.KIND_PART ? columnsPart : columnsBoutique}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            let repairBooleanRes = canDeliveryAtDispatch ? record.orderStatus <= RepairOrder.STATUS_SUPPORTING : record.orderStatus === RepairOrder.STATUS_SUPPORTING

                            if ((record.type === Delivery.DELIVERY_TYPE_SELL && record.orderStatus === Sale.STATUS_PRE_SETTLEMENT)
                                || (record.type === Delivery.DELIVERY_TYPE_SALE && record.orderStatus === SaleOrder.STATUS_KIND_PARTS)
                                || (record.type === Delivery.DELIVERY_TYPE_REPAIR && repairBooleanRes)
                                || record.type === Delivery.DELIVERY_TYPE_RECEIVE) {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Divider orientation={'left'}>出库明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={rows.length === 0 ? [] : list2}
                rowSelection={rowSelection2}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id !== '-1' && parseFloat(new BigNumber(record.quantity).minus(record.quantityBack).toString()) > 0) {
                                let num = -1
                                rows2.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows2
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection2.onChange(selectedRows.map((item) => item.id), selectedRows)
                            }
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%', y: '300px'}}/>

            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                s onCancel={() => {
                setVisible(false)
            }}
            >
                <ReturnForm action={action} defaultValue={rows2} onOk={returnBack}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReturn)