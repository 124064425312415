import React, {useEffect, useState} from 'react';
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Modal, Pagination, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import FormCreate from "../ProductGoodsCreateForm/form";
// import FormCreate from "./form";
import {Basic, Brand, Product} from "../config";

const FormItem = Form.Item

// 配件 或 精品选择
function ProductGoods(props) {
    // onOK              (回调函数)                   说明:(必填)
    // companyId         (字符串)      公司ID         说明:(选填) 公司ID
    // isMultiple        (布尔值)      是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // defaultValue      (字符串)      商品类型        说明:(选填) part(默认值 配件)/boutique(销售可用)/goods(配件和精品)
    // action            (字符串)      商品状态        说明:(选填) sale(sale模块用 默认)/repair(维修模块用)/all(自己模块 用)
    // isHaltProduction  (字符串)      是否停产        说明:(选填) no(显示不停产商品 默认)/all(显示全部商品 停产和不停产)
    // showButton        (字符串)      是否显示新增按钮  说明:(选填) false(不显示新增按钮 默认)/true(显示新增按钮)
    // defaultList       (数组)        默认选中        说明:(选填)
    // skuKind          (获取库存数量需要)  默认配件/精品    说明:(选填) part(默认值 配件库存)/boutique(精品库存)
    let {
        onOk,
        companyId = "",
        isMultiple = false,
        defaultValue,
        action,
        isHaltProduction,
        showButton,
        defaultList = [],
        skuKind = '',
        brandId = ""
    } = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([])
    let [rows, setRows] = useState([])
    let [visible, setVisible] = useState(false)
    let [brand, setBrand] = useState([])// 品牌

    // 搜索条件
    let initialSearch = {
        codeBar: '',
        name: '',
        number: '',
        brandIds: brand.map(item => item.id)
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columnsSku = [
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '当前库存',
            dataIndex: 'skuQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === null || text === undefined) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === null || text === undefined) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品条码' : '配件条码',
            dataIndex: 'barCode',
            width: 200,
            ellipsis: true,
        },
        {
            title: '是否施工',
            dataIndex: 'work',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '是否保养材料',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
    ]

    const columns = [
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品条码' : '配件条码',
            dataIndex: 'barCode',
            width: 200,
            ellipsis: true,
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
        },
        {
            title: '是否施工',
            dataIndex: 'work',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '是否保养材料',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
    ]

    let rowSelection = {
        type: isMultiple ? 'checked' : 'radio',
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setRows([
                        ...rows,
                        record
                    ])
                } else {
                    setRows(rows.filter(item => item.id !== record.id))
                }
            } else {
                setRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                rows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setRows(arr)
            }
        }
    }

    useEffect(() => {
        setRows(defaultList)
    }, [])

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据
    let getData = () => {
        let params = {
            ...pagination,
            ...query,
            status: Product.STATUS_ALL,
            isHaltProduction: Basic.IS_ANSWER_NO,
            ownerId: companyId ? companyId : common.getUser().company.id,
            kind: Product.KIND_PART,
            skuKind: skuKind,
            brandId: brandId
        }
        if (action === 'all') {
            params.status = -1
        }
        if (action === 'repair') {
            params.status = Product.STATUS_AFTER_SALE
        }

        if (isHaltProduction === 'all') {
            params.isHaltProduction = ''
        }

        if (defaultValue === Product.KIND_ARTICLE) {
            params.kind = Product.KIND_ARTICLE
        }
        if (defaultValue === Product.KIND_GOODS) {
            params.kind = Product.KIND_GOODS
        }

        if (brand.length === 0) {
            setList([])
        } else {
            common.loadingStart()
            common.ajax("get", "/wms/product/list", params).then((data) => {
                setList(data.products)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 页面初始化 拉取列表
    useEffect(getData, [pagination, query, defaultValue, brand])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setQuery({
                ...query,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // let create = (params) => {
    // common.loadingStart()
    // common.ajax('post', '/wms/product/create', params)
    //     .then(() => {
    //         common.toast("新增成功")
    //
    //     }).finally(() => {
    //     common.loadingStop()
    // })
    // }
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/create', params, {"displayError": !params.canRepetitionHint})
            .then(() => {
                common.toast("新增成功")
                getData()
                setVisible(false)
            }).catch((err) => {
            if (err.hasOwnProperty("message") && err.message.includes("已存在") && params.canRepetitionHint) {
                let message = err.message
                if (params.tenantId === params.ownerId) {
                    message += "点击[确认]将自动调整为集团配件档案中！"
                } else {
                    message += "点击[确认]将自动复制到本店配件档案中！"
                }

                params.canRepetitionHint = false
                common.confirm(message, () => {
                    create(params)
                })
            } else {
                common.alert(err.message)
            }
        })
            .finally(() => {
                common.loadingStop()
            })
    }

    return <>
        <SearchArea>
            <Form name={'form-data'} layout='inline'>
                <FormItem label={defaultValue === Product.KIND_ARTICLE ? "精品编号" : '配件编号'}>
                    <Input value={search.number} onChange={(e) => {
                        setSearch({...search, number: e.target.value.trim()})
                    }}/>
                </FormItem>
                <FormItem label={defaultValue === Product.KIND_ARTICLE ? '精品名称' : '配件名称'}>
                    <Input value={search.name} onChange={(e) => {
                        setSearch({...search, name: e.target.value})
                    }}/>
                </FormItem>
                <FormItem label={defaultValue === Product.KIND_ARTICLE ? '精品条码' : '配件条码'}>
                    <Input value={search.codeBar} onChange={(e) => {
                        setSearch({...search, codeBar: e.target.value})
                    }}/>
                </FormItem>
                <FormItem className={"inline-search-btns"}>
                    <Button icon={<SearchOutlined/>} onClick={() => {
                        setPagination({...pagination, page: 1})
                        setQuery(search)
                    }}>搜索</Button>
                    <Button icon={<ReloadOutlined/>} onClick={() => {
                        setPagination({...pagination, page: 1})
                        setQuery(initialSearch)
                        setSearch(initialSearch)
                    }}>清空</Button>
                </FormItem>
            </Form>
        </SearchArea>
        <Table
            columns={skuKind !== "" ? columnsSku : columns}
            dataSource={list}
            scroll={{x: "100%"}}
            pagination={false}
            rowKey="id"
            rowSelection={rowSelection}
            onRow={record => {
                return {
                    onClick: () => {
                        rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                    }, // 点击行
                };
            }}
        />
        <PageBottom
            children={
                showButton ?
                    <Button
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setVisible(true)
                        }}>配件档案</Button>
                    :
                    <></>
            }
            pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
        </PageBottom>
        <div className={"modal-footer-btn"}>
            <Button type={"primary"} onClick={() => {
                if (rows.length === 0) {
                    common.toast("请选择配件(精品)")
                    return false
                }
                isMultiple ? onOk(rows) : onOk(rows[0])
            }}>确定</Button>
        </div>
        <Modal
            maskClosable={false}
            visible={visible}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            <FormCreate isModal={true} onOk={create} isCompany={true}/>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGoods)