import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Basic, Brand, Product, Warehouse} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import LookupProduct from "../../../../../../components/wms/LookupProductGoods";
import StatusIndex from "./component/warehouse";
import ReceiptItemIndex from "../../../../../../components/wms/report/PartReceiptAndDelivery/index";
import moment from "moment";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../../components/export";

const {TabPane} = Tabs;
const {Option} = Select

function Index(props) {
    // action 字符串 必填 part(配件) article(精品)
    let {action} = props

    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState("1")
    let [brand, setBrand] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库

    // 搜索条件
    let initialSearch = {
        productId: "",
        exampleName: "",
        number: "",
        ownerIds: [common.getUser().company.id],
        brandIds: brand.map(item => item.id),
        warehouseId: "",
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"),
        lastMonth: moment().subtract(1, 'months').startOf('month').format("YYYY-MM"),
        month: moment().startOf('month').format("YYYY-MM"),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
        kind: action,
        statisticalCaliber: 1,
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getCompany = () => {
        common.loadingStart();
        common.ajax("get", "/passport/employee/findCompaniesByAuthUserId").then((data) => {
            setQuery({...query, companies: data})
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getCompany, [])

    return (
        <>
            <PageTop title={action === Product.KIND_PART ? '配件进销存统计' : '精品进销存统计'}>
                <ExportButton
                    filename={action === Product.KIND_PART ? '配件进销存统计表.xlsx' : '精品进销存统计表.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(query)}
                    type={action === Product.KIND_PART ? 'wms_part_receiptAndDelivery' : 'wms_boutique_receiptAndDelivery'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="发生时间">
                                <DatePicker
                                    allowClear={false}
                                    picker="month"
                                    suffixIcon={null}
                                    value={moment(search.createdAtStart, 'YYYY/MM/DD')}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: moment(dateString, 'YYYY-MM').startOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                            lastMonth: moment(dateString, 'YYYY-MM').subtract(1, 'months').startOf('months').format('YYYY-MM'),
                                            month: moment(dateString, 'YYYY-MM').startOf('months').format('YYYY-MM'),
                                            createdAtEnd: moment(dateString, 'YYYY-MM').endOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="存放仓库">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_PART ? "配件编号" : "精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_PART ? "配件名称" : '精品名称'}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="统计口径">
                                <Select
                                    value={search.statisticalCaliber}
                                    // onChange={(val) => {
                                    //     setSearch({...search, statisticalCaliber: val})
                                    // }}
                                >
                                    <Option key={initialSearch.statisticalCaliber}
                                            value={initialSearch.statisticalCaliber}>账面变化</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            brandIds: brand.map(item => item.id),
                                            page: 1
                                        })
                                    } else {
                                        setQuery({
                                            ...search,
                                            page: 1
                                        })
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/receiptAndDelivery'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    if (search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setModalNumber(key)
                }}>
                <TabPane tab={"按库别统计"} key={"1"}>
                    {modalNumber === '1' &&
                    <StatusIndex query={query}/>}
                </TabPane>
                <TabPane tab={"进销存明细"} key={"3"}>
                    {modalNumber === '3' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={action === Product.KIND_PART ? "选择配件" : "选择精品"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>
            </Modal>
        </>
    )
}

export default Index