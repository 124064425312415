import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import moment from "moment";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Modal, Row, Select, Table} from "antd"
import BigNumber from "bignumber.js";
import {Allot, Product} from "../../../../components/wms/config";
import OrderDetail from "../../../sale/plus/detail";
import OutsourceDetail from "../../../../components/support/OutsourceDetail";
import AllotGoodsDetail from "../../../../components/wms/finance/AllotGoodsDetail";
import AllotCarDetail from "../../../../components/wms/finance/AllotCarDetail";
import DelegateDetail from "../../../../components/support/DelegateDetail";
import GoodsProductDetail from "../../../../components/wms/finance/ProductGoodsReceiptDetail";
import CarReceiptDetail from "../../../../components/wms/CarReceiptForm";
import AvocationDetail from "../../../../components/avocation/AvocationOrderDetail";
import GoodsReceiptBackDetail from "../../../../components/wms/finance/ProductGoodsReceiptBackDetail";
import CarReceiptBackDetail from "../../../../components/wms/CarReceiptBackForm";
import "./confirm.less"
import {SaveOutlined} from "@ant-design/icons";
import {Term} from "../../../../components/finance/config";

function ApplyForm(props) {

    let {customerId, payableIds, onOk, type, applyId, isDisabled} = props

    const BUSINESS_SUPPORT = 1
    const BUSINESS_TRANSFER = 2
    const BUSINESS_INTERNAL = 3
    const BUSINESS_PART = 4
    const BUSINESS_PART_BACK = -4
    const BUSINESS_ARTICLE = 5
    const BUSINESS_ARTICLE_BACK = -5
    const BUSINESS_CAR = 6
    const BUSINESS_CAR_BACK = -6
    const BUSINESS_VALUE_ADDED = 7
    const BUSINESS_ORDER = 8

    const BusinessName = {
        [BUSINESS_SUPPORT]: "委外维修",
        [BUSINESS_TRANSFER]: "调拨单",
        [BUSINESS_INTERNAL]: "委内业务",
        [BUSINESS_PART]: "配件入库",
        [BUSINESS_PART_BACK]: "配件入库退货",
        [BUSINESS_ARTICLE]: "精品入库",
        [BUSINESS_ARTICLE_BACK]: "精品入库退货",
        [BUSINESS_CAR]: "车辆入库",
        [BUSINESS_CAR_BACK]: "车辆入库退货",
        [BUSINESS_VALUE_ADDED]: "增值业务",
        [BUSINESS_ORDER]: "销售费用",
    };

    const childrenColumns = [
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            ellipsis: true,
            align: "right",
            width: 150,
            render: (text) => {
                return text ? text : "-"
            }
        },
        {
            title: "业务类型",
            dataIndex: "financeCategoryName",
            width: 150,
            ellipsis: true,
            align: "center"
        },
        {
            title: "金额",
            dataIndex: "money",
            key: "money",
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paidMoney",
            key: "paidMoney",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(record.money - record.paidMoney)
            }
        },
        {
            title: "说明",
            dataIndex: "spec",
            key: "spec",
            align: "center"

        },
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
    ];

    const columns = [
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            width: 150,
            ellipsis: true
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (isDisabled == true) {
                    return text
                }
                return (
                    <Button type={'link'} onClick={(e) => {
                        setCode(text)
                        switch (record.businessType) {
                            case BUSINESS_ORDER:
                                setOrderDetailVisible(true)
                                break;
                            case BUSINESS_SUPPORT:
                                setSupprtDetailVisible(true)
                                break;
                            case BUSINESS_TRANSFER:
                                getTransferData(text)
                                break;
                            case BUSINESS_INTERNAL:
                                setInternalDetailVisible(true)
                                break;
                            case BUSINESS_PART:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_ARTICLE:
                                getGoodsReceipt(text)
                                break;
                            case BUSINESS_CAR:
                                getCarReceipt(text)
                                break;
                            case BUSINESS_VALUE_ADDED:
                                setValueAddedDetailVisible(true)
                                break;
                            case BUSINESS_PART_BACK:
                                getReceiptReturn(text)
                                break;
                            case BUSINESS_ARTICLE_BACK:
                                getReceiptReturn(text)
                                break;
                            case BUSINESS_CAR_BACK:
                                getReceiptReturn(text)
                                break;
                            default:
                                break;
                        }
                        e.stopPropagation()
                    }}>{text}</Button>
                )
            }
        },
        {
            title: "业务类型",
            dataIndex: "businessType",
            render: (text, record) => {
                return (
                    BusinessName[text]
                )
            },
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true
        },
        {
            title: "应付账款",
            dataIndex: "total",
            align: "right",
            key: "total",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paid",
            key: "paid",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(record.total - record.paid)
            }
        },
    ]

    let [invoiceType, setInvoiceType] = useState([]);

    let [dataSource, setDataSource] = useState([])

    let [parentKeys, setParentKeys] = useState([])
    let [parentRows, setParentRows] = useState([])

    let [childrenKeys, setChildrenKeys] = useState([])
    let [childrenRows, setChildrenRows] = useState([])

    let [request, setRequest] = useState({
        ownerId: common.getUser().company.id,
        customerId: customerId,
        invoiceMoney: '0',
        invoiceNo: '',
        invoiceTypeCode: '',
        spec: ''
    })

    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [supprtDetailVisible, setSupprtDetailVisible] = useState(false)
    let [transferDetailVisible, setTransferDetailVisible] = useState(false)
    let [internalDetailVisible, setInternalDetailVisible] = useState(false)
    let [partDetailVisible, setPartDetailVisible] = useState(false)
    let [articleDetailVisible, setArticleDetailVisible] = useState(false)
    let [carDetailVisible, setCarDetailVisible] = useState(false)
    let [valueAddedDetailVisible, setValueAddedDetailVisible] = useState(false)
    let [returnDetailVisible, setReturnDetailVisible] = useState(false)
    let [lookupOutsourceDetailVisible, setLookupOutsourceDetailVisible] = useState(false)
    let [code, setCode] = useState("")
    let [modalData, setModalData] = useState({})
    let [modalData2, setModalData2] = useState([])
    let [modalNumber, setModalNumber] = useState(0)

    let getCarReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            receiptCode: params,
            ownerId: common.getUser().company.id,
            kind: Product.KIND_CAR
        }).then((data) => {
            setModalData({
                ...data.receiptItems[0].receiptDto,
                receiptItemDto: data.receiptItems[0],
                goodsDto: data.receiptItems[0].goodsDto,
                productDto: data.receiptItems[0].productDto
            })
            setCarDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getGoodsReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params
        }).then((data) => {
            setModalData2(data.receiptItems)
            setPartDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getReceiptReturn = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params,
            // type: Receipt.RECEIPT_TYPE_RETURN_BUY,
        }).then((data) => {
            setModalData2(data.receiptItems)
            if (data.receiptItems[0].goodsDto == null) {
                setModalNumber(1)
            } else {
                setModalNumber(2)
            }
            setReturnDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getTransferData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/findByCode", {
            code: params
        }).then((data) => {
            common.consoleLog(data)
            setModalData(data)
            if (data.type === Allot.TYPE_CAR) {
                setModalNumber(4)
            } else {
                setModalNumber(3)
            }
            setTransferDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }


    let getData = () => {
        let params = {
            customerId,
            ownerId: common.getUser().company.id,
            ids: payableIds
        }
        common.loadingStart()
        common.ajax('get', '/finance/payable/findByIds', params).then(data => {
            common.ajax('get', '/finance/apply/findApplyItemIdsByPayableIds', {
                payableIds: payableIds
            }).then(applyData => {
                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "financeCategory",
                    status: Term.STATUS_YES,// 启用
                }).then(res => {
                    res = res.filter(item => item.status != 2)
                    let dataList = []
                    data.payableList.forEach(payable => {
                        let list = []
                        payable.itemList.forEach(item => {
                            if (applyData.indexOf(item.id) == -1 && parseInt(item.money) != parseInt(item.paidMoney)) {
                                item.businessOrderCode = payable.businessOrderCode
                                let index = res.findIndex(resItem => resItem.id == item.financeCategoryId)
                                if (index != -1) {
                                    item.financeCategoryName = res[index].name
                                }
                                list.push(item)
                            }
                        })
                        payable.itemList = list
                        dataList.push(payable)
                    })
                    setDataSource(dataList)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let findById = () => {
        let params = {
            ownerId: common.getUser().company.id,
            customerId,
            ids: [applyId]
        }

        common.loadingStart()
        common.ajax('get', '/finance/apply/findByIds', params).then(data => {
            common.ajax("get", "/finance/term/findByTaxonomy", {
                taxonomy: "financeCategory",
                status: Term.STATUS_YES,// 启用
            }).then(res => {
                let list = []
                let keys1 = []
                let keys2 = []
                res = res.filter(item => item.status != 2)
                data.applyList[0].payableList.forEach(payable => {
                    payable.itemList.forEach(item => {
                        item.businessOrderCode = payable.businessOrderCode
                        let index = res.findIndex(resItem => resItem.id == item.financeCategoryId)
                        if (index != -1) {
                            item.financeCategoryName = res[index].name
                        }
                        keys2.push(item.id)
                    })
                    list.push(payable)
                    keys1.push(payable.id)
                })

                setRequest({...data.applyList[0]})
                setDataSource(list)
                setParentKeys(keys1)
                setChildrenKeys(keys2)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (type == 'create') {
            getData()
        } else {
            findById()
        }
    }, [payableIds, customerId])

    let getInvoiceType = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            status: Term.STATUS_YES,// 启用
            taxonomy: "invoiceType"
        }).then((data) => {
            data = data.filter(item => item.status != 2)
            setInvoiceType([...data]);
        }).finally(common.loadingStop)
    }

    useEffect(getInvoiceType, [])

    useEffect(() => {
        if (type == 'create') {
            let total = '0'
            dataSource.forEach(data => {
                data.itemList.forEach(item => {
                    if (childrenKeys.indexOf(item.id) != -1) {
                        total = new BigNumber(total).plus(item.money).toString()
                    }
                })
            })

            setRequest({...request, invoiceMoney: total})
        }
    }, [childrenKeys])

    let getTotal = () => {
        let total = '0'
        dataSource.forEach(data => {
            data.itemList.forEach(item => {
                if (childrenKeys.indexOf(item.id) != -1) {
                    total = new BigNumber(total).plus(item.money).toString()
                }
            })
        })
        return total
    }

    let getDifference = () => {
        let total = getTotal()

        return new BigNumber(total).minus(new BigNumber(request.invoiceMoney && !isNaN(request.invoiceMoney) ? request.invoiceMoney : '0')).toString()
    }

    let onSubmit = () => {
        if (type == 'create') {
            toCreate()
        } else {
            toUpdate()
        }
    }

    let toCreate = () => {
        if (!request.invoiceTypeCode) {
            common.alert('请选择发票类型')
            return
        }

        if (!request.invoiceNo) {
            common.alert('发票编号必须填写')
            return
        }

        if (!request.invoiceMoney) {
            common.alert('请输入发票金额')
            return
        }

        if (childrenKeys.length <= 0) {
            common.alert('至少选择一条明细')
            return
        }

        let params = {
            ...request,
            payableItemIds: childrenKeys,
        }

        common.loadingStart()
        common.ajax('post', '/finance/apply/create', params).then(res => {
            common.toast('申请成功')
            onOk()
        }).finally(common.loadingStop)
    }

    let toUpdate = () => {
        if (!request.invoiceTypeCode) {
            common.alert('请选择发票类型')
            return
        }

        if (!request.invoiceNo) {
            common.alert('发票编号必须填写')
            return
        }

        if (!request.invoiceMoney) {
            common.alert('请输入发票金额')
            return
        }

        let params = {
            id: applyId,
            ownerId: common.getUser().company.id,
            invoiceMoney: request.invoiceMoney,
            invoiceNo: request.invoiceNo,
            invoiceTypeCode: request.invoiceTypeCode,
            spec: request.spec
        }

        common.loadingStart()
        common.ajax('post', '/finance/apply/update', params).then(res => {
            common.toast('编辑成功')
            onOk()
        }).finally(common.loadingStop)
    }

    /**
     * 父表单选
     */
    let onParentSelectChange = (record, selected, selectedRows) => {
        //父表和子表所选中的keys
        let parentKeyArr = [...parentKeys]
        let childrenKeyArr = [...childrenKeys]

        //选中的父表下所有的子表id
        let childrenIds = dataSource.find(item => item.id == record.id).itemList.map(item => item.id)

        //selected：true 选中 false 取消
        if (selected) {
            //父表添加选中的数据 子表keys添加当前父表下所有的key
            parentKeyArr.push(record.id)
            childrenKeyArr = Array.from(new Set([...childrenKeyArr, ...childrenIds]))
        } else {
            //父表keys删除当前取消的数据 子表keys删除所有属于此数据下的数据
            parentKeyArr.splice(parentKeyArr.findIndex(item => item == record.id), 1)
            childrenKeyArr = childrenKeyArr.filter(item => !childrenIds.some(e => e === item))
        }

        setParentKeys(parentKeyArr)
        setChildrenKeys(childrenKeyArr)
    }

    /**
     * 父表全选
     */
    let onParentSelectAllChange = (selected, selectedRows, changeRows) => {
        //父表keys 和子表 keys
        let parentKeyArr = [...parentKeys]
        let childrenIds = []

        //将子表所有的key添加进keys数组中
        changeRows.forEach(e => {
            childrenIds = [...childrenIds, ...e.itemList.map(item => item.id)]
        });

        if (selected) {
            //父表所有数据选中
            parentKeyArr = Array.from(new Set([...parentKeyArr, ...changeRows.map(item => item.id)]))
        } else {
            parentKeyArr = []
            childrenIds = []
        }

        setParentKeys(parentKeyArr)
        setChildrenKeys(childrenIds)
    }

    /**
     * 子表单选
     */
    let onChildrenSelectChange = (record, selected, selectedRows) => {
        //子表keys
        let keyArr = [...childrenKeys]

        if (selected) {
            //添加当前选中的数据
            keyArr.push(record.id)
        } else {
            //删除当前选中的数据
            keyArr.splice(keyArr.findIndex(item => item == record.id), 1)
        }

        //排除调undefined数据
        selectedRows = selectedRows.filter(item => item !== undefined)

        //父表响应
        onChildrenToParent('select', selected, selectedRows, record)

        setChildrenKeys(keyArr)
    }

    /**
     * 子表全选
     */
    let onChildrenSelectAllChange = (selected, selectedRows, changeRows) => {
        let keyArr = [...childrenKeys]

        if (selected) {
            keyArr = Array.from(new Set([...keyArr, ...changeRows.map(item => item.id)]))
        } else {
            keyArr = keyArr.filter(item => !changeRows.some(row => row.id == item))
        }

        selectedRows = selectedRows.filter(item => item !== undefined)

        onChildrenToParent('selectAll', selected, changeRows, undefined)

        setChildrenKeys(keyArr)
    }

    /**
     * 子表数据选中或取消父表响应
     *  type:单选或者全选
     *  selected:取消 false 选中 true
     *  changeRows: 影响的数据
     *  record:单选时进行操作的数据
     */
    let onChildrenToParent = (type, selected, changeRows, record) => {
        //父表keys
        let keyArr = [...parentKeys]

        //子表单选
        if (type == 'select') {
            //循环父表数据
            for (let item of dataSource) {
                //查找出操作的子表数据所属的父表数据
                if (item.itemList.find(v => v.id == record.id)) {
                    //子表数据全都选中的情况
                    if (item.itemList.length == changeRows.length) {
                        keyArr.push(item.id)
                    } else {
                        //子表数据无全选中 父表keys中包含操作的子表数据所属的父表key 将其删除
                        if (keyArr.length > 0 && keyArr.find(a => a == item.id)) {
                            keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                        }
                    }
                    break
                }
            }
            //子表全选
        } else if (type == 'selectAll') {
            //循环父表数据
            for (let item of dataSource) {
                //查找出操作的子表数据所属的父表数据
                if (item.itemList.find(v => v.id == changeRows[0].id)) {
                    if (selected) {
                        //选中的情况 父表keys加入当前操作的子表数据所属的父表key
                        keyArr.push(item.id)
                    } else {
                        //取消的情况 父表keys删除当前操作的子表数据所属的父表key
                        keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                    }
                    break;
                }
            }
        }

        setParentKeys(keyArr)
    }

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: parentKeys,
        onSelect: onParentSelectChange,
        onSelectAll: onParentSelectAllChange,
        getCheckboxProps: record => ({
            disabled: type == 'update' || record.itemList.length == 0, // Column configuration not to be checked
        }),
    };

    const childrenRowSelection = {
        selectedRowKeys: childrenKeys,
        type: "checkbox",
        onSelect: onChildrenSelectChange,
        onSelectAll: onChildrenSelectAllChange,
        getCheckboxProps: record => ({
            disabled: type == 'update', // Column configuration not to be checked
        }),
    }

    let expandedRowRender = (record, index) => {
        let arr = record.itemList
        common.consoleLog(arr)
        return <Table style={{marginTop: '10px', marginBottom: '10px'}} className={'children_table'} rowKey={'id'}
                      columns={childrenColumns}
                      dataSource={arr}
                      rowSelection={(isDisabled == null || isDisabled == undefined) ? childrenRowSelection : null}
                      pagination={false}/>;
    }

    return (
        <>
            <br/>
            <PageTop title={type == 'create' ? '创建申请单' : isDisabled == true ? "申请单明细" : "编辑申请单"}>
                {
                    (isDisabled == null || isDisabled == undefined) ?
                        <Button icon={<SaveOutlined/>} type="primary" onClick={onSubmit}>保存</Button> : null
                }
            </PageTop>

            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="发票类型" required={true} className='label-character-4'>
                            <Select disabled={isDisabled} value={request.invoiceTypeCode} onChange={e => {
                                setRequest({...request, invoiceTypeCode: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    invoiceType.map(item => {
                                        return <Select.Option value={item.code}
                                                              key={item.code}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发票编号" required={true} className='label-character-4'>
                            <Input disabled={isDisabled} value={request.invoiceNo} onChange={e => {
                                setRequest({...request, invoiceNo: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发票金额" required={true} className='label-character-4'>
                            <Input disabled={isDisabled} value={request.invoiceMoney} onChange={v => {
                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '' || v.target.value == '-') {
                                    setRequest({...request, invoiceMoney: v.target.value})
                                }
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="应付款与发票金额差异">
                            <Input disabled value={getDifference()}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="申请说明" className='label-character-4'>
                            <Input disabled={isDisabled} value={request.spec} onChange={e => {
                                setRequest({...request, spec: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                className={'parent-table'}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                rowSelection={(isDisabled == null || isDisabled == undefined) ? rowSelection : null}
                expandable={{expandedRowRender: expandedRowRender,}}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                summary={(currentData) => {
                    let paid = "0.00" // 应付账款
                    let total = "0.00"  // 已付账款
                    currentData.forEach(item => {
                        paid = common.numberHalfUp(new BigNumber(paid).plus(item.paid).toString(), 2)
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                    })
                    return type === 'update' ?
                        <tr>
                            <th/>
                            <th>合计</th>
                            <td colSpan={3}/>
                            <td align={"right"}>{total}</td>
                            <td align={"right"}>{paid}</td>
                            <td align={"right"}>{common.numberHalfUp(new BigNumber(total).minus(paid).toString(), 2)}</td>
                        </tr> : null
                }}
            />

            <div>
                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1200}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={code}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={supprtDetailVisible}
                    title={'委外详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setSupprtDetailVisible(false)}
                >
                    <OutsourceDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={transferDetailVisible}
                    title={'调拨单详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setTransferDetailVisible(false)}
                >
                    {modalNumber === 3 &&
                        <AllotGoodsDetail defaultValue={modalData}/>}
                    {modalNumber === 4 &&
                        <AllotCarDetail defaultValue={modalData}/>}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={internalDetailVisible}
                    title={'委内详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setInternalDetailVisible(false)}
                >
                    <DelegateDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={partDetailVisible}
                    title={'配件入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setPartDetailVisible(false)}
                >
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'part'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={articleDetailVisible}
                    title={'精品入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setArticleDetailVisible(false)}
                >
                    {/*配件action = {'part'}*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'boutique'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={carDetailVisible}
                    title={'车辆入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setCarDetailVisible(false)}
                >
                    <CarReceiptDetail action={'look'} defaultValue={modalData}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={valueAddedDetailVisible}
                    title={'增值业务详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setValueAddedDetailVisible(false)}
                >
                    <AvocationDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={returnDetailVisible}
                    title={'入库退货详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setReturnDetailVisible(false)}
                >
                    {modalNumber === 1 &&
                        <GoodsReceiptBackDetail defaultValue={modalData2} action={modalData2[0].receiptDto.type}/>}
                    {modalNumber === 2 &&
                        <CarReceiptBackDetail defaultValue={modalData2}/>}
                </Modal>
            </div>
        </>
    )
}

export default ApplyForm
